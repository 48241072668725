import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import useToggle from '../../hooks/useToggle'
import './UnderlinedTextField.css'

const UnderlinedTextField = ({containerClassName = '', className = '', value, type, onChange, onFocus, onBlur, valueProcessor, textPosition, validation, autoComplete, min, max, onKeyDown }) => {

  const [edit, toggleEdit] = useToggle(false)
  const [validationMessage, setValidationMessage] = useState(validation)

  const ref = useRef()  

  const textPositions = ['left', 'center', 'right']

  const CHECK_TEXT_POSITIION = textPositions.find(x => x === textPosition) ? textPosition : textPositions[0]

  const onEnterEscape = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      ref.current?.blur()
      toggleEdit(false)
      window.removeEventListener('keydown', onEnterEscape)
    }
  }

  const onChangeHandler = (e) => {
    onChange && onChange(e)
    if(!validationMessage) return
    setValidationMessage('')
  }

  const onFocusHandler = (e) => {
    window.addEventListener('keydown', onEnterEscape)
    onFocus && onFocus(e)
  }
  const onBlurHandler = (e) => {    
    toggleEdit(false)
    onBlur && onBlur(e)
  }

  useEffect(() => {
    if (!edit) return
    ref.current?.focus()
  }, [edit])

  useEffect(() => {
    setValidationMessage(validation)
  }, [validation])

  useEffect(() => {
    setValidationMessage('')
  }, [value])

  return (
    <div className={`utf__container ${containerClassName}`}>
      {edit ? (
        <input
          ref={ref}
          value={value} 
          onChange={onChangeHandler} 
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          className={`utf__input__field utf__input__field--${CHECK_TEXT_POSITIION} ${className}`}
          type={type}
          autoComplete={autoComplete || 'off'}
          min={min}
          max={max}
          onKeyDown={onKeyDown}
        />
      ) : (
        <div className={`utf__value utf__value--${CHECK_TEXT_POSITIION} ${className}`} onClick={() => toggleEdit(true)}>{valueProcessor && value ? valueProcessor(value) : ''}</div> 
      )}
      {validationMessage ? (
        <div className={`text-xs text-red-600 font-normal mt-2`}>{validationMessage}</div>
      ) : ''}
    </div>
  )
}

export default UnderlinedTextField