import React, { useEffect, useState } from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import IconContainer from '../IconContainer/IconContainer'

const Pagination = ({
  page,
  pageTotal,
  paginationOnClick,
  className = ''
}) => {

  const DOTS = '...'
  let numPage = page ? Number(page) : 1
  let numPageTotal = pageTotal ? Number(pageTotal) : 1
  const [pages, setPages] = useState([])

  const paginationOnClickHandler = (pageNum) => {
    paginationOnClick && paginationOnClick(pageNum)
  }

  useEffect(() => {
    let pagesArr = []
    for (let i = 1; i <= numPageTotal; i++) {
      if(numPage < 5) {
        if(i <= 5 || i === numPageTotal) pagesArr.push(i)
        if(i === 6) pagesArr.push(DOTS)
      } else if(numPage >= 5 && numPage < numPageTotal - 3) {
        if((i === 1 || i === numPageTotal || i === numPage - 1 || i === numPage || i === numPage + 1)) pagesArr.push(i)
        if(i === numPage - 3 || (i === numPage + 3)) pagesArr.push(DOTS)
      } else if(numPage >= numPageTotal - 4) {
        if(i === 1 || i >= numPageTotal - 4) pagesArr.push(i)
        if(i === numPageTotal - 5) pagesArr.push(DOTS)
      }
    }
    setPages(pagesArr)
  }, [numPage, numPageTotal, page, pageTotal])

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <IconContainer component={'button'} className={`${styles.btn.default} ${numPage === 1 ? styles.btn.chevron.disabled : styles.btn.chevron.enabled}`} onClick={() => numPage !== 1 && paginationOnClickHandler(numPage - 1)}>
        <IoChevronBack/>
      </IconContainer>
      {pages.map((pg, pgIdx) => (
         pg === DOTS ? (
          <IconContainer key={pgIdx} className={`${styles.btn.default}`}>
            {pg}
          </IconContainer>
         ) : (
          <IconContainer key={pgIdx} component={'button'} className={`${styles.btn.default} ${numPage === pg ? styles.btn.page.active : styles.btn.page.deactive}`} onClick={() => paginationOnClickHandler(pg)}>
            {pg}
          </IconContainer>
         )
      ))}     
      <IconContainer component={'button'}  className={`${styles.btn.default} ${numPage === numPageTotal ? styles.btn.chevron.disabled : styles.btn.chevron.enabled}`} onClick={() => numPage !== numPageTotal && paginationOnClickHandler(numPage + 1)}>
        <IoChevronForward/>
      </IconContainer>
    </div>
  )
}

const styles = {
  btn: {
    default: `h-7 w-7 rounded-full`,
    page: {
      active: 'bg-etpi-blue-100 text-etpi-blue',
      deactive: `hover:bg-etpi-blue-50`,
    },
    chevron: {
      enabled: 'bg-etpi-blue text-white hover:bg-etpi-blue-700',
      disabled: 'bg-etpi-blue-50 text-etpi-blue-300 cursor-not-allowed',
    }
  },
  
  
}

export default Pagination