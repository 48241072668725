import React from 'react'
import './Card.css'

const Card = ({
  id,
  className = '',
  header,
  headerClassName = '',
  contentClassName = '',
  children,
}) => {
  return (
    <div id={id} className={`card ${className}`}>
      {header ? (
        <div className={`card__header ${headerClassName}`}>
          {header}
        </div>
      ) : ''}
      <div className={`card__content ${contentClassName}`}>
        {children}
      </div>
    </div>
  )
}

export default Card