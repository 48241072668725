import React from 'react'
import { RiHome2Fill } from 'react-icons/ri'
import Card from '../../../../components/Card/Card'
import IconContainer from '../../../../components/IconContainer/IconContainer'
import { useGetDate } from '../../../../hooks/useGetDate'

const PlaceDateCard = ({
  placeName
}) => {

  const getDate = useGetDate()  
  const { date, month, year } = getDate('') 

  return (
    <Card
      id={'place-and-date'}
      className='w-full'
      contentClassName='p-5 divide-y'
    >
      <div className='flex items-center pb-4 space-x-2'>
        <IconContainer>
          <RiHome2Fill />
        </IconContainer>
        <div className='font-bold'>{placeName || '-'}</div>
      </div>
      <div className='flex justify-between w-full pt-4 text-sm'>
        <span>Tanggal Lelang</span>
        <span className='font-semibold'>{date}/{month}/{year}</span>
      </div>
    </Card>
  )
}

export default PlaceDateCard