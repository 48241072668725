export const useAddZero = () => {

  const zeroCountHandler = (count) => {
    let zeros = ''
    for (let i = 0; i < count; i++) {
      zeros += 0    
    }
    return zeros
  }

  const front = (value, zeroCount = 1) => {
    value = value.toString()
    let zeros = zeroCountHandler(zeroCount)    
    const processedValue = value.length < 2 ? `${zeros}${value}` : value
    return processedValue
  } 

  const back = (value, zeroCount = 1) => {
    value = value.toString()
    let zeros = zeroCountHandler(zeroCount)    
    const processedValue = `${value}${zeros}`
    return processedValue
  } 

  return {
    front,
    back
  }
}