import React from 'react'
import Alert from '../Alert/Alert'
import Button from '../../Button/Button'

const ActionAlert = ({
  icon,
  title,
  subtitle,
  action
}) => {
  return (
    <Alert size={`lg`}>
      <div className={`flex flex-col items-center text-center space-y-6 p-5`}>
        {icon}
        <div className="flex flex-col space-y-1">
          <div className='text-2xl font-bold'>
            {title}
          </div>
          <div className=''>
            {subtitle}
          </div>
        </div>
        <div className='flex items-center w-full space-x-3 font-bold'>
          {action?.yes ? (
            <Button className='w-full' variant={'outline'} color={'gray'} onClick={action?.yes}>Ya</Button>
          ) : ''}
          {action?.try ? (
            <Button className='w-full' variant={'outline'} color={'gray'} onClick={action?.try}>Coba Lagi</Button>
          ) : ''}
          {action?.no ? (
            <Button className='w-full' onClick={action?.no}>Tidak</Button>
          ) : ''}
          {action?.ok ? (
            <Button className='w-full' onClick={action?.ok}>OK</Button>
          ) : ''}
          {action.ok ? (
            <Button className='w-full' onClick={action.ok}>OK</Button>
          ) : ''}
        </div>
      </div>
    </Alert>
  )
}

export default ActionAlert