import React from 'react'
import Button from '../../../../components/Button/Button'
import ListCard from '../../../../components/ListCard/ListCard'
import 'react-loading-skeleton/dist/skeleton.css'
import { IoRefreshOutline } from 'react-icons/io5'

const ShipListCard = ({
  antrian,
  lelang
}) => { 

  return (
    <ListCard
      id={'queue-list'}
      header={
        <div className='flex items-center justify-between w-full'>
          <span>Antrian Kapal</span>
          <Button color={'gray'} variant={'outlined'} title={'Muat Ulang Antrian Kapal'} onClick={() => antrian.fetch()}>
            <div className='flex items-center space-x-2'> <IoRefreshOutline/></div>
           
          </Button>
        </div> 
      }
      data={antrian?.list}
      renderData={(sp, idx) => (
        <div key={idx} className={'grid grid-cols-3 w-full p-5 text-sm items-start border-b group'}>
          <div  className={`col-span-2 space-y-1`}>
            {/* <div className='text-xs'>Antrian {sp.NoAntrian < 10 ? `00${sp.NoAntrian}` : sp.NoAntrian < 100 ? `0${sp.NoAntrian}` : idx + 1}</div> */}
            <div className='font-semibold'>{sp.Nelayan?.NamaKapal}</div>
            <div>{sp.Nelayan?.NoKartuNelayan}</div>
          </div>
          {(lelang.status === false) && (
            <div className='flex justify-end transition-all opacity-0 group-hover:opacity-100'>
             <Button
               size={'sm'}
               color={'orange'}
               onClick={(e) => {
                 antrian.mulaiLelang(e, sp, idx)
               }}
               className={'whitespace-nowrap'}
             >
               Mulai Lelang
             </Button>
           </div>
          )}         
        </div>     
      )} 
    />
  )
}

export default ShipListCard