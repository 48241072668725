import React from 'react'
import './MessageBox.css'

const MessageBox = ({
  className = '',
  color,
  children
}) => {
  
  const messageBoxColors = ['blue', 'orange', 'red', 'yellow', 'green', 'gray']

  const CHECK_MESSAGE_BOX_COLOR = messageBoxColors.find(x => x === color) ? color : messageBoxColors[0]

  return (
    <div className={`msg-box msg-box--${CHECK_MESSAGE_BOX_COLOR} ${className}`}>
      {children}
    </div>
  )
}

export default MessageBox