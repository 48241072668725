import axios from "axios"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/slices/userSlice/userSlice"
import { API_BASE_URL } from "../../../utils/urls"

export const useFetchHargaPembukaan = () => {
  const dispatch = useDispatch()
  
  const fetchHargaPembukaan = async(token, idJenisIkan, idTpi, setHargaPembukaan) => {
    if(!idJenisIkan || !idTpi) return

    const config = {
      url: '/dash/harga-pembukaan-ikan',
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      params: {
        query: {"IdJenisIkan": Number(idJenisIkan), "IdTpi": Number(idTpi)}
      }
    }
    try {
      const { data } = await axios(config)
      if (!data) return       
      const pembukaan = data.data
      setHargaPembukaan(pembukaan)
    } catch (error) {
      if(error.message.includes('401')) return dispatch(logout())
    }
  } 

  return fetchHargaPembukaan
}