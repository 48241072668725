import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5"
import { useSelector } from "react-redux"
import useToggle from "../../hooks/useToggle"
import { API_BASE_URL } from "../../utils/urls"

const GLOBAL = 'global'
const GLOBAL_SETTING = 'globalSetting'
const FEE_NELAYAN = 'feeNelayan'
const ERROR = 'error'
const SUCCESS = 'success'

export const useSettingViewModel = () => {

  const state = useSelector(state => state) 
  const { token, user } = state?.user?.userLogin?.userInfo

  const [globalAlert, setGlobalAlert] = useState(null)

  const globalAlertConfig = {
    global: {
      error: {
        noId: {
          title: 'Id TPI Tidak Ditemukan'
        }
      }
    },
    globalSetting: {
      error: {
        fetch: {
          title: 'Gagal Mendapatkan Global Setting',
          action: {
            try: () => {
              globalSettingHandler('GET')
              closeGlobalAlert()
            }
          }
        },
        send: {
          title: 'Gagal Mengubah Global Setting',
          action: {
            try: () => {
              globalSettingHandler('SEND')
              closeGlobalAlert()
            },
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
      },
      success: {
        send: {
          title: 'Berhasil Mengubah Global Setting',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
      }
      
    },
    feeNelayan: {
      error: {
        fetch: {
          title: 'Gagal Mendapatkan Retribusi',
          action: {
            try: () => {
              fetchFeeNelayan()
              closeGlobalAlert()
            }
          }
        },
        send: {
          title: 'Gagal Menambahkan Retribusi',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
        edit: {
          title: 'Gagal Mengubah Retribusi',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
        delete: {
          title: 'Gagal Menghapus Retribusi',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
      },
      success: {
        send: {
          title: 'Berhasil Menambahkan Retribusi',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
        edit: {
          title: 'Berhasil Mengubah Retribusi',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
        delete: {
          title: 'Berhasil Menghapus Retribusi',
          action: {
            ok: () => {
              closeGlobalAlert()
            }
          }
        },
      }
      
    },
  }
  
  const showGlobalAlert = (selector, type, typeSelector) => {
    if(!selector || !type || !typeSelector) return
    const types = [
      {
        type: SUCCESS,
        icon: <IoCheckmarkCircle  className='text-6xl text-green-600'/>
      }, 
      {
        type: ERROR,
        icon: <IoCloseCircle className='text-6xl text-red-600'/>
      },
    ]
    const CHECK_TYPE = types.find(x => x.type === type) || types[0]
    const config = {
      icon: CHECK_TYPE.icon, 
      ...globalAlertConfig[selector][CHECK_TYPE.type][typeSelector]
    }
    setGlobalAlert(config)
  }

  const showNoIdAlert = () => {
    showGlobalAlert(GLOBAL, ERROR, 'noId')
  }

  const closeGlobalAlert = () => {
    setGlobalAlert(null)
  }

  const [globalSetting, setGlobalSetting] = useState({ loading: true, error: '', data: {} })
  const [globalSettingDetail, setGlobalSettingDetail] = useState({})
  const [globalSettingAlert, toggleGlobalSettingAlert] = useToggle(false)

  const globalSettingDetailOnChangeHandler = (e, data) => {
    if(data) return setGlobalSettingDetail(data)
    setGlobalSettingDetail({...globalSettingDetail, [e.target.name]: e.target.value})
  }

  const globalSettingHandler = async(method, data, callback) => {
    setGlobalSetting({ loading: false, data })
    const config = {
      url: `tpi/${user.id_tpi}/setting`,
      method: method ,
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: data ? {
        ...data
      } : null
    }
    try {
      const response = await axios(config)
      setGlobalSetting({ loading:false, data: response.data.data })
      setGlobalSettingDetail(response.data.data)
      callback && callback({ data: response.data, error: ''})
      method === 'PUT' && showGlobalAlert(GLOBAL_SETTING, SUCCESS, 'send')
    } catch (error) {
      setGlobalSetting({ loading:false, error: error.message })
      callback && callback({ data: null, error: error})
      showGlobalAlert(GLOBAL_SETTING, ERROR, method === 'GET' ? 'fetch' : 'send')
    }
  }

  const fetchGlobalSetting = () => {
    globalSettingHandler('GET')
  }

  const editGlobalSetting = (callback) => {
    globalSettingHandler(
      'PUT', 
      {
        idGlobalSetting: globalSettingDetail.IdGlobalSetting,
        idTpi: globalSettingDetail.IdTpi,
        feeNelayan: globalSettingDetail.FeeNelayan,
        feePedagang: globalSettingDetail.FeePedagang,
        selisihHarga: globalSettingDetail.SelisihHarga,
        timer: Number(globalSettingDetail.Timer)
        
      },
      callback)
  }  

  const initialState = {
    feeNelayan: {
      detail: {id: 0, NamaFee: '', NominalFee  : ''}
    }
  }

  const [feeNelayan, setFeeNelayan] = useState({ loading: true, error: '', data: {}})
  const [feeNelayanDetail, setFeeNelayanDetail] = useState(initialState.feeNelayan.detail)
  const [feeNelayanAlert, setFeeNelayanAlert] = useState(null)
  const [feeNelayanErrors, setFeeNelayanErrors] = useState(null)

  const feeNelayanDetailOnChangeHandler = (e, data) => {
    if(data) return setFeeNelayanDetail(data)
    setFeeNelayanDetail({...feeNelayanDetail, [e.target.name]: e.target.value})
  }

  const fetchFeeNelayan = async() => {
    setFeeNelayan({...feeNelayan, loading:true })
    const config = {
      url: `tpi/${user.id_tpi}/fee-nelayan`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
    }
    try {
      const response = await axios(config)
      setFeeNelayan({ loading:false, data: response.data })
    } catch (error) {
      setFeeNelayan({ loading:false, error: error.message })
      showGlobalAlert(FEE_NELAYAN, ERROR, 'fetch')
    }
  }

  const addFeeNelayan = async(payload, callback) => {
    setFeeNelayan({...feeNelayan, loading:true })
    const config = {
      url: `tpi/${user.id_tpi}/fee-nelayan`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: {
        idTpi: Number(user.id_tpi),
        ...payload,
      }
    }
    try {
      const response = await axios(config)
      callback && callback({ data: response.data, error: ''})
      showGlobalAlert(FEE_NELAYAN, SUCCESS, 'send')
    } catch (error) {
      callback && callback({ data: null, error: error})
      showGlobalAlert(FEE_NELAYAN, ERROR, 'send')
    }
  }

  const feeNelayanDetailHandler = async(payload, method, callback) => {
    setFeeNelayanDetail({ loading:true, data: payload })
    const config = {
      url: `tpi/${user.id_tpi}/fee-nelayan/${payload.IdMasterFeeNelayan}`,
      method: method || 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: payload
    }
    try {
      const response = await axios(config)
      setFeeNelayanDetail({ loading:false, data: response.data })
      callback && callback({data: response.data, error: ''})
      console.log(globalAlertConfig[FEE_NELAYAN][SUCCESS][method === 'PUT' ? 'edit' : method === 'DELETE' ? 'delete' : 'fetch' ])
      showGlobalAlert(FEE_NELAYAN, SUCCESS, method === 'PUT' ? 'edit' : method === 'DELETE' ? 'delete' : 'fetch' )
      
    } catch (error) {
      setFeeNelayanDetail({ loading:false, error: error.message })
      callback && callback({data: null, error: error})
      console.log(globalAlertConfig[FEE_NELAYAN][ERROR][method === 'PUT' ? 'edit' : method === 'DELETE' ? 'delete' : 'fetch' ])
      showGlobalAlert(FEE_NELAYAN, ERROR, method === 'PUT' ? 'edit' : method === 'DELETE' ? 'delete' : 'fetch' )
    }
  }

  const editFeeNelayan = (payload, callback) => {
    feeNelayanDetailHandler(payload, 'PUT', callback)
  }

  const deleteFeeNelayan = (payload, callback) => {
    feeNelayanDetailHandler(payload, 'DELETE', callback)
  }

  const feeNelayanErrorsHandler = (errors) => {
    if(Object.entries(errors).length > 0) return setFeeNelayanErrors(errors)
    setFeeNelayanErrors(null)
  }

  const toggleFeeNelayanAlert = (value) => {
    setFeeNelayanAlert(feeNelayanAlert ? null : value)
  }

  const [keranjang, setKeranjang] = useState({ loading: true, error: '', data: {}})
  const [keranjangDetail, setKeranjangDetail] = useState({})
  const [keranjangAlert, setKeranjangAlert] = useState(null)
  const [keranjangErrors, setKeranjangErrors] = useState(null)

  const keranjangDetailOnChangeHandler = (e, data) => {
    if(data) return setKeranjangDetail(data)
    setKeranjangDetail({...keranjangDetail, [e.target.name]: e.target.value})
  }

  const fetchKeranjang = async() => {
    setKeranjang({...keranjang, loading:true })
    const config = {
      url: `tpi/${user.id_tpi}/keranjang`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      params: {
        order: 'IdMasterKeranjang ASC'
      }
    }
    try {
      const response = await axios(config)
      setKeranjang({ loading:false, data: response.data })
    } catch (error) {
      setKeranjang({ loading:false, error: error.message })
    }
  }

  const addKeranjang = async(payload, callback) => {
    setKeranjang({...keranjang, loading:true })
    const config = {
      url: `tpi/${user.id_tpi}/keranjang`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: {
        idTpi: Number(user.id_tpi),
        ...payload,
      }
    }
    try {
      const response = await axios(config)
      callback && callback({ data: response.data, error: ''})
    } catch (error) {
      callback && callback({ data: null, error: error})
    }
  }

  const keranjangDetailHandler = async(payload, method, callback) => {
    setKeranjangDetail({ loading:true, data: payload })
    const config = {
      url: `tpi/${user.id_tpi}/keranjang/${payload.IdMasterKeranjang}`,
      method: method || 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: payload
    }
    try {
      const response = await axios(config)
      setKeranjangDetail({ loading:false, data: response.data })
      callback && callback({data: response.data, error: ''})

    } catch (error) {
      setKeranjangDetail({ loading:false, error: error.message })
      callback && callback({data: null, error: error})
    }
  }

  const editKeranjang = (payload, callback) => {
    keranjangDetailHandler(payload, 'PUT', callback)
  }

  const deleteKeranjang = (payload, callback) => {
    keranjangDetailHandler(payload, 'DELETE', callback)
  }

  const keranjangErrorsHandler = (errors) => {
    if(Object.entries(errors).length > 0) return setKeranjangErrors(errors)
    setKeranjangErrors(null)
  }

  const toggleKeranjangAlert = (value) => {
    setKeranjangAlert(keranjangAlert ? null : value)
  }

  useEffect(() => {
    if(user.id_tpi) return 
    showNoIdAlert()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id_tpi])

  return {
    global: {
      alert: {
        state: globalAlert,
        handler: {
          closeGlobalAlert,
          showGlobalAlert
        }
      }
    },
    globalSetting: {
      state: {
        list: globalSetting,
        detail: globalSettingDetail,
        alert: globalSettingAlert
      },
      handler: {
        fetch: fetchGlobalSetting,
        edit: editGlobalSetting,
        alert: toggleGlobalSettingAlert,
        onChange: globalSettingDetailOnChangeHandler
      }
    },
    feeNelayan: {
      state: {
        list: feeNelayan,
        detail: feeNelayanDetail,
        errors: feeNelayanErrors,
        alert: feeNelayanAlert
      },
      handler: {
        fetchList: fetchFeeNelayan,
        add: addFeeNelayan,
        edit: editFeeNelayan,
        delete: deleteFeeNelayan,
        onChange: feeNelayanDetailOnChangeHandler,
        errors: feeNelayanErrorsHandler,
        alert: toggleFeeNelayanAlert
      },
    },
    keranjang: {
      state: {
        list: keranjang,
        detail: keranjangDetail,
        errors: keranjangErrors,
        alert: keranjangAlert
      },
      handler: {
        fetchList: fetchKeranjang,
        add: addKeranjang,
        edit: editKeranjang,
        delete: deleteKeranjang,
        onChange: keranjangDetailOnChangeHandler,
        errors: keranjangErrorsHandler,
        alert: toggleKeranjangAlert
      },
    }
  }
}