import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import Cookies from "js-cookie"
import { API_BASE_URL } from "../../../../utils/urls"

const setCookies = (name, state) => {
  let cookiesObj = Cookies.get(name) ? JSON.parse(Cookies.get(name)) : {}
  const stateEntries = Object.entries(state)
  stateEntries.forEach(([key, value]) => {
    cookiesObj[key] = value
  })
  Cookies.set(name, JSON.stringify(cookiesObj))
} 

export const fetchListAntrian = createAsyncThunk(
  'auction/fetchListAntrian',
  async (payload, thunkAPI) => {    
    const state = thunkAPI.getState()
    const userInfo = state.user.userLogin.userInfo
    const token = userInfo.token 
    const config = {
      url: '/dash/antrian-kapal',
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      params: {
        query: {
          "TangalAntrian": payload,
          "Status":"1"
        }
      }
    }
    const response = await axios(config)
    if (response) return response.data
  }
)

export const fetchInfoAntrian = createAsyncThunk(
  'auction/fetchInfoAntrian',
  async (id, thunkAPI) => {    
    const state = thunkAPI.getState()
    const userInfo = state.user.userLogin.userInfo
    const token = userInfo.token 
    const config = {
      url: `/dash/antrian-kapal/${id}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
     
    }
    const response = await axios(config)
    if (response) return response.data
  }
)

export const antrianExtraReducer = (builder) => {
  builder.addCase(fetchListAntrian.pending, (state, action) => {
    state.antrian.list.loading = true
  })
  builder.addCase(fetchListAntrian.fulfilled, (state, action) => {
    state.antrian.list.loading = false 
    state.antrian.list.data = action.payload.data.Records
  })
  builder.addCase(fetchListAntrian.rejected, (state, action) => {
    state.antrian.list.loading = false 
    state.antrian.list.error = action.error.message
  })
  builder.addCase(fetchInfoAntrian.pending, (state, action) => {
    state.antrian.info.loading = true
  })
  builder.addCase(fetchInfoAntrian.fulfilled, (state, action) => {
    state.antrian.info.loading = false 
    state.antrian.info.data = action.payload.data
    setCookies('auction/antrian/info', {
      loading: false,
      data: action.payload.data
    })
  })
  builder.addCase(fetchInfoAntrian.rejected, (state, action) => {
    state.antrian.info.loading = false 
    state.antrian.info.error = action.error.message
  })
} 