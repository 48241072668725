import React from 'react'
import './Chip.css'

const Chip = ({
  className = '',
  children,
  color,
  size,
  variant,
  onClick,
  disabled,
}) => {

  const chipVariants = ['default', 'outline']
  const chipSizes = ['xs', 'sm', 'md', 'lg', 'xl']
  const chipColors = ['blue', 'orange', 'red', 'gray']
  
  const CHECK_CHIP_SIZE = chipSizes.find(x => x === size) ? size : 'md'
  const CHECK_CHIP_VARIANT = chipVariants.find(x => x === variant) ? variant : chipVariants[0]
  const CHECK_CHIP_COLOR = chipColors.find(x => x === color) ? color : chipColors[0]

  return (
    !disabled ? (
      <button className={`chip chip--${CHECK_CHIP_SIZE} chip--${CHECK_CHIP_COLOR} chip--${CHECK_CHIP_VARIANT} ${className}`} onClick={onClick}>
        {children}
      </button>
    ) : (
      <div className={`chip chip--${CHECK_CHIP_SIZE} chip--${CHECK_CHIP_VARIANT} chip--disabled ${className}`}>
        {children}
      </div>
    )
  )
}

export default Chip