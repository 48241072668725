import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import IconContainer from '../../components/IconContainer/IconContainer'
import Notification from '../../components/Notification/Notification'
import Select from '../../components/Select/Select'
import Table from '../../components/Table/Table'
import TextField from '../../components/TextField/TextField'
import { useUrlParams } from '../../hooks/useUrlParams'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import { API_BASE_URL } from '../../utils/urls'



const TransactionsScreen = () => {

  const state = useSelector(state => state)
  const userLogin = state.user.userLogin.userInfo
  const token = userLogin.token

  const initialState = {
    search: {type: '', keyword: '', errors: null},
    notif: {show: false, header: '', message: '', color: ''}
  }

  const [search, setSearch] = useState(initialState.search)
  const [notif, setNotif] = useState(initialState.notif)
  const [loading, setLoading] = useState(false)
  const [shipList, setShipList] = useState({})
  
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useUrlParams()

  const searchOnChangeHandler = (e) => {
    setSearch({...search, keyword: e.target.value})
  }

  const searchOnClickHandler = () => {
    let errs = {}
    if(!search.type) errs.type = 'Tipe data pencarian harus diisi'
    const errsLengthMoreThanZero = Object.entries(errs).length > 0
    setSearch({...search, errors: errsLengthMoreThanZero ? errs : initialState.search.errors})
    setNotif(errsLengthMoreThanZero ? {...notif, show: true, message: 'Pencarian harus dilengkapi', color: 'red'} : initialState.notif)
    if(errsLengthMoreThanZero) return 
    fetchAllNelayan(`{"${search.type.value}": ${search.type.type === 'string' ? `"${search.keyword}"` : search.keyword}}`,responseHandler)
  }

  const resetOnClickHandler = () => {
    fetchAllNelayan(null, responseHandler)
    setSearch(initialState.search)
  }

  const closeNotif = () => setNotif({...notif, show: false})

  const optionOnSelectHandler = (e, data, idx) => {
    setSearch({...search, type: data})
  }
  
  const paginationOnClickHandler = (pageVal) => {
    navigate({pathname: '/transactions', search: `?page=${pageVal}`})
  }

  const responseHandler = (res, err) => {
    if(res) return setShipList(res)
  }

  const fetchAllNelayan = async( query, callBack) => {
    const page = urlParams.get('page')
    let res, err
    setLoading(true)
    try {
      const {data} = await axios.get(`${API_BASE_URL}/nelayan`, {
        headers: {
          Authorization: token
        },
        params: {
          page: page || 1,
          query: query
        }
      })
      res = data.data
    } catch (error) {
      err = error
    }
    callBack && callBack(res, err)
    setLoading(false)
  }

  useEffect(() => {   
    fetchAllNelayan(null, responseHandler)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const pickData = [
    {
      title: 'ID Master Nelayan',
      selector: (tr) => tr.IdMasterNelayan
    },
    {
      title: 'Nama Kapal',
      selector: (tr) => tr.NamaKapal
    },
    {
      title: 'Nama Pemilik Kapal',
      selector: (tr) => tr.NamaPemilikKapal
    },      
  ]

  const listTipeDataPencarian = [
    { id: 1, value: 'IdMasterNelayan', label: 'Id Master Nelayan', type: 'number'},
    { id: 2, value: 'NamaKapal', label: 'Nama Kapal', type: 'string'},
    { id: 3, value: 'NamaPemilikKapal', label: 'Nama Pemilik Kapal', type: 'string'},
    
  ]

  return (
    <GlobalLayout contentClassName='overflow-auto'>
      <Card className='flex flex-col w-full p-5'>
        <div className='mb-8 text-xl font-bold'>Daftar Transaksi</div>
        <div className="grid w-full grid-cols-12 gap-5">
          <div className="grid items-end grid-cols-12 gap-3 col-span-full">
            <TextField 
              wrapperClassName='col-span-8' 
              label={'Kata Kunci Pencarian'} 
              value={search.keyword} 
              onChange={searchOnChangeHandler}
            />
            <Select 
              wrapperClassName='col-span-2' 
              label={'Tipe Data Pencarian'} 
              name={'select-tipe-data'} 
              dropdownData={listTipeDataPencarian} 
              value={search.type?.label || ''} 
              optionOnSelect={optionOnSelectHandler}
            />
            <div className={`grid grid-cols-2 col-span-2 gap-3`}>
              <Button size={'lg'} color={'red'} onClick={searchOnClickHandler}>Cari</Button>
              <Button size={'lg'} color={'red'} variant={'outline'} onClick={resetOnClickHandler}>Reset</Button>
            </div>
          </div>
          {loading ? (
            <Skeleton containerClassName='h-96 w-full rounded-lg overflow-hidden col-span-full' className='w-full h-full'/>
          ) : (
            <Table
              containerClassName='col-span-full w-full'
              pagination={true}
              page={shipList.Page}
              pageTotal={shipList.TotalPage}
              maxDataPreview={shipList.Limit}
              paginationOnClick={paginationOnClickHandler}
              pickData={pickData}
              data={shipList.Records || []}
              actions={(rowData, idx) => (
                <button onClick={() => navigate(`/transactions/${rowData.NamaKapal}`)}>
                  <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md cursor-pointer hover:text-etpi-blue hover:border-etpi-blue' >
                    <IoEyeOutline />
                  </IconContainer>
                </button>
              )}
            />
          )}
        </div>
      </Card>
      <Notification 
        notification={notif.show}
        notifClose={closeNotif}
        header={notif.message}
        notifColor={notif.color}
      />
    </GlobalLayout>
  )
}

export default TransactionsScreen