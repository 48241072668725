import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { IoCheckmarkCircle, IoClose, IoCloseCircle, IoEyeOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import Card from '../../components/Card/Card'
import IconContainer from '../../components/IconContainer/IconContainer'
import Table from '../../components/Table/Table'
import TextField from '../../components/TextField/TextField'
import { useGetDate } from '../../hooks/useGetDate'
import { useMoneyDigits } from '../../hooks/useMoneyDigits'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import { CURRENCY } from '../../utils/currency'
import { API_BASE_URL } from '../../utils/urls'
import Alert from '../../components/Alerts/Alert/Alert'
import Button from '../../components/Button/Button'
import Skeleton from 'react-loading-skeleton'
import './SettlementFisherScreen.css';
import { logout } from '../../redux/slices/userSlice/userSlice'
import ActionAlert from '../../components/Alerts/ActionAlerts/ActionAlert'

const SettlementFisherScreen = () => {

  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const token = state.user.userLogin.userInfo.token
  const getDate = useGetDate()
  const moneyDigits = useMoneyDigits()

  const [page, setPage] = useState(1)
  const [pageTotal, setPageTotal] = useState(1)
  const [maxDataPreview, setMaxDataPreview] = useState(1)
  const [transactions, setTransactions] = useState(null)
  const [detailPopUp, setDetailPopUp] = useState(null)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [submitAlert, setSubmitAlert] = useState(null)
  const [actionAlert, setActionAlert] = useState(null)

  const searchOnChange = (e) => {
    setSearch(e.target.value)
  }

  const logoutHandler = () => {
    dispatch(logout())
  }

  const paginationOnClickHandler = (value) => {
    setPage(value)
    console.log('clck')
  }

  const closePopUp = (e) => {
    setDetailPopUp(null)
  }

  const fetchSettlement = async(token, setTransactions, params) => {
    setLoading(true)
    let processedParams = params.join('&')
    const config = {
      url: `/settlement-nelayan${params ? `?${processedParams}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token,
      },
    }
    try {
      const { data } = await axios(config)
      if(!data?.data) return
      const resData = data.data
      let dataRecords = [];
      const resDataRecord = resData.Records === null ? [] : resData.Records
      for (let i = 0; i < resDataRecord.length; i++) {
          dataRecords.push({
            lelang:     resData.Records[i].lelang,
            retribusi:  resData.Records[i].retribusi,
            transaksi:  resData.Records[i].transaksi,
            isSelected: false
          });
      }
      setTransactions(dataRecords)
      setPageTotal(resData.TotalPage)
      setMaxDataPreview(resData.Limit)
      setPage(resData.Page)
      setLoading(false)
    } catch(err) {
      if(err.message.includes('401')) return logoutHandler()
      setSubmitAlert({message: err.message})
      setLoading(false)
    }
  }


  useEffect(() => {
    let params = [`page=${page}`, 'limit=20']
    if(search !== '') params.push(`id_antrian_lelang=${Number(search)}`)
    fetchSettlement(token, setTransactions, params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, token])

  const dateProcessor = (val) => {
    const gotDate = getDate(val)
    const date = gotDate.date
    const month = gotDate.month
    const year = gotDate.year
    return `${date}-${month}-${year}`
  }

  const pickData = [
    {
      title: 'Nama Kapal',
      selector: (tr) => tr.lelang.nama_kapal
    },
    {
      title: 'ID Antrian',
      selector: (tr) => tr.lelang.id_antrian_lelang
    },  
    {
      title: 'Total Transaksi',
      selector: (tr) => tr.lelang['total_penerimaan_tpi'],
      processor: (val) => `${CURRENCY} ${ moneyDigits(val)}`
    },
    {
      title: 'Tanggal Transaksi',
      selector: (tr) => tr.lelang.tanggal,  
      processor: dateProcessor
    },
  ]

  const detailPopUpPickData = [
    {
      title: 'Nama Ikan',
      selector: (tr) => tr['nama_ikan'],
    },
    {
      title: 'Berat (kg)',
      selector: (tr) => tr['berat'],
    },
    {
      title: 'Harga Aktual (Rp)',
      selector: (tr) => tr['harga_aktual'],
      processor: (value) => moneyDigits(value)
    },
    {
      title: 'Transaksi (Rp)',
      selector: (tr) => tr['total_transaksi'],
      processor: (value) => moneyDigits(value)
    },
  ]

  const disburse = async() => {
    setLoading(true)
    const config = {
      url: `/disburse-nelayan`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: {
        id_antrian_lelang : Number(detailPopUp.lelang.id_antrian_lelang)
      }
    }
    try {
      await axios(config)
      setDetailPopUp(null)
      setActionAlert({
        icon: <IoCheckmarkCircle className='text-6xl text-green-600'/>,
        title: 'Berhasil Disburse',
        action: {
          ok: async() => {
            await fetchSettlement(token, setTransactions, [`page=${page}`, 'limit=20']) 
            setActionAlert(null)
          }
        }
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setActionAlert({
        icon: <IoCloseCircle className='text-6xl text-red-600'/>,
        title: 'Gagal Disburse',
        action: {
          try: disburse,
          ok: () => { setActionAlert(null) }
        }
      })
    }
  }
  
  return (
    <>
      <GlobalLayout contentClassName='' loading={loading}>
        <div className="flex flex-col w-full h-full overflow-x-hidden overflow-y-scroll rounded-xl">
          <div className="flex flex-col w-full h-full">
            <Card className='flex flex-col w-full p-5'>
              <div className='mb-8 text-xl font-bold'>Daftar Transaksi Kapal</div>
              <div className="grid w-full grid-cols-12 gap-5">
                <TextField wrapperClassName='col-span-3' label={'Cari ID Antrian Lelang'} variant={'search'} value={search} onChange={searchOnChange}/>
                {loading ? (
                  <Skeleton containerClassName='h-96 w-full rounded-lg overflow-hidden col-span-full' className='w-full h-full'/>
                ) : (
                  <Table
                    containerClassName='col-span-full w-full'
                    pagination={true}
                    page={page}
                    pageTotal={pageTotal}
                    maxDataPreview={maxDataPreview}
                    paginationOnClick={paginationOnClickHandler}
                    pickData={pickData}
                    data={transactions || []}
                    actions={(rowData, idx) => (
                      <button onClick={() => setDetailPopUp(rowData)}>
                        <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md cursor-pointer hover:text-etpi-blue hover:border-etpi-blue' >
                          <IoEyeOutline />
                        </IconContainer>
                      </button>
                    )}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </GlobalLayout>
      {detailPopUp && (
        <Alert size={'4xl'} className={`max-h-[80vh] h-full flex flex-col`} header={<div className='text-xl font-bold'>Detail Transaksi</div>} headerClose closeFunction={closePopUp}>
          <div className="flex w-full h-full">
            <div className='relative flex w-full h-full'>
              <div className='absolute inset-0 overflow-y-auto divide-y-8'>
                <div className="flex w-full p-5">
                  <div className="flex-shrink-0 w-2/5">
                    <img src={`${API_BASE_URL}/public/${detailPopUp.lelang.barcode}`} alt="barcode" />
                  </div>
                  <div className='flex flex-col w-full space-y-2'>
                    <div className='flex justify-between w-full'>
                      <div>Nama Kapal</div>
                      <div className='font-semibold text-right'>{detailPopUp.lelang.nama_kapal}</div>
                    </div>
                    <div className='flex justify-between w-full'>
                      <div>Id Antrian</div>
                      <div className='font-semibold text-right'>{detailPopUp.lelang.id_antrian_lelang}</div>
                    </div>
                    <div className='flex justify-between w-full'>
                      <div>Tanggal Transaksi</div>
                      <div className='font-semibold text-right'>{dateProcessor(detailPopUp.lelang.tanggal)}</div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full p-5'>
                  <div className='mb-3 text-lg font-semibold'>
                    Daftar Transaksi
                  </div>
                  <Table
                    data={detailPopUp.transaksi}
                    pickData={detailPopUpPickData}
                  />

                </div>
                <div className='flex flex-col w-full p-5 space-y-2'>
                  <div className='flex justify-between w-full'>
                    <div>Total Transaksi</div>
                    <div className='font-semibold text-right'>{CURRENCY}{moneyDigits(detailPopUp.lelang.total_transaksi)}</div>
                  </div>
                  <div className='flex justify-between w-full'>
                    <div>Fee Nelayan ({detailPopUp.lelang.fee_nelayan}%)</div>
                    <div className='font-semibold text-right'>-{CURRENCY}{moneyDigits(detailPopUp.lelang.fee_nelayan_rp)}</div>
                  </div>
                  {detailPopUp.retribusi && detailPopUp.retribusi.map((rtb, rtbIdx) => (
                    <div key={rtbIdx} className='flex justify-between w-full'>
                      <div>{rtb.nama_fee} ({rtb.nominal_fee}%)</div>
                      <div className='font-semibold text-right'>-{CURRENCY}{moneyDigits(rtb.nominal_fee_rp)}</div>
                    </div>
                  ))}
                  <div className='flex justify-between w-full text-xl font-bold'>
                    <div>Total Penerimaan</div>
                    <div className='text-right '>{CURRENCY}{moneyDigits(detailPopUp.lelang.total_penerimaan)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/4 h-full p-5 text-base border-l">
              <Button size={'lg'} color={'red'} className='w-full' onClick={disburse} disabled={loading}>Disburse</Button>
            </div>
          </div>
        </Alert>
      )}
      {submitAlert && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <IoClose/>
            </IconContainer>
            <div className="text-2xl font-bold">
              Gagal
            </div>
            <p>{submitAlert.message}</p>
            <div className='grid w-full gap-2 mt-6'>
              <Button className='w-full' color={'red'} variant={'outline'} onClick={() => setSubmitAlert(null)}>
                OK
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {actionAlert && (
        <ActionAlert 
          {...actionAlert}
        />
      )}
    </>
  )
}

export default SettlementFisherScreen
