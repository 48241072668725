import axios from "axios"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/slices/userSlice/userSlice"
import { API_BASE_URL } from "../../../utils/urls"

export const useFetchListAntrian = (token, tanggal, setAntrian, setAntrianLoading) => {
  const dispatch = useDispatch()
  const config = {
    url: '/dash/antrian-kapal',
    method: 'GET',
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type" : "application/json",
      "Authorization" : token
    },
    params: {
      query: {
        "TangalAntrian": tanggal,
        "Status":"1",
      },
      order: "NoAntrian ASC"
    }
  }
  
  const fetchListAntrian = async() => {    
    try {
      setAntrianLoading(true)
      const { data } = await axios(config)
      if (!data) return
      setAntrianLoading(false)
      setAntrian(data.data.Records)
    } catch (error) {
      setAntrianLoading(false)
      if(error.message.includes('401')) return dispatch(logout())
    }
  }

  return fetchListAntrian
}