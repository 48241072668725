import React from 'react'
import Card from '../Card/Card'
import List from '../List/List'
import './ListCard.css'
import 'react-loading-skeleton/dist/skeleton.css'

const ListCard = ({
  id,
  header,
  headerColor,
  data = [],
  renderData
}) => {

  const headerColors = ['gray', 'green']

  const CHECK_HEADER_COLOR = headerColors.find(x => x === headerColor) ? headerColor : headerColors[0] 

  const CHECK_DATA = data || []

  return (
    <Card
      id={id}
      header={header}
      className='list-card'
      headerClassName={`list-card__header list-card__header--${CHECK_HEADER_COLOR}`}
      contentClassName='list-card__content'
    >
      <List className="absolute inset-0 overflow-y-auto">
        {CHECK_DATA.map((ship, idx) => (
          <React.Fragment key={idx}>
            {renderData(ship, idx)}
          </React.Fragment>
        ))}
      </List>
    </Card>
  )
}

export default ListCard