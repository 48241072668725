import React from 'react'

const List = ({
  className,
  children
}) => {
  return (
    <ul className={`list ${className}`}>
      {children}
    </ul>
  )
}

export default List