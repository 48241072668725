import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { IoChevronDown, IoClose, IoTrash } from 'react-icons/io5'
import Button from '../../../../components/Button/Button'
import IconContainer from '../../../../components/IconContainer/IconContainer'
import useToggle from '../../../../hooks/useToggle'

const BasketAccordion = ({
  no,
  deleteIkan,
  namaIkan,
  beratIkan,
  listKeranjang,
  listKeranjangOnChange,
  listJenisKeranjang = [],
  validation,
  editable = true,
  deleteable = true,
  active,
  headerOnClick
}) => {


  const panel = useRef(null)


  const [showPanel, toggleShowPanel] = useToggle(active)
  const [panelMaxHeight, setPanelMaxHeight] = useState(0)

  const [validationMsg, setValidationMsg] = useState('')

  useEffect(() => {
    setValidationMsg(validation)
  }, [validation])

  const tambahKeranjang = () => {
    let dkArr = [...listKeranjang]
    dkArr.push({
      id_keranjang: null,
      qty: null
    })
    listKeranjangOnChange(dkArr)
  }

  const hapusKeranjang = (idx) => {
    let dkArr = [...listKeranjang]
    dkArr.splice(idx, 1)
    listKeranjangOnChange(dkArr)
  }

  const keranjangInputOnChange = (e, query, idx) => {
    let dkArr = [...listKeranjang]
    dkArr[idx][query] = Number(e.target.value)
    listKeranjangOnChange(dkArr)
  }

  const headerOnClickHandler = (e) => {
    toggleShowPanel()
    headerOnClick && headerOnClick(e)
  }


  useEffect(() => {
    if(typeof active !== 'boolean') return
    toggleShowPanel(active)
  }, [active, showPanel, toggleShowPanel])

  useEffect(() => {
    if(!panel) return
    const currentPanel = panel.current
    const currentPanelScrollHeight = currentPanel.scrollHeight + 'px'
    setPanelMaxHeight((showPanel) ? currentPanelScrollHeight : 0)
  }, [showPanel, listKeranjang, ])

  useEffect(() => {
    toggleShowPanel(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namaIkan, beratIkan])
  

  return (
    <div>
      <div className={`flex flex-col w-full border rounded-lg ${validation ? 'border-etpi-red' : ''}`} >
        <div className='w-full'>
          <div className="flex items-center w-full text-left" >            
            {no && (
              <div className="p-5 text-center border-r">{no}.</div>
            )}
            <div className="flex-1 p-5">{namaIkan}</div>
            <div className="flex-1 pr-5 text-right">
              <div className='w-full text-right'>
                {beratIkan} Kg
              </div>
            </div>    
            <div className="flex items-center">
              {deleteable && (
                <button className="p-5 text-lg text-slate-400 hover:text-red-600" onClick={() => deleteIkan()}>
                  <IoClose/>
                </button>
              )}
              <button className='p-5 border-l'  onClick={headerOnClickHandler}>
                <IconContainer  className={`transition-all duration-150 ease-in-out ${(showPanel) ? '-rotate-180' : ''}`} >
                  <IoChevronDown/>
                </IconContainer> 
              </button>
            </div>      
                     
          </div>
        </div>
        
        <div ref={panel} className={`flex flex-col w-full overflow-y-hidden`} style={{maxHeight: panelMaxHeight}}>
          <div className="flex-shrink-0 w-full h-px bg-slate-200"></div>
          <div className="flex flex-col w-full p-5 space-y-5">
            <div className="flex items-center justify-between w-full">
              <div className="font-semibold ">
                Daftar Keranjang
              </div>
              {editable && (
                <Button color={'orange'} variant={'outline'} onClick={tambahKeranjang} disabled={listKeranjang.length >= listJenisKeranjang.length}>
                  Tambah Keranjang
                </Button>
              )}
            </div>
            {listKeranjang?.length > 0 && (
              <div className='flex flex-col w-full overflow-hidden border divide-y rounded-lg'>
                <table className='divide-y'>
                  <thead>
                    <tr className='divide-x'>
                      <th className='px-3 py-2'>
                        Jenis Keranjang
                      </th>
                      <th className='px-3 py-2'>
                        Jumlah Keranjang
                      </th>
                      {editable && (
                        <th className='px-3 py-2'>
                          Aksi
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {listKeranjang.map((krj, krjIdx) => (
                      <tr key={krjIdx} className='divide-x'>
                        <td>
                          {editable ? (
                            <select 
                              className='w-full px-3 text-center cursor-pointer h-11 focus:outline-none'
                              type="text" 
                              value={krj?.id_keranjang || krj?.id_master_keranjang || ''}
                              onChange={(e) =>keranjangInputOnChange(e, 'id_keranjang', krjIdx)}
                            >
                              <option value="" className='' disabled>-- Pilih Jenis Keranjang --</option>
                              {listJenisKeranjang.map((ljk, idx) => 
                                <option className='cursor-pointer' key={idx} value={ljk.IdMasterKeranjang} disabled={listKeranjang.find(x => x?.id_keranjang === ljk.IdMasterKeranjang)}>{ljk?.Nama}</option>
                              )}
                              
                            </select>
                          ) : (
                            <div 
                              className='flex items-center justify-center w-full px-3 text-center h-11 focus:outline-none'
                            >
                             {listJenisKeranjang.find(x => x.IdMasterKeranjang === krj?.id_keranjang)?.Nama || krj?.id_keranjang}
                              
                            </div>
                          )}
                        </td>
                        <td>
                          {editable ? (
                            <input 
                              className='w-full px-3 text-center h-11 focus:outline-none'
                              type="number"
                              min={1} 
                              value={krj?.qty || ''}
                              onChange={(e) =>keranjangInputOnChange(e, 'qty', krjIdx)}
                              autoComplete={'off'}
                            />
                          ) : (
                            <div 
                              className='flex items-center justify-center w-full px-3 text-center h-11 focus:outline-none'
                            >
                             {krj?.qty}                              
                            </div>
                          )}
                          
                        </td>
                        {editable && (
                          <td>
                            <IconContainer component={'button'} className='w-full h-full text-2xl text-gray-400' onClick={() => hapusKeranjang(krjIdx)}>
                              <IoTrash/>
                            </IconContainer>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {validationMsg && (
        <div className='text-sm text-etpi-red'>
          {validationMsg}
        </div>
      )}
    </div>
  )
}

export default BasketAccordion