import React from 'react'
import './IconContainer.css'

const IconContainer = ({
  className = '',
  children,
  onClick,
  component 
}) => {

  const CHECK_COMPONENT = component || 'div'

  return (
    <CHECK_COMPONENT className={`icon-container ${className}`} onClick={onClick}>
      {children}
    </CHECK_COMPONENT>
  )
}

export default IconContainer