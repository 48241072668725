import axios from "axios"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/slices/userSlice/userSlice"
import { API_BASE_URL } from "../../../utils/urls"

export const useFetchListJenisIkan = (token, setListJenisIkan) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const config = {
      url: '/jenis-ikan',
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
    }
    const fetchListJenisIkan = async() => {
      try {
        const { data } = await axios(config)
        if (!data) return
        let jenisIkanArr = []
        const dataJenisIkan = data.data.Records
        for (let i = 0; i < dataJenisIkan.length; i++) {
          jenisIkanArr.push({id: dataJenisIkan[i].IdJenisIkan, value: dataJenisIkan[i].NamaIkan})          
        }
        setListJenisIkan(jenisIkanArr)
      } catch (error) {
        if(error.message.includes('401')) return dispatch(logout())
      }
    } 
    fetchListJenisIkan()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListJenisIkan, token])
}