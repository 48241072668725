import React, { useEffect, useState } from 'react'
import TextField from '../../components/TextField/TextField'
import Title from '../../components/Title/Title'
import AuthLayout from '../../layouts/AuthLayout/AuthLayout'
import { FaSpinner } from 'react-icons/fa'
import Button from '../../components/Button/Button'
import { PASSWORD_VALIDATION, USER_ID_VALIDATION } from '../../utils/inputValidations'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/slices/userSlice/userSlice'
import MessageBox from '../../components/Boxes/MessageBox/MessageBox'
import { useNavigate } from 'react-router-dom'

const LoginScreen = () => {
  
  const [userID, setUserID] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  
  const state = useSelector(state => state)
  const userLogin = state.user.userLogin
  const { loading, error, userInfo } = userLogin

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const textFields = [
    {
      label: 'User ID',
      name: 'userID',
      type: 'number',
      value: userID,
      onChange: (e) => setUserID(e.target.value),
      placeHolder: 'User ID Anda',
      required: true,
      message: USER_ID_VALIDATION.message,
      validation: errors?.userID?.message
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      value: password,
      onChange: (e) => setPassword(e.target.value),
      adornment: true,
      adornmentPosition: 'right',
      placeHolder: 'Password Anda',
      required: true,
      message: PASSWORD_VALIDATION.message,
      validation: errors?.password?.message,
      variant: 'password',
    }
  ]

  const valueValidator = (data, errorList) => {
    if (data.value === '') return errorList[data.name] = { type: 'required', message: data.message.required }
    if (data.type === 'number' && isNaN(data.value)) return { type: 'type', message: data.message.type }
    if (data.pattern && !data.pattern.test(data.value)) return errorList[data.name] = { type: 'pattern', message: data.message.pattern }
  }

  const validateValue = (data) => {
    let errorList = {}
    for (let i = 0; i < data.length; i++) {
      const el = data[i]
      if (el.required) valueValidator(el, errorList)
    }
    return Object.entries(errorList).length > 0 ? errorList : null
  }

  const submit = (e) => {
    e.preventDefault()
    const validation = validateValue(textFields)
    setErrors(validation)
    if (validation) return 
    dispatch(login({ userid: userID, password: password }))
  }

  useEffect(() => {
    if (!userInfo) return
    navigate('/')
  }, [userInfo, navigate])

  return (
    <AuthLayout>
      <div className='fixed inset-0 flex items-center justify-center space-x-40 -mt-[4%]'>
        <div className="flex items-center justify-center">
          <div className='flex flex-col items-center'>
            <img src={`./assets/img/logo_white.png`} alt={'logo'} className={'h-56 mb-5'}/>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <form className="flex flex-col flex-shrink-0 w-full max-w-md p-8 space-y-5 bg-white rounded-md shadow-xl">
            <Title
              containerClassName='space-y-2 items-center text-center pb-5'
              title={'Selamat Datang'}
              titleWeight={'bold'}
              titleClassName={'text-4xl'}
              subtitle={'Silahkan masukkan User ID dan Password Anda'}
              subtitlePosition={'bottom'}
              subtitleClassName={'text-sm opacity-70'}
            />
            {error ? (
              <MessageBox color={'red'}>
                {error}
              </MessageBox>
            ) : ''}
            <div action="" className='space-y-3'>
              {textFields.map((field, idx) => (
                <TextField 
                  key={idx}
                  {...field}  
                />
              ))}
            </div>
            <div className='w-full pt-5'>
              <Button color={'orange'} onClick={submit} disabled={loading} className={'w-full'}>
                {loading ? (
                  <div className='text-lg animate-spin'><FaSpinner /></div>
                ) : (
                  <div>Masuk</div>
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  )
}

export default LoginScreen