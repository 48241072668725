import axios from 'axios'
import Cookies from 'js-cookie'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { IoEyeOutline } from 'react-icons/io5'
import Card from '../../components/Card/Card'
import IconContainer from '../../components/IconContainer/IconContainer'
import Select from '../../components/Select/Select'
import Table from '../../components/Table/Table'
import TextField from '../../components/TextField/TextField'
import { useGetDate } from '../../hooks/useGetDate'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import { API_BASE_URL } from '../../utils/urls'
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../components/Button/Button'
import { useValidateValue } from '../../hooks/useValidateValue'
import Alert from '../../components/Alerts/Alert/Alert'
import { CURRENCY } from '../../utils/currency'
import { useMoneyDigits } from '../../hooks/useMoneyDigits'

const ReportsScreen = () => {

  const userInfo = Cookies.get(`userInfo`) ? JSON.parse(Cookies.get(`userInfo`)) : null
  const token = userInfo?.token || null

  const getDate = useGetDate()
  const moneyDigits = useMoneyDigits()
  
  const queryList = [
    {id: 0, value: "jenis_ikan"},
    {id: 1, value: "jumlah_ikan"},
    {id: 2, value: "nama_kapal"},
    {id: 3, value: "nama_tpi"},
    {id: 6, value: "total_pembayaran_pedagang"},
    {id: 7, value: "total_penerimaan_nelayan"},
    {id: 8, value: "total_transaksi"},
    {id: 9, value: "trx_id"},
  ]

  const initialState = {
    reportList : { loading: true, error:'', data: {} },
    report: { loading: false, error: '', data: {} }
  }
  

  const [reportList, setReportList] = useState(initialState.reportList)
  const [query, setQuery] = useState('')
  const [tanggal, setTanggal] = useState('')
  const [search, setSearch] = useState('')
  const [searchType, setSearchType] = useState('text')
  const [errors, setErrors] = useState(null)
  const [reportPopUp, setReportPopUp] = useState(false)
  const [report, setReport] = useState(initialState.report)

  const formatDate = (date) => {
    const newDate = new Date(date);
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1; // Months start at 0!
    let dd = newDate.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedDate = yyyy + '-' + mm + '-' + dd;
  
    return formattedDate
  }

  const fetchReportList = async(page) => {
    
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/report/history`, 
        { 
          headers: { Authorization: token }, 
          params: {
            query: query ? {
              [query.value]: search,
              tanggal_transaksi: tanggal ? formatDate(tanggal) : null,
            } : {
              tanggal_transaksi: tanggal ? formatDate(tanggal) : null,
            },
            limit: 10,
            page: page
          }
        }
      )
      setReportList({loading: false, data: data.data})
    } catch (error) {
      setReportList({loading: false, error: error})
    }
  }


  const pickData = [
    {
      title: 'ID',
      selector: (tr) => tr.trx_id 
    },
    {
      title: 'Tanggal Transaksi',
      selector: (tr) => {
        const trDate = getDate(tr.tanggal_transaksi)
        return `${trDate.date}-${trDate.month}-${trDate.year}`
      },
      className: 'whitespace-nowrap'
    },
    {
      title: 'Nama Kapal',
      selector: (tr) => tr.nama_kapal,
      className: 'whitespace-nowrap'
    },
    {
      title: 'Nama TPI',
      selector: (tr) => tr.nama_tpi,
      className: 'whitespace-nowrap'
    },
    {
      title: 'Jenis Ikan',
      selector: (tr) => tr.jenis_ikan,
      processor: (val) => {
        const valArr = val.split(',')
        return `${valArr[0]}, ...`
      }
    },
    {
      title: 'Jumlah Ikan',
      selector: (tr) => tr.jumlah_ikan
    },
    
  ]

  const inputs = [
    {
      name: 'search',
      value: search, 
      onChange: e => setSearch(e.target.value), 
      label: 'Kata Kunci Pencarian', 
      wrapperClassName: 'col-span-6',
      validation: errors?.search?.message,
      message: {
        required: 'Kata Kunci Pencarian tidak boleh kosong'
      },
      type: searchType,
      required: true
    },  
    {
      label: 'Tipe Data',
      wrapperClassName: 'col-span-2',
      name: 'dataType',
      dropdownData: queryList,
      value: query?.value || '',
      optionOnSelect: (e, data) => setQuery(data),
      validation: errors?.dataType?.message,
      message: {
        required: 'Tipe Data harus dipilih'
      },
      inputVariant: 'select',
      required: true
    },
    {
      name: 'date',
      value: tanggal, 
      onChange: e => setTanggal(e.target.value), 
      label: 'Tanggal', 
      wrapperClassName: 'col-span-2',
      validation: errors?.tanggal?.message,
      type: 'date',
      message: {
        required: 'Tanggal tidak boleh kosong'
      },
      max: formatDate(new Date(Date.now()))
    },
  ]

  const reportPickData = [
    {
      title: 'ID', 
      selector: (tr) => tr.trx_id_d
    },
    {
      title: 'Timbangan', 
      selector: (tr) => tr.no_timbangan
    },
    {
      title: 'Nama Ikan', 
      selector: (tr) => tr.nama_ikan
    },
    {
      title: `Harga Aktual (${CURRENCY})`, 
      selector: (tr) => tr.harga_aktual,
      processor: moneyDigits
    },
    {
      title: 'Berat (kg)', 
      selector: (tr) => tr.berat
    },
    {
      title: `Total Transaksi (${CURRENCY})`, 
      selector: (tr) => tr.total_transaksi,
      processor: moneyDigits
    },
    {
      title: `Fee Pedagang (${CURRENCY})`, 
      selector: (tr) => tr.fee_pedagang_rp,
      processor: moneyDigits
    },
    {
      title: `Fee Nelayan (${CURRENCY})`, 
      selector: (tr) => tr.fee_nelayan_rp,
      processor: moneyDigits
    },
    {
      title: `Total Fee Nelayan (${CURRENCY})`, 
      selector: (tr) => tr.total_fee_nelayan_rp,
      processor: moneyDigits
    },
  ]

  
  const validateValue = useValidateValue()

  const searchHandler = () => {
    if(search) {
      const valueValidation = validateValue(inputs)
      setErrors(valueValidation)
      if(valueValidation) return
    }
    console.log('fetch list')  
    fetchReportList()
  }

  const fetchReport = async(trx_id, callBack) => {
    setReport({...report, loading: true})
    try {
      const response = await axios.post(
        `${API_BASE_URL}/report/history/detail`,
        { "trx_id": trx_id },
        { headers: { Authorization: token } },
      )
      const res = {...report, loading: false, data: response.data.data}
      setReport(res)
      callBack && callBack(res)
    } catch (error) {
      const res = {...report, loading: false, error: error.message}
      setReport(res)
      callBack && callBack(res)
    }
  }

  const showReportPopUp = async(data) => {
    setReportPopUp(true)
    fetchReport(data.trx_id)
  }

  const resetSearchHandler = () => {
    setSearch('')
    setQuery('')
    setTanggal('')
  }

  useEffect(() => {
    if(search || query || tanggal) return
    fetchReportList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, query, tanggal])

  useEffect(() => {
    const queryIsNumber = query.value === 'jumlah_ikan' | 'total_berat' | 'total_pembayaran_pedagang' | 'total_penerimaan_nelayan' | 'total_transaksi' | 'trx_id'
    if(queryIsNumber === 0) return setSearchType('text')
    if(typeof Number(search) !==  'number') return setErrors(errors ? {...errors, search:{ message: 'Pencarian bukan angka'}} : {search: { message: 'Pencarian bukan angka'}})
    setSearch(errors?.search && delete errors?.search )
    setSearch(search ? Number(search) : '')
  }, [errors, query.value, search])

  return (
    <>
      <GlobalLayout contentClassName='overflow-auto'>
        <Card className='w-full' contentClassName='p-5 grid gap-5 '>
          <div className='text-xl font-bold '>Daftar Laporan</div>
          <div className='grid grid-cols-12 gap-5 '>
          {inputs.map((inp, inpIdx) => (
              <React.Fragment key={inpIdx}>
                {inp.inputVariant === 'select' ? (
                  <Select 
                    {...inp}
                  />
                ) : (
                  <TextField 
                    {...inp}
                  />
                )}
              </React.Fragment>
            ))}
            <div className='flex pt-5'>
              <Button color={'red'} className='w-full h-11' onClick={searchHandler}>Cari</Button>
              </div>
            <div className='flex pt-5'>
              <Button color={'red'} variant={'outline'} className='w-full h-11' onClick={resetSearchHandler}>Reset</Button>
            </div>
          </div>
          <div className='w-full overflow-x-auto'>
            <Table
              className=''
              data={reportList.data?.Records}
              pickData={pickData}
              actions={(rowData, idx) => (
                <button onClick={() => showReportPopUp(rowData)}>
                  <IconContainer className='act-btn' >
                    <IoEyeOutline />
                  </IconContainer>
                </button>
              )}
              pagination
              page={reportList.data?.Page}
              pageTotal={reportList.data?.TotalPage}
              maxDataPreview={reportList.data?.Limit}
              paginationOnClick={(pageVal) => fetchReportList(pageVal) }
            />
          </div>
        </Card>
      </GlobalLayout>
      {reportPopUp && (
        <Alert
          size={'6xl'}
          header={"Report Transaksi"}
          headerClose={true}
          closeFunction={() => {
            setReport(initialState.report)
            setReportPopUp(false)
          }}
          className={'h-5/6 overflow-hidden relative'}
        >
          <div className='h-full p-5 pb-20 overflow-y-auto '>
            {report.loading ? (
              'Mengambil data...'
            ) : (
              <>
                <div className='flex flex-col w-full p-5'>
                  <div className='mb-3 text-lg font-semibold'>
                    Daftar Transaksi
                  </div>
                  <div className='w-full overflow-x-auto'>
                    <Table
                      data={report.data.transaksi}
                      pickData={reportPickData}
                    />
                  </div>
                </div>
                <div className='flex flex-col w-full p-5 space-y-2'>
                  {report.data?.retribusi && report.data?.retribusi.map((rtb, rtbIdx) => (
                    <div key={rtbIdx} className='flex justify-between w-full'>
                      <div>{rtb.nama_fee} ({rtb.nominal_fee}%)</div>
                      <div className='font-semibold text-right'>{CURRENCY}{moneyDigits(rtb.nominal_fee_rp)}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </Alert>
      )}
    </>
  )
}

export default ReportsScreen