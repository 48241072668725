import React, { useEffect, useRef, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import './Input.css'

const Input = ({
  inputRef,
  wrapperClassName = '',
  containerClassName = '',
  label,
  labelName,
  id,
  className = '',
  name,
  type,
  value,
  placeholder,
  onFocus,
  onBlur,
  onClick,
  onChange,
  onLoad,
  autoComplete,
  style,
  labelClassName = '',
  adornment,
  adornmentClassName = '',
  adornmentPosition,
  adornmentRotate,
  dropdown,
  dropdownData = [{id: 0, value: 'No Data'}],
  optionOnSelect,
  disabled,
  validation,
  min,
  max,
  defaultValue,
  display = false,
  loading
}) => {

  const [validationMessage, setValidationMessage] = useState(null)

  const ref = useRef(null)

  const adornmentPositions = ['left', 'right']

  const CHECK_ADORNMENT_POSITION = adornment ? adornmentPositions.find(x => x === adornmentPosition) && adornmentPosition : adornmentPositions[0]
  const CHECK_ADORNMENT_FOR_INPUT_FIELD = adornment ? `input__field--${CHECK_ADORNMENT_POSITION}-adornment` : ''
  const CHECK_INPUT_FIELD_VALIDATION = validationMessage ? 'input__field--error' : ''
  const ADORNMENT_IS_LEFT = CHECK_ADORNMENT_POSITION === 'left'

  const onChangeHandler = (e) => {
    onChange && onChange(e)
  }

  const optionOnSelectHandler = (e, data, idx) => {
    optionOnSelect && optionOnSelect(e, data, idx)
    if (validationMessage) setValidationMessage(null)
  }
  
  useEffect(() => {
    setValidationMessage(validation)
  }, [validation])

  useEffect(() => {
    if (!inputRef || display) return
    inputRef(ref)
  }, [display, inputRef])

  useEffect(() => {
    if(!disabled || display) return
    ref.current.blur()
  }, [disabled, display])

  return (
    <div className={wrapperClassName}>
      <div className={`input__container ${containerClassName}`}>
        {label ? (
          <label 
            className={`input__label ${labelClassName}`} 
            name={labelName || name || ''}
          >
            {label}
          </label>
        ) : ''}
        <div className='input__items'>
          {display ? (
            <div className={`flex items-center h-11 text-sm w-full ${className}`}>{ADORNMENT_IS_LEFT && <span>{adornment}</span>} <span>{value}</span> {!ADORNMENT_IS_LEFT && <span>{adornment}</span>}</div>
          ) : (
            <React.Fragment>
              <input 
                ref={ref}
                id={id}
                className={`input__field ${CHECK_ADORNMENT_FOR_INPUT_FIELD} ${CHECK_INPUT_FIELD_VALIDATION} ${dropdown ? `input__field--active` : ''} ${className}`}
                type={type || 'text'} 
                name={name}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                onChange={onChangeHandler}
                onLoad={onLoad}
                placeholder={placeholder}
                autoComplete={autoComplete}
                style={style}
                min={min}
                max={max}
                defaultValue={defaultValue}
              />
              {adornment ? (
                <div className={`input__adornment input__adornment--${CHECK_ADORNMENT_POSITION} ${dropdown ? adornmentRotate ? 'input__adornment--rotate' : '' : ''} ${adornmentClassName}`} >
                  {adornment}
                </div>
              ) : ''}
              {dropdown ? (
                <ul className={`input__dropdown`}>
                  {loading ? (
                    <div className='flex items-center justify-center input__dropdown__option'><FaSpinner className='text-xl animate-spin'/></div>
                  ) : dropdownData.length > 0 ? (
                    dropdownData?.map((data, idx) => (
                      <li key={idx} className={`input__dropdown__option`} onClick={(e) => optionOnSelectHandler(e, data, idx)}>{data.label || data.value}</li>
                    ))
                  ) : (
                    <li className={`input__dropdown__option`}>Tidak Ada Data</li>
                  )}
                </ul>
              ) : ''}
              {disabled && !display ? (
                <div className='absolute inset-0 z-20 bg-gray-300 rounded-md opacity-50'></div>
              ) : ''}
            </React.Fragment>
          )}
        </div>
        {validationMessage ? (
          <div className={`text-xs text-red-600`}>{validationMessage}</div>
        ) : ''}
      </div>
    </div>
  )
}

export default Input