import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Alert from '../../components/Alerts/Alert/Alert'
import MessageBox from '../../components/Boxes/MessageBox/MessageBox'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import IconContainer from '../../components/IconContainer/IconContainer'
import Table from '../../components/Table/Table'
import TextField from '../../components/TextField/TextField'
import { useGetDate } from '../../hooks/useGetDate'
import { useMoneyDigits } from '../../hooks/useMoneyDigits'
import { useUrlParams } from '../../hooks/useUrlParams'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import { CURRENCY } from '../../utils/currency'
import { API_BASE_URL } from '../../utils/urls'


const TransactionScreen = () => {

  const initialState = {
    search: {keyword: '', type: '', startDate: '', endDate: '', errors: ''},
    notif: {show: false, header: '', message: '', color: ''}
  }

  const state = useSelector(state => state)
  const userLogin = state.user.userLogin.userInfo
  const token = userLogin.token

  const params = useParams()
  const moneyDigits = useMoneyDigits()
  const getDate = useGetDate()
  const navigate = useNavigate()
  const urlParams = useUrlParams()
  const location = useLocation()

  const [transaction, setTransaction] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)  
  const [detailPopUp, setDetailPopUp] = useState(null)
  const [search, setSearch] = useState(initialState.search)
  
  const dateProcessor = (val) => {
    const gotDate = getDate(val)
    const date = gotDate.date
    const month = gotDate.month
    const year = gotDate.year
    return `${date}-${month}-${year}`
  }

  const paginationOnClickHandler = (pageVal) => {
    navigate({pathname:'/transactions', search:`?nama_kapal=${params.namaKapal}&page=${pageVal}`})
  }

  const responseHandler = (res, err) => {
    setTransaction(res)
    setError(err)
  }

  const fetchTransactionHistory = async(callBack) => {
    if(!params.namaKapal) return
    const page = urlParams.get('page')
    setLoading(true)
    let res, err
    try {
      const {data} = await axios.get(
        `${API_BASE_URL}/transaction-history`, 
        {
          headers: {
            Authorization: token, 
          },
          params: {
            "nama_kapal": params.namaKapal,
            page: page || 1,
            id_antrian_lelang: search.keyword,
            start_date: search.startDate,
            end_date: search.endDate,
          }
        }
      )
      res = data.data
    } catch (error) {
      err = error
    }
    callBack && callBack(res, err)
    setLoading(false)
  }

  const closePopUp = () => {
    setDetailPopUp(null)
  }

  const searchOnChangeHandler = (e) => {
    setSearch({...search, [e.target.name]: e.target.value})
  }

  const searchOnClickHandler = () => {
    fetchTransactionHistory(responseHandler)
  }

  const resetOnClickHandler = () => {
    fetchTransactionHistory(responseHandler)
    setSearch(initialState.search)
  }

  useEffect(() => {
    fetchTransactionHistory(responseHandler)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, location])

  const pickData = [
    {
      title: 'Nama Kapal',
      selector: (tr) => tr.lelang.nama_kapal
    },
    {
      title: 'ID Antrian',
      selector: (tr) => tr.lelang.id_antrian_lelang
    },  
    {
      title: 'Total Transaksi',
      selector: (tr) => tr.lelang['total_transaksi'],
      processor: (val) => `${CURRENCY} ${ moneyDigits(val)}`
    },
    {
      title: 'Tanggal Transaksi',
      selector: (tr) => tr.lelang.tanggal,  
      processor: dateProcessor
    },
  ]

  const detailPopUpPickData = [
    {
      title: 'Nama Ikan',
      selector: (tr) => tr['nama_ikan'],
    },
    {
      title: 'Harga Aktual (Rp)',
      selector: (tr) => tr['harga_aktual'],
      processor: (value) => moneyDigits(value)
    },
    {
      title: 'Berat (kg)',
      selector: (tr) => tr['berat'],
    },
    {
      title: 'Total Transaksi (Rp)',
      selector: (tr) => tr['total_transaksi'],
      processor: (value) => moneyDigits(value)
    },
    {
      title: 'Pemenang',
      selector: (tr) => tr['no_user_pari'],
    }
  ]

  return (
    <GlobalLayout breadcrumbs={transaction} loading={loading}>
      {error ? (
        <MessageBox>Tidak ada data untuk kapal {params.namaKapal}</MessageBox>
      ) : (
        <>
          <Card contentClassName='p-5  space-y-5'>
            <div className='flex items-center justify-between w-full mb-5'>
              <div className='text-xl font-bold'>Transaksi Kapal {params.namaKapal}</div>
            </div>
            <div className="grid items-end grid-cols-12 gap-3 col-span-full">
              <TextField 
                name={'keyword'}
                wrapperClassName='col-span-6' 
                label={'Cari ID Antrian'} 
                value={search.keyword} 
                onChange={searchOnChangeHandler}
                type={'number'}
              />
              <TextField 
                name={'startDate'}
                wrapperClassName='col-span-2' 
                label={'Tanggal Mulai'} 
                value={search.startDate} 
                onChange={searchOnChangeHandler}
                type={'date'}
              />
              <TextField 
                name={'endDate'}
                wrapperClassName='col-span-2' 
                label={'Tanggal Berakhir'} 
                value={search.endDate} 
                onChange={searchOnChangeHandler}
                type={'date'}
              />
              
              <div className={`grid grid-cols-2 col-span-2 gap-3`}>
                <Button size={'lg'} color={'red'} onClick={searchOnClickHandler}>Cari</Button>
                <Button size={'lg'} color={'red'} variant={'outline'} onClick={resetOnClickHandler}>Reset</Button>
              </div>
            </div>
            <Table
              containerClassName='col-span-full w-full'
              pagination={true}
              page={transaction?.Page}
              pageTotal={transaction?.TotalPage}
              maxDataPreview={transaction?.Limit}
              paginationOnClick={paginationOnClickHandler}
              pickData={pickData}
              data={transaction?.Records || []}
              actions={(rowData, idx) => (
                <button onClick={() => setDetailPopUp(rowData)}>
                  <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md cursor-pointer hover:text-etpi-blue hover:border-etpi-blue' >
                    <IoEyeOutline />
                  </IconContainer>
                </button>
              )}
            />
          </Card> 
          {detailPopUp && (
            <Alert size={'7xl'} className={`max-h-[80vh] h-full flex flex-col`} header={<div className='text-xl font-bold'>Detail Transaksi</div>} headerClose closeFunction={closePopUp}>
              <div className="flex w-full h-full">
                <div className='relative flex w-full h-full'>
                  <div className='absolute inset-0 overflow-y-auto divide-y-8'>
                    <div className='flex flex-col w-full p-5'>
                      <div className='mb-3 text-lg font-semibold'>
                        Daftar Transaksi
                      </div>
                      <Table
                        data={detailPopUp.transaksi}
                        pickData={detailPopUpPickData}
                      />
                    </div>
                    <div className='flex flex-col w-full p-5 space-y-2'>
                      <div className='flex justify-between w-full'>
                        <div>Total Transaksi</div>
                        <div className='font-semibold text-right'>{CURRENCY}{moneyDigits(detailPopUp.lelang.total_transaksi)}</div>
                      </div>
                      <div className='flex justify-between w-full'>
                        <div>Fee Nelayan ({detailPopUp.lelang.fee_nelayan}%)</div>
                        <div className='font-semibold text-right'>-{CURRENCY}{moneyDigits(detailPopUp.lelang.fee_nelayan_rp)}</div>
                      </div>
                      {detailPopUp.retribusi && detailPopUp.retribusi.map((rtb, rtbIdx) => (
                        <div key={rtbIdx} className='flex justify-between w-full'>
                          <div>{rtb.nama_fee} ({rtb.nominal_fee}%)</div>
                          <div className='font-semibold text-right'>-{CURRENCY}{moneyDigits(rtb.nominal_fee_rp)}</div>
                        </div>
                      ))}
                      <div className='flex justify-between w-full text-xl font-bold'>
                        <div>Total Penerimaan</div>
                        <div className='text-right '>{CURRENCY}{moneyDigits(detailPopUp.lelang.total_penerimaan)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Alert>
          )}
        </>
      )}
    </GlobalLayout>
  )
}

export default TransactionScreen