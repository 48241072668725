import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { IoCheckmark, IoClose } from 'react-icons/io5'
import { RiShoppingBasketFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../../components/Alerts/Alert/Alert'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import IconContainer from '../../components/IconContainer/IconContainer'
import { useGetDate } from '../../hooks/useGetDate'
import { useSocket } from '../../hooks/useSocket'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import { logout } from '../../redux/slices/userSlice/userSlice'
import { API_BASE_URL } from '../../utils/urls'
import PlaceDateCard from '../MainScreen/components/PlaceDateCard/PlaceDateCard'
import { useFetchListAntrian } from '../MainScreen/hooks/useFetchListAntrian'
import { useFetchListJenisIkan } from '../MainScreen/hooks/useFetchListJenisIkan'
import BasketAccordion from './components/BasketAccordion/BasketAccordion'
import QueueList from './components/QueueList/QueueList'

const UserInfoIsTrue = ({userInfo}) => {
  const getDate = useGetDate()
  const { date, month, year } = getDate('') 
  const tanggal = `${year}-${month}-${date}`
  const tanggal2 = `${year}${month}${date}`  
  
  const LIST_IKAN_BARU = `listIkanBaru${tanggal2}`

  const initialState = {
    listIkanBaru: localStorage.getItem(LIST_IKAN_BARU) ? JSON.parse(localStorage.getItem(LIST_IKAN_BARU)) : [],
    listIkanTersimpan: localStorage.getItem('listIkanTersimpan') ? JSON.parse(localStorage.getItem('listIkanTersimpan')) : []
  }

  const { socket, event } = useSocket() 

  const dispatch = useDispatch()
  const token = userInfo?.token
  const idTpi = userInfo?.user?.id_tpi
  

  const [loading, setLoading] = useState(false)

  const [socketLogin, setSocketLogin] = useState(false)
  
  const [listAntrian, setListAntrian] = useState([])
  const [antrian, setAntrian] = useState(null)
  const [listIkanBaruAntrian, setListIkanBaruAntrian] = useState([])

  const [listJenisIkan, setListJenisIkan] = useState([])
  
  const [listIkanBaru, setListIkanBaru] = useState(initialState.listIkanBaru)
  const [listIkanBaruError, setListIkanBaruError] = useState(null)
  const [listIkanBaruActive, setListIkanBaruActive] = useState([])

  const [listIkanTersimpan, setListIkanTersimpan] = useState([])
  const [listIkanTersimpanError, setListIkanTersimpanError] = useState(null)
  const [listIkanTersimpanActive, setListIkanTersimpanActive] = useState([])
  const [unchangedLIT, setUnchangedLIT] = useState(false)


  const [listJenisKeranjang, setListJenisKeranjang] = useState([])

  const [dangerAlert, setDangerAlert] = useState(null)
  const [successAlert, setSuccesAlert] = useState(null)

  const logoutHandler = () => {
    dispatch(logout())
  }

  const deleteIkanActive = (arr, id) => {
    const existIdIdx = arr.indexOf(id)
    arr.splice(existIdIdx, 1)
    return arr
  }

  const listItemOnClick = (activeList, item) => {
    const id = item.id || item.ikan?.id_jenis_ikan 
    const arr = [...activeList.state]
    const existId = arr.find(x => x === id)
    if(!existId) arr.push(id)
    if(existId) {
      deleteIkanActive(arr, existId)
    }
    activeList.set(arr)
  }

  const listAntrianOnClick = (dt) => {
    localStorage.removeItem('listIkanTersimpan')
    setAntrian(null)
    setListIkanTersimpan(null)
    setAntrian(dt)
  }

  const listIkanTersimpanHandler = (arr) => {
    setListIkanTersimpan(arr)
    localStorage.setItem('listIkanTersimpan', JSON.stringify(arr))
  }

  const deleteIkanBaru = (newFish) => {
    setLoading(true)
    const listIkanBaruArr = [...listIkanBaru]
    const fishIdx = listIkanBaruArr.findIndex(x => x.id === newFish.id)
    listIkanBaruArr.splice(fishIdx, 1)
    const listIkanBaruActiveArr = [...listIkanBaruActive]
    const fishToDeleteIdx = listIkanBaruActiveArr.findIndex(x => x === newFish.id)
    if(fishToDeleteIdx) listIkanBaruActiveArr.splice(fishToDeleteIdx, 1)
    setListIkanBaruActive(listIkanBaruActive)
    setListIkanBaru(listIkanBaruArr)
    setTimeout(() => {
      setLoading(false)
    }, [300])
  }

  const keranjangHandler = (oldList, newList, data, idx) => {
    const id = data.id || data.ikan?.id_jenis_ikan 
    let libArr = [...oldList.state]
    let findFish = libArr.find(fish => (fish.id || fish.ikan?.id_jenis_ikan)  === id)
    findFish.keranjang = newList
    oldList.set(libArr)
  }

  const checkKeranjang = (keranjang) => {
    for (let i = 0; i < keranjang.length; i++) {
      const krj = keranjang[i];
      if(!krj?.id_keranjang || !krj.qty || krj.qty === 0) return true
    }
    return false
  }

  const keranjangValidation = (keranjang) => {
    let message = null
    if(keranjang.length === 0) message = 'Harus memiliki setidaknya 1 keranjang'
    const checkedKeranjang = checkKeranjang(keranjang)
    if(checkedKeranjang) message = 'Keranjang Harus Dilengkapi'
    return message
  }

  const ikanValidation = (listIkan) => {
    let errorObj = {}
    for (let i = 0; i < listIkan.length; i++) {
      const jenisIkan = listIkan[i]
      const validatedKeranjang = keranjangValidation(jenisIkan.keranjang) 
      if(validatedKeranjang) errorObj[jenisIkan.id || jenisIkan?.ikan?.id_jenis_ikan ] = validatedKeranjang 
    }
    return Object.entries(errorObj).length > 0 ? errorObj : null
  }

  const processFish = (arrA, arrB, push = true) => {
    arrB.forEach(fish => {
      const exsitFish = arrA.find(x => x.ikan.id_jenis_ikan === fish.ikan.id_jenis_ikan)
      if(!exsitFish && push) arrA.push(fish)
      if(exsitFish) {
        exsitFish.ikan.berat += (fish.ikan.beratKotor || fish.ikan.berat)
        const EFB = exsitFish.keranjang
        const FB = fish.keranjang
        FB.forEach(basket => {
          const existBasket = EFB.find(x => x.id_keranjang === basket.id_keranjang)
          if(!existBasket) EFB.push(basket)
          if(existBasket) existBasket.qty += basket.qty
        })
      }
    })
  }

  const sendFishes = async(processedFishArr, newFishArr) => {
    let successFishes = [], failedFishes = []
    const fishIdToDelete = []
    for (let i = 0; i < processedFishArr.length; i++) {
      const fish = processedFishArr[i]    
      const fishName = listJenisIkan ? findFish(fish)?.value : fish.ikan.id_jenis_ikan
      try {
        const { data } = await axios.post(
          `${API_BASE_URL}/antrian/timbangan`,
          fish,
          {
            headers: {
              Authorization: token
            },
          }
        )
        if(!data) return     
        fishIdToDelete.push(fish.ikan.id_jenis_ikan)
        successFishes.push(fishName)
      } catch (error) {
        setDangerAlert(error.response.data)
        if(error.response.status === 400) fetchListAntrian()
        failedFishes.push(fishName)
      }
    }

    if(successFishes.length > 0) {
      setSuccesAlert({
        title:`Berhasil Menyimpan Ikan`,
        message:`Ikan ${successFishes.join(', ')} berhasil disimpan` ,
        variant:'success',
      })
    }

    if(failedFishes.length > 0) {
      setDangerAlert({ 
        title: `Gagal Menyimpan Ikan Baru`,
        message: `Ikan ${failedFishes.join(', ')} gagal disimpan`,
        variant: 'danger',
      })  
    }
    
    fetchListIkanTersimpan()

    if(fishIdToDelete.length === 0) return
    const newFishArrToSet = []
    newFishArr.forEach((fish, idx) => {
      const ftd = fishIdToDelete.find(id => id !== fish.ikan.id_jenis_ikan && antrian.ID !== fish.ikan.id_antrian_lelang) 
      if(ftd) newFishArrToSet.push(fish)
    })
    
    setListIkanBaru(newFishArrToSet)
  }

  const submit = async() => {    
    const LIB = JSON.parse(localStorage.getItem(LIST_IKAN_BARU))
    const LIT = JSON.parse(localStorage.getItem('listIkanTersimpan'))
    const LIBA = []
    LIB.forEach(lib => {
      if(lib.ikan.id_antrian_lelang === antrian.ID) LIBA.push(lib)
    })
    const ikanError = ikanValidation(LIBA)
    setListIkanBaruError(ikanError)
    if(ikanError) return
    setListIkanBaruActive([])
    setLoading(true)
    let LIBATS = []
    processFish(LIBATS, LIBA)
    processFish(LIBATS, LIT, false)    
    await sendFishes(LIBATS, LIB)
    setLoading(false)
  }

  const submitOnClick = () => {
    if(!unchangedLIT) return setDangerAlert({title: 'Tidak Bisa Menyimpan Ikan Baru', message: 'Perubahan pada Ikan Tersimpan belum disimpan'})
    submit()
  }

  const findFish = (fish) =>  listJenisIkan.find(lst => lst.id === fish.ikan.id_jenis_ikan)


  const sendChangedFish = async(oldFishArr, newFishArr) => {
    let successFishes = []
    let failedFishes = []
    for (let i = 0; i < newFishArr.length; i++) {
      const oldFish = oldFishArr[i]
      const newFish = newFishArr[i] 
      if (JSON.stringify(oldFish) !== JSON.stringify(newFish)) {
        newFish.ikan.berat = newFish.ikan.beratKotor   
        delete newFish.ikan.beratKotor      
        const fishName = listJenisIkan ? findFish(newFish)?.value : newFish.ikan.id_jenis_ikan
        try {
          await axios.post(
            `${API_BASE_URL}/antrian/timbangan`,
            newFish,
            {
              headers: {
                Authorization: token
              },
            }
          ) 
          successFishes.push(fishName)
          localStorage.setItem('listIkanTersimpan', JSON.stringify(listIkanTersimpan))
        } catch (error) {    
          failedFishes.push(fishName)
        }
      }
    }

    if(successFishes.length > 0) {
      setSuccesAlert({
        title:`Berhasil Menyimpan Perubahan`,
        message:`Ikan ${successFishes.join(', ')} berhasil disimpan` ,
        variant:'success',
      })
    }

    if(failedFishes.length > 0) {
      setDangerAlert({ 
        title: `Gagal Menyimpan Perubahan`,
        message: `Ikan ${failedFishes.join(', ')} gagal disimpan`,
        variant: 'danger',
      })          
    }

    fetchListIkanTersimpan()       
  }

  const submitChange = async() => {    
    const LIT = JSON.parse(localStorage.getItem('listIkanTersimpan'))
    const ikanError = ikanValidation(listIkanTersimpan)
    setListIkanTersimpanError(ikanError)
    if(ikanError) return
    setLoading(true)
    await sendChangedFish(LIT, listIkanTersimpan)
    setLoading(false)
  }

  const fetchListAntrian = useFetchListAntrian(
    token,
    tanggal,
    setListAntrian,
    () => {}
  )

  useFetchListJenisIkan(
    token,
    setListJenisIkan
  )

  const fetchListJenisKeranjang = async() => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/tpi/${userInfo.user.id_tpi}/keranjang`,
        {
          headers: {
            Authorization: token
          },
          params: {
            limit:100,
            order: 'IdMasterKeranjang ASC'
          }
        }
      )
      setListJenisKeranjang(data.data.Records)
    } catch (error) {
      if(error.message.includes('401')) return logoutHandler()
    }
  }

  const fetchListIkanTersimpan = async() => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/antrian/timbangan`,
        {
          headers: {
            Authorization: token
          },
          params: {
            query: `{"IdAntrianLelang":${antrian.ID}}`,
            limit:100
          }
        }
      )
      const records = data.data.Records
      if(!records) return listIkanTersimpanHandler([])
      const processedRecords = []
      records.forEach(record => {
        const keranjang = []
        record.Keranjang?.forEach((krj) => {
          keranjang.push({
            "id_keranjang": krj.id_master_keranjang,
            "qty": krj.qty
          })
        })
        processedRecords.push({
          "ikan": {
            "berat": record.BeratIkan,
            "beratKotor": record.BeratKotor,
            "id_antrian_lelang": record.IdAntrianLelang,
            "id_jenis_ikan": record.IdJenisIkan
          },
          "keranjang": keranjang
        })
      })
      listIkanTersimpanHandler(processedRecords)
    } catch (error) {
      if(error.message.includes('401')) return logoutHandler()
    }
  }
  
  useEffect(() => {
    fetchListAntrian()
    fetchListJenisKeranjang()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!antrian) return
    const listIkanBaruArr = [...listIkanBaru]
    const processedListIkanBaruArr = listIkanBaruArr?.filter((a) => a.ikan.id_antrian_lelang === antrian.ID)
    setListIkanBaruAntrian(processedListIkanBaruArr.reverse())
  }, [antrian, listIkanBaru])

  useEffect(() => {
    if(!socket) return
    setTimeout(() => {
      event.login.emit(token)
    }, 2000)
    event.login.listen((data) => {
      if(data.status_code !== 200) return
      setSocketLogin(true)
    })    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]) 

  useEffect(() => {
    if(!socket) return
    event.timbangan.listen((data) => {
      if(!data || !antrian) return 
      setLoading(true)
      const newListIkanBaru = [
        ...listIkanBaru, 
        {
          id: (listIkanBaru.length > 0 ? listIkanBaru[listIkanBaru.length - 1].id : listIkanBaru.length) + 1,
          "ikan": {
            "berat": Number(data.berat),
            "id_antrian_lelang": antrian.ID,
            "id_jenis_ikan": data.id_jenis_ikan,
          },
          "keranjang": []
        }
      ]  
      setListIkanBaru(newListIkanBaru) 
      setTimeout(() => {
        setLoading(false)
      }, 300)
    })    
  }, [antrian, event.timbangan, listIkanBaru, socket])
 


  useEffect(() => {
    localStorage.setItem(LIST_IKAN_BARU, JSON.stringify(listIkanBaru))
    setListIkanBaruError(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listIkanBaru])

  useEffect(() => {
    if(!antrian) return
    fetchListIkanTersimpan()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [antrian])
  
  useEffect(() => {
    setUnchangedLIT(JSON.stringify(listIkanTersimpan) === localStorage.getItem('listIkanTersimpan'))
  }, [listIkanTersimpan])
  
  useEffect(() => {
    if(!antrian) return
    const findAntrian = listAntrian.find(x => x.ID === antrian?.ID)
    if(findAntrian) return
    const newListIkanBaru = []
    listIkanBaru.forEach(ikan => {
      if(ikan.ikan.id_antrian_lelang !== antrian.ID) newListIkanBaru.push(ikan)
    })
    setListIkanBaru(newListIkanBaru)
    setAntrian(null)
    localStorage.removeItem('listIkanTersimpan')
  }, [antrian, listAntrian, listIkanBaru])


  // BARCODE SCANNER HANDLER
  useEffect(() => {
    const addAndListenBarcodeScanner = (e) => {
      
      // add scan property to window if it does not exist
      if(!window.hasOwnProperty('scan')) {
        window.scan = []
      }
      
      // if key stroke appears after 10 ms, empty scan array
      if(window.scan.length > 0 && (e.timeStamp - window.scan.slice(-1)[0].timeStamp) > 3 * 1000) {
        window.scan = []
      }
      
      // if key store is enter and scan array contains keystrokes
      // dispatch `scanComplete` with keystrokes in detail property
      // empty scan array after dispatching event
      if(e.key === "Enter" && window.scan.length > 0) {
        let scannedString = window.scan.reduce(function(scannedString, entry) {
          return scannedString + entry.key
        }, "")
        window.scan = []
        return document.dispatchEvent(new CustomEvent('scanComplete', {detail: scannedString}))
      }
      
      // do not listen to shift event, since key for next keystroke already contains a capital letter
      // or to be specific the letter that appears when that key is pressed with shift key
      if(e.key !== "Shift") {
        // push `key`, `timeStamp` and calculated `timeStampDiff` to scan array
        let data = JSON.parse(JSON.stringify(e, ['key', 'timeStamp']))
        data.timeStampDiff = window.scan.length > 0 ? data.timeStamp - window.scan.slice(-1)[0].timeStamp : 0;
        window.scan.push(data)
      }
    }
        
    document.addEventListener('keydown', addAndListenBarcodeScanner)

    return () => {
      document.removeEventListener('keydown', addAndListenBarcodeScanner)
    }
  }, []) 

  const sendAntrianKapal = async(idNelayan) => {
    const config = { 
      "idNelayan": Number(idNelayan),
      "idTpi": Number(idTpi) ,
      "tangalAntrian": tanggal
    }
    try {
      await axios.post(
        `${API_BASE_URL}/dash/antrian-kapal`, 
        config,
        {
          headers: {
            Authorization: token
          }
        }
      )
      fetchListAntrian()
    } catch (error) {
      let message, title = ''
      if(error.response.data.errors.includes('duplicate')) message = 'Kapal sudah masuk ke daftar antrian'
      setDangerAlert({
        title,
        message
      })

    }
  }

  useEffect(() => {
    
    document.addEventListener('scanComplete', function(e) { 
      sendAntrianKapal(e.detail) 
    })
    return () => {
      document.removeEventListener('scanComplete', function(e) { 
        sendAntrianKapal(e.detail) 
      })    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <GlobalLayout contentClassName='grid grid-cols-9 gap-5' loading={loading}>
        <div className="flex flex-col col-span-2 space-y-5">
          <PlaceDateCard placeName={userInfo?.user?.nama_tpi}/>
          <QueueList 
            list={listAntrian} 
            activeListItem={(sp) => sp?.ID === antrian?.ID} 
            listItemOnClick={(sp) => listAntrianOnClick(sp)}
            refreshOnClick={() => fetchListAntrian()}
          />
        </div>
        <Card className='h-full col-span-7 ' contentClassName='p-5 flex flex-col h-full space-y-5'>
          <div className="text-2xl font-semibold">
            Daftar Timbangan Ikan {antrian?.nama_kapal}
          </div>
          {!antrian ? (
            <div className="flex flex-col items-center justify-center w-full h-full space-y-5 text-2xl font-semibold">
              <IconContainer className='-mt-20 text-4xl rounded-full text-slate-500 w-28 h-28 bg-slate-300'>
                <RiShoppingBasketFill/>
              </IconContainer>
              <div className='text-slate-500'>Silahkan Memilih Kapal Terlebih Dahulu</div>
            </div>
          ) : (
            <div className="grid h-full grid-cols-2 gap-5">            
              <Card className='flex flex-col flex-1 p-5 border' contentClassName='h-full flex flex-col'>
                <div className='flex flex-col flex-1 space-y-5'>
                  <div className="text-xl font-semibold">
                    Ikan Baru
                  </div>                  
                  <div className="relative flex flex-1 w-full overflow-auto">
                    <div className='absolute inset-0 flex flex-col w-full h-full space-y-2 overflow-auto'>
                      {listIkanBaruAntrian.map((lst, lstIdx) => {                        
                        return (
                          <BasketAccordion 
                            key={lstIdx} 
                            no={listIkanBaruAntrian.length - lstIdx}
                            active={listIkanBaruActive.find(x => x === lst.id) ? true : false}
                            namaIkan={listJenisIkan ? listJenisIkan.find(fish => fish.id === lst.ikan.id_jenis_ikan)?.value : lst.ikan.id_jenis_ikan }
                            beratIkan={lst.ikan.berat}
                            listJenisKeranjang={listJenisKeranjang}
                            listKeranjang={lst.keranjang}
                            listKeranjangOnChange={(newList) => keranjangHandler({state: listIkanBaru, set: setListIkanBaru}, newList, lst, lstIdx)}
                            validation={listIkanBaruError ? listIkanBaruError[lst.id] : ''}
                            deleteIkan={() => deleteIkanBaru(lst)}
                            headerOnClick={() => listItemOnClick({state: listIkanBaruActive, set: setListIkanBaruActive},lst)}
                          />
                        )
                      })}
                    </div>
                  </div>
                  <div className='flex justify-end w-full pt-5 border-t'>
                    <Button color={'orange'} onClick={submitOnClick} disabled={listIkanBaruAntrian.length === 0 || loading}>
                      Simpan Ikan
                    </Button>
                  </div>
                </div>
              </Card>
              <Card className='flex flex-col flex-1 p-5 border' contentClassName='h-full flex flex-col'>
                <div className='flex flex-col flex-1 space-y-5'>
                  <div className="text-xl font-semibold">
                    Ikan Tersimpan
                  </div>                  
                  <div className="relative flex flex-1 w-full overflow-auto">
                    <div className='absolute inset-0 flex flex-col w-full h-full space-y-2 overflow-auto'>
                      {listIkanTersimpan?.map((lst, lstIdx) => (
                        // console.log(lst)
                        <BasketAccordion 
                          key={lstIdx} 
                          no={lstIdx + 1}
                          active={listIkanTersimpanActive.find(x => x === lst.ikan.id_jenis_ikan) ? true : false}
                          namaIkan={listJenisIkan ? listJenisIkan.find(fish => fish.id === lst.ikan.id_jenis_ikan)?.value : lst.ikan.id_jenis_ikan }
                          beratIkan={lst.ikan.berat}
                          listJenisKeranjang={listJenisKeranjang}
                          listKeranjang={lst.keranjang}
                          validation={listIkanTersimpanError ? listIkanTersimpanError[lst.ikan.id_jenis_ikan] : ''}
                          deleteable={false}
                          listKeranjangOnChange={(newList) => keranjangHandler({state: listIkanTersimpan, set: setListIkanTersimpan}, newList, lst, lstIdx)}
                          headerOnClick={() => listItemOnClick({state: listIkanTersimpanActive, set: setListIkanTersimpanActive},lst)}
                        />
                      ))}
                    </div>
                  </div>
                  <div className='flex justify-end w-full pt-5 border-t'>
                    <Button color={'orange'} onClick={submitChange} disabled={unchangedLIT || loading}>
                      Simpan Perubahan
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Card>
      </GlobalLayout>
      {!socketLogin && (
        <div className='fixed inset-0 z-50 grid text-white bg-etpi-blue place-content-center'>
          <div className="flex flex-col items-center space-y-2 text-center">
            <FaSpinner className='text-4xl animate-spin'/>
            <div className='text-2xl font-bold '>Menghubungkan Socket</div>
            <div>Mohon tunggu sebentar</div>
          </div>
        </div>
      )}
      {dangerAlert && (
        <Alert size={'lg'}>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className={`w-16 h-16 mb-5 text-4xl text-white rounded-full bg-red-600`}>
              <IoClose/>
            </IconContainer>
            <div className="mb-2 text-2xl font-bold">
              {dangerAlert?.title || 'Gagal'}
            </div>
            <p>{dangerAlert?.message}</p>
            <div className='grid w-full gap-2 mt-6'>
              <Button className='w-full' color={'red '} variant={'outline'} onClick={() => setDangerAlert(null)}>
                OK
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {successAlert && (
        <Alert size={'lg'}>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className={`w-16 h-16 mb-5 text-4xl text-white rounded-full bg-green-600`}>
              <IoCheckmark/>
            </IconContainer>
            <div className="mb-2 text-2xl font-bold">
              {successAlert?.title || 'Berhasil'}
            </div>
            <p>{successAlert?.message}</p>
            <div className='grid w-full gap-2 mt-6'>
              <Button className='w-full' variant={'outline'} onClick={() => setSuccesAlert(null)}>
                OK
              </Button>
            </div>
          </div>
        </Alert>
      )}
    </>
  )
}

const BasketScreen = () => {

  const { user } = useSelector(state => state)
  const userInfo = user?.userLogin?.userInfo

  const [component, setComponent] = useState(<></>)

  useEffect(() => {
    if(userInfo) setComponent(<UserInfoIsTrue userInfo={userInfo}/>)
  }, [userInfo])

  return component
}



export default BasketScreen