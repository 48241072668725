import { configureStore } from '@reduxjs/toolkit'
import auctionSlice from './slices/auctionSlice/auctionSlice'
import userSlice from './slices/userSlice/userSlice'

const store = configureStore({
  reducer: {
    user: userSlice,
    auction: auctionSlice,
  },
})

export default store