import React from 'react'
import './SmallBox.css'

const SmallBox = ({
  className = '',
  children,
  color
}) => {

  const smallBoxColors = ['blue', 'orange', 'red', 'yellow', 'green', 'white']

  const CHECK_SMALL_BOX_COLOR = smallBoxColors.find(x => x === color) ? color : smallBoxColors[0]
    
  return (
    <div className={`small-box small-box--${CHECK_SMALL_BOX_COLOR} ${className}`}>
      {children}
    </div>
  )
}

export default SmallBox