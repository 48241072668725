import React, { useEffect } from 'react'
import ListCard from '../../../../components/ListCard/ListCard'
import 'react-loading-skeleton/dist/skeleton.css'
import { useState } from 'react'

const ParticipantListCard = ({
  peserta
}) => {

  const [list, setList] = useState([])

  useEffect(() => {
    setList(peserta)
  }, [peserta])

  return (
    <ListCard 
      id={'bidder-list'}
      header='Peserta Lelang'
      data={list || []}
      renderData={(ptc, idx) => (
        <div key={idx} className={'flex w-full p-4 items-start text-sm space-x-2 border-b'}>
          <div className='w-6 font-semibold'>{idx+1 < 10 ? `0${idx+1}` : idx+1}.</div>
          <div  className={`flex flex-col space-y-1`}>
            <div className='font-semibold'>{ptc.nama} {ptc.id}</div>
            <div className='text-xs'>{ptc.phone}</div>
          </div>
        </div>             
      )}
    />
  )
}

export default ParticipantListCard