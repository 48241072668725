import React from 'react'
import Card from '../../components/Card/Card'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import TextField from '../../components/TextField/TextField'
import Button from '../../components/Button/Button'
import IconContainer from '../../components/IconContainer/IconContainer'
import { IoBasket, IoPencil, IoTrash } from 'react-icons/io5'
import Alert from '../../components/Alerts/Alert/Alert'
import Select from '../../components/Select/Select'
import { useMoneyDigits } from '../../hooks/useMoneyDigits'
import { useSettingViewModel } from './useSettingsViewModel'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import ActionAlert from '../../components/Alerts/ActionAlerts/ActionAlert'

const SettingsScreen = () => {

  const state = useSelector(state => state)
  const userInfo = state?.user?.userLogin?.userInfo

  const { global, feeNelayan, globalSetting, keranjang } = useSettingViewModel()

  const moneyDigits = useMoneyDigits()

  const CHECK_FEE_NELAYAN_METHOD = feeNelayan.state.alert === 'add' ? 'Tambah' : 'Ubah' 
  const CHECK_KERANJANG_METHOD =  keranjang.state.alert === 'add' ? 'Tambah' : 'Ubah' 

  const globalSettingAlertFields = [
    {
      label: `Fee Nelayan`,
      name: 'FeeNelayan',
      value: globalSetting.state.detail?.FeeNelayan || '',
      onChange: globalSetting.handler.onChange,
      validation: globalSetting.state.errors?.FeeNelayan?.message,
      message: {
        required: `Fee Nelayan harus diisi`,
        invalid: `Jumlah tidak boleh 0 / kosong`
      },
      type: 'number'
    },
    {
      label: `Fee Pedagang`,
      name: 'FeePedagang',
      value: globalSetting.state.detail?.FeePedagang|| '',
      onChange: globalSetting.handler.onChange,
      validation: globalSetting.state.errors?.FeePedagang?.message,
      message: {
        required: `Fee Pedagang harus diisi`,
        invalid: `Jumlah tidak boleh 0 / kosong`
      },
      type: 'number'
    },
    {
      label: `Waktu Penawaran`,
      name: 'Timer',
      value: globalSetting.state.detail?.Timer|| '',
      onChange: globalSetting.handler.onChange,
      validation: globalSetting.state.errors?.Timer?.message,
      message: {
        required: `Fee Pedagang harus diisi`,
        invalid: `Jumlah tidak boleh 0 / kosong`
      },
      type: 'number'
    },
  ]

  const feeNelayanAlertFields = [
    {
      label: `Nama Retribusi`,
      name: 'NamaFee',
      value: feeNelayan.state.detail.NamaFee || '',
      onChange: feeNelayan.handler.onChange,
      validation: feeNelayan.state.errors?.NamaFee?.message,
      message: {
        required: `Nama Retribusi harus diisi`
      },
    },
    {
      label: `Persentase (%)`,
      name: 'NominalFee',
      value: feeNelayan.state.detail.NominalFee|| '',
      onChange: feeNelayan.handler.onChange,
      validation: feeNelayan.state.errors?.NominalFee?.message,
      message: {
        required: `Jumlah harus diisi`,
        invalid: `Jumlah tidak boleh 0 / kosong`
      },
      type: 'number'
    },
  ]

  const keranjangAlertFields = [
    {
      label: `Nama Keranjang`,
      name: 'Nama',
      value: keranjang.state.detail.Nama || '',
      onChange: keranjang.handler.onChange,
      validation: keranjang.state.errors?.Nama?.message,
      message: {
        required: `Nama Keranjang harus diisi`
      },
    },
    
    {
      label: `Berat Keranjang (kg)`,
      name: 'Berat',
      value: keranjang.state.detail.Berat|| '',
      onChange: keranjang.handler.onChange,
      validation: keranjang.state.errors?.Berat?.message,
      message: {
        required: `Jumlah harus diisi`,
        invalid: `Jumlah tidak boleh 0 / kosong`
      },
      type: 'number'
    },
  ]

  const editGlobalSetting = () => {
    globalSetting.handler.alert(true)
    globalSetting.handler.onChange(null, globalSetting.state.list.data)
  }

  const globalSettingSubmit = () => {
    globalSetting.handler.edit(({data, error}) => {
      if(!data) return global.alert.handler.showGlobalAlert('globalSetting', 'error', 'send')
      globalSetting.handler.alert(false)
      globalSetting.handler.fetch()
    })
  }

  const editFeeNelayan = (data) => {
    feeNelayan.handler.alert('edit')
    feeNelayan.handler.onChange(null, data)
  } 

  const validateFeeNelayanValue = () => {
    let errs = {}
    feeNelayanAlertFields.forEach((fnl) => {
      if(!fnl.value || fnl.value === '' ) return errs[fnl.name] = { type: 'required', message: fnl.message.required }
      if(fnl.type === 'number' && (fnl.value === 0 || fnl.value === '0')) return errs[fnl.name] = { type: 'invalid', message: fnl.message.invalid }
    })
    return errs
  }

  const onFeeNelayanSuccess = (data) => {
    feeNelayan.handler.alert(null)
    if(!data) return
    feeNelayan.handler.fetchList()
  } 

  const addListFeeNelayanHandler = (dataToSend) => {
    feeNelayan.handler.add(dataToSend, ({data, error}) => {
      onFeeNelayanSuccess(data)
    })
  }

  const editFeeNelayanHandler = (dataToSend) => {
    feeNelayan.handler.edit(dataToSend, ({data, error}) => {
      onFeeNelayanSuccess(data)
    })
  }

  const deleteFeeNelayan = (dataToSend) => {
    feeNelayan.handler.delete(dataToSend, ({data, error}) => {
      onFeeNelayanSuccess(data)
    })
  }

  const feeNelayanSubmit = () => {
    feeNelayan.handler.errors(validateFeeNelayanValue())
    let dataToSend = {
      "namaFee": feeNelayan.state.detail.NamaFee,
      'nominalFee': Number(feeNelayan.state.detail.NominalFee),
    }
    if(feeNelayan.state.alert === 'add') return addListFeeNelayanHandler(dataToSend)
    editFeeNelayanHandler({...dataToSend, "IdMasterFeeNelayan": Number(feeNelayan.state.detail.IdMasterFeeNelayan)})    
  }

  const editKeranjang = (data) => {
    keranjang.handler.alert('edit')
    keranjang.handler.onChange(null, data)
  } 

  const validateKeranjangValue = () => {
    let errs = {}
    keranjangAlertFields.forEach((fnl) => {
      if(!fnl.value || fnl.value === '' ) return errs[fnl.name] = { type: 'required', message: fnl.message.required }
      if(fnl.type === 'number' && (fnl.value === 0 || fnl.value === '0')) return errs[fnl.name] = { type: 'invalid', message: fnl.message.invalid }
    })
    return errs
  }

  const onKeranjangSuccess = (data) => {
    keranjang.handler.alert(null)
    if(!data) return
    keranjang.handler.fetchList()
  } 

  const addListKeranjangHandler = (dataToSend) => {
    keranjang.handler.add(dataToSend, ({data, error}) => {
      onKeranjangSuccess(data)
    })
  }

  const editKeranjangHandler = (dataToSend) => {
    keranjang.handler.edit(dataToSend, ({data, error}) => {
      onKeranjangSuccess(data)
    })
  }

  const deleteKeranjang = (dataToSend) => {
    keranjang.handler.delete(dataToSend, ({data, error}) => {
      onKeranjangSuccess(data)
    })
  }

  const keranjangSubmit = () => {
    const validatedKeranjang = validateKeranjangValue()
    keranjang.handler.errors(validatedKeranjang)
    if(keranjang.state.errors) return
    let dataToSend = {
      "Berat": Number(keranjang.state.detail.Berat),
      "IdTpi": Number(userInfo.user.id_tpi),
      "Nama": keranjang.state.detail.Nama
    }
    if(keranjang.state.alert === 'add') return addListKeranjangHandler(dataToSend)
    editKeranjangHandler({...dataToSend, "IdMasterKeranjang": Number(keranjang.state.detail.IdMasterKeranjang)})    
  }

  const CHECK_ALERT_COMPONENT = globalSetting.state.alert ? (
    <div className='p-5 space-y-2'>
      {globalSettingAlertFields.map((raf, rafIdx) => (
        raf.fieldType === 'select' ? (
          <Select
            key={rafIdx}
            {...raf}
          />
        ) : (
          <TextField
            key={rafIdx}
            {...raf}
          />
        )
      ))}
      <div className='w-full pt-5'>
        <Button className='w-full' onClick={globalSettingSubmit} disabled={globalSetting.state.list.loading}>
          Ubah Global Setting
        </Button>
      </div>
    </div>    
  ) : feeNelayan.state.alert ? (
    <div className='p-5 space-y-2'>
      {feeNelayanAlertFields.map((raf, rafIdx) => (
        raf.fieldType === 'select' ? (
          <Select
            key={rafIdx}
            {...raf}
          />
        ) : (
          <TextField
            key={rafIdx}
            {...raf}
          />
        )
      ))}
      <div className='w-full pt-5'>
        <Button className='w-full' onClick={feeNelayanSubmit} disabled={feeNelayan.state.detail.loading}>
          {CHECK_FEE_NELAYAN_METHOD} Retribusi
        </Button>
      </div>
    </div>    
  ) : keranjang.state.alert && (
    <div className='p-5 space-y-2'>
      {keranjangAlertFields.map((raf, rafIdx) => (
        raf.fieldType === 'select' ? (
          <Select
            key={rafIdx}
            {...raf}
          />
        ) : (
          <TextField
            key={rafIdx}
            {...raf}
          />
        )
      ))}
      <div className='w-full pt-5'>
        <Button className='w-full' onClick={keranjangSubmit} disabled={keranjang.state.detail.loading}>
          {CHECK_KERANJANG_METHOD} Keranjang
        </Button>
      </div>
    </div>    
  )

  useEffect(() => {
    globalSetting.handler.fetch()
    feeNelayan.handler.fetchList()
    keranjang.handler.fetchList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GlobalLayout contentClassName='flex space-x-5'>
      <Card className='w-full max-w-md border' contentClassName='p-5'>
        <div className="flex items-center justify-between w-full mb-6">
          <div className='text-xl font-bold '>Pengaturan Global</div>
          <button className='act-btn act-btn--blue' title='Edit' onClick={editGlobalSetting}>
            <IconContainer>
              <IoPencil/>
            </IconContainer>
          </button>
        </div>
        <div className='grid gap-2'>
          {globalSettingAlertFields.map((gs, gsIdx) => (
            <div key={gsIdx} className="flex items-center justify-between">
              <div>{gs.label}</div>
              <div>{globalSetting.state.list.data ? globalSetting.state.list.data[gs.name] : ''} {gs.name === 'Timer' ? 'detik' : '%'}</div>
            </div>
          ) )}
        </div>      
      </Card>
      <Card className='w-full max-w-md border' contentClassName='p-5'>
        <div className='flex items-center justify-between w-full mb-6 text-xl font-bold'>
          <div>Retribusi</div>
          <Button size={'sm'} onClick={() => feeNelayan.handler.alert('add')}>+ Tambah Retribusi</Button>
        </div>        
        <div className='grid gap-2'>
          {feeNelayan.state.list.loading ? (
            Array(5).fill('').map((ld , idx) => (
              <div key={idx} className='flex items-center justify-between w-full'>
                <Skeleton containerClassName='w-1/3 h-5'  className='w-full h-full'/>
                <div className='flex items-center space-x-2'>
                  <Skeleton containerClassName='w-8 h-5'  className='w-full h-full'/>
                  <Skeleton containerClassName='w-8 h-8'  className='w-full h-full'/>
                  <Skeleton containerClassName='w-8 h-8'  className='w-full h-full'/>
                </div>
              </div>  
            ))
          ) : feeNelayan.state.list.data?.data?.Records?.length > 0 ? (
            feeNelayan.state.list.data?.data?.Records?.map((fnl, key) => (
              <div key={key} className="flex items-center justify-between">
                <div className='flex space-x-1'>
                  <div>{fnl.NamaFee}</div>
                </div>
                <div className='flex items-center justify-end space-x-4'>                  
                  <div className='flex space-x-1'>
                    <div>{moneyDigits(fnl.NominalFee)}</div>
                    <div>%</div>
                  </div>
                  <div className='flex space-x-2'>
                    <button className='act-btn act-btn--red' title='Delete' onClick={() => deleteFeeNelayan(fnl)}>
                      <IconContainer>
                        <IoTrash/>
                      </IconContainer>
                    </button>
                    <button className='act-btn act-btn--blue' title='Edit' onClick={() => editFeeNelayan(fnl)}>
                      <IconContainer>
                        <IoPencil/>
                      </IconContainer>
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : feeNelayan.state.list.data?.Records?.length === 0 && (
            <div className='text-sm text-gray-500'>Belum Ada Retribusi</div>
          )}
        </div>
                
      </Card>
      <Card className='w-full max-w-md border'  contentClassName='p-5'>
        <div className='flex items-center justify-between w-full mb-6 text-xl font-bold'>
          <div className='flex items-center space-x-2'>
            <IconContainer className='text-2xl text-etpi-orange'>
              <IoBasket/>
            </IconContainer>
            <div>Keranjang</div>
          </div>
          <Button size={'sm'} onClick={() => keranjang.handler.alert('add')}>+ Tambah Keranjang</Button>
        </div>  
        <div className="grid grid-cols-8 pb-2 text-sm font-semibold border-b">
          <div className='col-span-4'>Nama Keranjang</div>
          <div className='col-span-2'>Berat</div>
          <div className='col-span-2 '>Aksi</div>
        </div>
        <div className="flex flex-col w-full space-y-2">
          {keranjang.state.list.data?.data?.Records?.length > 0 ? (
            keranjang.state.list.data?.data?.Records?.map((krj, key) => (
              <div key={key} className="grid items-center grid-cols-8 py-2 border-b">
                <div className='col-span-4'>{krj.Nama}</div>
                <div className='flex col-span-2 space-x-1 text-center'>
                  <div>{moneyDigits(krj.Berat)}</div>
                  <div>Kg</div>
                </div>
                <div className='flex col-span-2 space-x-2'>
                  <button className='act-btn act-btn--red' title='Delete' onClick={() => deleteKeranjang(krj)}>
                    <IconContainer>
                      <IoTrash/>
                    </IconContainer>
                  </button>
                  <button className='act-btn act-btn--blue' title='Edit' onClick={() => editKeranjang(krj)}>
                    <IconContainer>
                      <IoPencil/>
                    </IconContainer>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className='text-sm text-gray-500'>Belum Ada Keranjang</div>
          )} 
        </div>
      </Card>
      {(feeNelayan.state.alert || globalSetting.state.alert || keranjang.state.alert) && (
        <Alert 
          header={`${feeNelayan.state.alert ? CHECK_FEE_NELAYAN_METHOD : keranjang.state.alert && CHECK_KERANJANG_METHOD} ${feeNelayan.state.alert ? 'Retribusi' : 'Global Setting'}`} 
          headerClose 
          closeFunction={() => keranjang.state.alert ? keranjang.handler.alert(null) : feeNelayan.state.alert ? feeNelayan.handler.alert(null) : globalSetting.handler.alert(null)} 
          className={'-mt-[30vh]'}
        >
          {CHECK_ALERT_COMPONENT}
        </Alert>
      )}
      
      {global.alert.state && (
        <ActionAlert
          {...global.alert.state}
        />
      )}
    </GlobalLayout>
  )
}

export default SettingsScreen