import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { API_BASE_URL } from "../../../../utils/urls"

export const fetchHargaPembukaan = createAsyncThunk(
  'auction/fetchHargaPembukaan',
  async (_, thunkAPI) => {    
    const state = thunkAPI.getState()
    const userInfo = state.user.userLogin.userInfo
    const token = userInfo.token
    const idTpi = Number(userInfo.user.id_tpi)
    const idJenisIkan = Number(state.auction.ikan.pilihan.id)
    const config = {
      url: `/dash/harga-pembukaan-ikan`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
      data: { "IdJenisIkan": idJenisIkan, "IdTpi": idTpi }
    }
    const response = await axios(config)
    if (response) return response.data
  }
)

export const hargaExtraReducer = (builder) => {
  builder.addCase(fetchHargaPembukaan.pending, (state, action) => {
    state.harga.pembukaan.loading = true
  })
  builder.addCase(fetchHargaPembukaan.fulfilled, (state, action) => {
    state.harga.pembukaan.data = action.payload.data
  })
  builder.addCase(fetchHargaPembukaan.rejected, (state, action) => {
    state.harga.pembukaan.loading = false 
    state.harga.pembukaan.error = action.error.message
  })
} 