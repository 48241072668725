import { IoReceiptOutline } from 'react-icons/io5'
import { RiDashboardFill, RiUserFill, RiSettings5Fill, RiSailboatFill, RiScalesFill, RiPieChart2Fill, RiShoppingBasketFill } from 'react-icons/ri'
import MainScreen from '../screens/MainScreen/MainScreen'
import ReceiptScreen from '../screens/ReceiptScreen/ReceiptScreen'
import ReportsScreen from '../screens/ReportsScreen/ReportsScreen'
import BasketScreen from '../screens/BasketScreen/BasketScreen'
import SettingsScreen from '../screens/SettingsScreen/SettingsScreen'
import SettlementFisherScreen from '../screens/SettlementFisherScreen/SettlementFisherScreen'
import SettlementTPIScreen from '../screens/SettlementTPIScreen/SettlementTPIScreen'
import TransactionsScreen from '../screens/TransactionsScreen/TransactionsScreen'
import { FaHandshake, FaShip } from 'react-icons/fa'
import TransactionScreen from '../screens/TransactionsScreen/TransactionScreen'
import FisherTransactions from '../screens/FisherTransactions/FisherTransactions'

const routes = {
  JuruLelang: [
    {
      name: 'main-dashboard',
      title: 'dashboard-utama',
      path: '/',
      element: <MainScreen />,
      icon: <RiDashboardFill />
    },
    {
      name: 'keranjang',
      title: 'keranjang',
      path: '/basket',
      element: <BasketScreen />,
      icon: <RiShoppingBasketFill />
    },
   
    {
      name: 'users',
      title: 'daftar-pengguna',
      path: '/users',
      element: <MainScreen />,
      icon: <RiUserFill />
    },
    {
      name: 'receipt',
      title: 'struk',
      path: '/receipt/:idNelayan/:idTransaksi',
      element: <ReceiptScreen />,
      icon: <IoReceiptOutline />
    },
    {
      name: 'fisher-transactions',
      title: 'transaksi-nelayan',
      path: '/transactions',
      element: <FisherTransactions/>,
      icon: <FaShip/>
    },
  ], 
  Supervisor: [
    {
      name: 'settings',
      title: 'pengaturan',
      path: '/',
      element: <SettingsScreen />,
      icon: <RiSettings5Fill />
    },
    {
      name: 'transactions',
      title: 'histori-transaksi',
      path: '/transactions',
      element: <TransactionsScreen />,
      icon: <FaHandshake />
    },
    {
      name: 'transaction-detail',
      title: 'detail-transaksi',
      path: '/transactions/:namaKapal',
      element: <TransactionScreen />,
      icon: <FaHandshake />
    },
  ],
  Finance: [
    {
      name: 'main-dashboard',
      title: 'dashboard-utama',
      path: '/',
      element: <SettlementFisherScreen/>,
      icon: <RiSailboatFill/>
    },
    {
      name: 'tpi-transaction',
      title: 'transaksi-tpi',
      path: '/tpi',
      element: <SettlementTPIScreen/>,
      icon: <RiScalesFill/>
    },
    {
      name: 'receipt',
      title: 'struk',
      path: '/receipt/:idNelayan/:idTransaksi',
      element: <ReceiptScreen />,
      icon: <IoReceiptOutline />
    },
    {
      name: 'transactions',
      title: 'histori-transaksi',
      path: '/transactions',
      element: <TransactionsScreen/>,
      icon: <FaHandshake />
    },
    {
      name: 'transaction-detail',
      title: 'detail-transaksi',
      path: '/transactions/:namaKapal',
      element: <TransactionScreen />,
      icon: <FaHandshake />
    },
  ],
  Stakeholder: [
    {
      name: 'main-dashboard',
      title: 'reports',
      path: '/',
      element: <ReportsScreen/>,
      icon: <RiPieChart2Fill/>
    },
  ]
}

export default routes
