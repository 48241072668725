import React from 'react'

const AuthLayout = ({
  children
}) => {
  return (
    <div className='flex flex-col items-center pt-[10%] w-full h-screen bg-etpi-blue'>
      {children}
    </div>
  )
}

export default AuthLayout