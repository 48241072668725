import { useEffect, useState } from "react";
import io from 'socket.io-client'
import { API_BASE_URL } from "../utils/urls";
import { useFindExistData } from "./useFindExistData";


export const useSocket = () => {
  const [socket, setSocket] = useState(null)
  const [connect, setConnect] = useState()
  const [disconnect, setDisconnect] = useState()
  const [commandcenter, setCommandcenter] = useState()    
  const [login, setLogin] = useState()
  const [dashboard, setDashboard] = useState({})
  const [broadcast, setBroadcast] = useState({})
  const [antrian, setAntrian] = useState()
  const [sesi, setSesi] = useState()
  const [harga, setHarga] = useState()
  const [timbangan, setTimbangan] = useState()
  const [notifTimbangan, setNotifTimbangan] = useState()

  const findExistData = useFindExistData()

  // ---------------------------------------------------------------- EMITS ------------------------------------------------------------------
  const getLelangSesiEmitter =  (id, callBack) => {
    const arg = { 
      "commandcenter": "getlelangsesi", 
      "transaction": { "id_tpi" : `${id}` }
    }
    socket.emit("commandcenter", JSON.stringify(arg) )
    callBack && callBack(arg)
  }
  
  const loginEmitter = (token, callBack) => {
    const arg = {
      "token": token, 
      "type": "etpi"
    }
    socket.emit("login", JSON.stringify(arg))
    callBack && callBack(arg)
  }

  const dashboardEmitter = (callBack) => {
    socket.emit('dashboard')
    callBack && callBack()
  }


  const broadcastEmitter = (callBack) => {
    socket.emit('broadcast')
    callBack && callBack()
  }

  const antrianEmitter = (shipQueueId, callBack) => {
    socket.emit('antrian', JSON.stringify({id_antrian_kapal : Number(shipQueueId)}))
    callBack && callBack()
  }

  const sesiEmitter = (arg = {
    "id_antrian_kapal": 0,
    "id_timbangan": 0,
    "id_jenis_ikan":0,
    "berat": 0,
    "harga_aktual": 0,
    "status": ""
  }, callBack) => {
    socket.emit('sesi', JSON.stringify(arg))
    callBack && callBack()
  }

  const hargaEmitter = (arg = {
    "range": 0,
    "status": ""
  }, callBack) => {
    socket.emit('harga', JSON.stringify(arg))
    callBack && callBack()
  }

  const timbanganEmitter = (arg = {
    "berat": 1,
    "id_jenis_ikan": 1,
    "id_tpi": 1
  }, callBack) => {
    socket.emit('timbangan', JSON.stringify(arg))
    callBack && callBack()
  }
  // ---------------------------------------------------------------- END EMITS ------------------------------------------------------------------

  
  // ---------------------------------------------------------------- LISTENERS ------------------------------------------------------------------
  const connectListener = (callBack) => {
    socket.on('connect', (data) => {
      setConnect(data)
      callBack && callBack(data)
    })
  }

  const disconnectListener = (callBack) => {
    socket.on('disconnect', (data) => {
      setDisconnect(data)
      callBack && callBack(data)
    })
  }

  const commadCenterListener = (callBack) => {
    socket.on("commandcenter", (data) => {
      setCommandcenter(findExistData(commandcenter, data, 'commandcenter'))
      callBack && callBack(data)
    })
  }
  
  const loginListener = (callBack) => {
    socket.on("login", (data) => {
      setLogin(data)
      callBack && callBack(data)
    })
  }

  const dashboardListener = (callBack) => {
    socket.on("dashboard", (data) => {
      setDashboard(data?.data)
      callBack && callBack(data?.data)
    })
  }

  const broadcastListener = (callBack) => {
    socket.on("broadcast", (data) => {
      let broadcastObj = broadcast    
      if(data.data.peserta_bidder) {
        broadcastObj.peserta_bidder = data.data.peserta_bidder
        setBroadcast(broadcastObj)
      }
      if(data.data.peserta_lelang) {
        broadcastObj.peserta_lelang = data.data.peserta_lelang
        setBroadcast(broadcastObj)
      }      
      callBack && callBack(data.data)
    })
  }

  const antrianListener = (callBack) => {
    socket.on("antrian", (data) => {
      setAntrian(data)
      callBack && callBack(data)
    })
  }

  const sesiListener = (callBack) => {
    socket.on("sesi", (data) => {
      setSesi(data)
      callBack && callBack(data)
    })
  }

  const hargaListener = (callBack) => {
    socket.on("harga", (data) => {
      setHarga(data)
      let broadcastObj = {...broadcast}
      broadcastObj.peserta_bidder = null
      setBroadcast(broadcastObj)
      callBack && callBack(data)
    })
  }

  const timbanganListener = (callBack) => {
    socket.on("timbangan", (data) => {
      setTimbangan(data)
      callBack && callBack(data)
    })
  }

  const notifTimbanganListener = (callBack) => {
    socket.on("notif-timbangan", (data) => {
      setNotifTimbangan(data)
      callBack && callBack(data)
    })
  }
  // ---------------------------------------------------------------- END LISTENERS ------------------------------------------------------------------

  // ---------------------------------------------------------------- REMOVE LISTENERS ------------------------------------------------------------------
  const removeNotifTimbanganListener = (callBack) => {
    socket.off("notif-timbangan", (data) => {
      callBack && callBack(data)
    })
  }
  // ---------------------------------------------------------------- END REMOVE LISTENERS ------------------------------------------------------------------

  const event= {
    connect: {
      data: connect,
      listen: connectListener
    },
    disconnect: {
      data: disconnect,
      listen: disconnectListener
    },
    commandcenter: {
      data: commandcenter,
      emit: {
        getLelangSesi: getLelangSesiEmitter
      },
      listen: commadCenterListener
    },
    login: {
      data: login,
      emit: loginEmitter,
      listen: loginListener,
    },
    dashboard: {
      data: dashboard,
      emit: dashboardEmitter,
      listen: dashboardListener
    },
    broadcast: {
      data: broadcast,
      emit: broadcastEmitter,
      listen: broadcastListener
    },
    antrian: {
      data: antrian,
      emit: antrianEmitter,
      listen: antrianListener
    },
    sesi: {
      data: sesi,
      emit: sesiEmitter,
      listen: sesiListener
    },
    harga: {
      data: harga,
      emit: hargaEmitter,
      listen: hargaListener
    },
    timbangan: {
      data: timbangan,
      emit: timbanganEmitter,
      listen: timbanganListener,
      notif: {
        data: notifTimbangan,
        listen: notifTimbanganListener,
        unlisten: removeNotifTimbanganListener
      }
    },
  }

  useEffect(() => {
    const newSocket = io(API_BASE_URL, { path: '/e-tpi' });
    setSocket(newSocket);
    return () => newSocket.close();   
  }, [])

  return { 
    socket, 
    event, 
    data: { 
      commandcenter,
      login,
      dashboard,
      broadcast,
      antrian,
      sesi,
      harga,
      timbangan
    } 
  }
}


