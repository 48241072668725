import { createSlice } from "@reduxjs/toolkit"
import Cookies from "js-cookie"
import { antrianExtraReducer } from "./extraReducers/antrianExtraReducer"
import { hargaExtraReducer } from "./extraReducers/hargaExtraReducer"
import { ikanExtraReducer } from "./extraReducers/ikanExtraReducer"
import { timbanganExtraReducer } from "./extraReducers/timbanganExtraReducer"

const pickDataArr = ['lelang', 'sesi', 'timbangan', 'ikan', 'harga', 'errors']

const setCookies = (name, state) => {
  let cookiesObj = Cookies.get(name) ? JSON.parse(Cookies.get(name)) : {}
  const stateEntries = Object.entries(state)
  stateEntries.forEach(([key, value]) => {
    cookiesObj[key] = value
  })
  Cookies.set(name, JSON.stringify(cookiesObj))
} 

const getCookies = (name, initial) => {
  let cookiesObj = Cookies.get(name) 
  if(!cookiesObj) return initial
  let initialObj = {...initial}
  let parsedCookies = JSON.parse(Cookies.get(name))
  let parsedCookiesEntries = Object.entries(parsedCookies)
  parsedCookiesEntries.forEach(([key, value]) => {
    initialObj[key] = value
  })
  return initialObj
}

const initialState = {
  lelang: getCookies('auction/lelang', {
    status: false,
    waktu_mulai: '',
    waktu_selesai: '',
  }),
  sesi: getCookies('auction/sesi', {
    status: false,
    waktu_mulai: '',
    waktu_selesai: '',
  }),
  antrian: {
    list: {
      loading: false,
      error: '',
      data: []
    },
    info: getCookies('auction/antrian/info',{
      loading: false,
      error: '',
      data: {}
    })
  },
  ikan: getCookies('auction/ikan',{
    list: {
      loading: false,
      error: '',
      data: []
    },
    info: getCookies('auction/ikan/info',{
      loading: false,
      error: '',
      data: {}
    }),
    harga_pembukaan: getCookies('auction/ikan/harga_pembukaan',{
      loading: false,
      error: '',
      data: {}
    }),
    pilihan: '',
    bobot: ''
  }),
  timbangan: getCookies('auction/timbangan', {
    list: {
      loading: false,
      error: '',
      data: []
    },
    pilihan: ''
  }),
  harga: getCookies(`auction/harga`, {
    aktual: '',
    selisih: '',
    pembukaan: getCookies('auction/harga/pembukaan', {
      loading: false,
      error: '',
      data: {}
    })
  }),
  errors: {}
}

const acutionSlice = createSlice({
  name: 'auction',
  initialState: initialState,
  reducers: {
    mulaiLelang: (state, action) => {
      state.lelang.status = true
      state.lelang.waktu_mulai = action.payload
      setCookies('auction/lelang', {
        status: true,
        waktu_mulai: action.payload
      })
    },
    tutupLelang: (state, action) => {
      state.lelang.status = false
      state.lelang.waktu_selesai = action.payload
      setCookies('auction/lelang', {
        status: false,
        waktu_selesai: action.payload
      })
    },
    mulaiSesi: (state, action) => {
      state.sesi.status = true
      state.sesi.waktu_mulai = action.payload
      setCookies('auction/sesi', {
        status: true,
        waktu_mulai: action.payload
      })
    },
    tutupSesi: (state, action) => {
      state.sesi.status = false
      state.sesi.waktu_selesai = action.payload
      setCookies('auction/sesi', {
        status: false,
        waktu_selesai: action.payload
      })
    },
    pilihTimbangan: (state, action) => {
      state.timbangan.pilihan = action.payload
      setCookies('auction/timbangan', {
        pilihan: action.payload
      })
    },
    pilihJenisIkan: (state, action) => {
      state.ikan.pilihan = action.payload
      setCookies('auction/ikan', {
        pilihan: action.payload
      })
    },
    setBobotIkan: (state, action) => {
      state.ikan.bobot = Number(action.payload)
      setCookies('auction/ikan', {
        bobot: Number(action.payload)
      })
    },
    setHargaAktual: (state, action) => {
      state.harga.aktual = action.payload
      setCookies('auction/harga', {
        aktual: action.payload
      })
    },
    setHargaSelisih: (state, action) => {
      state.harga.selisih = action.payload
      setCookies('auction/harga', {
        selisih: action.payload
      })
    },
    resetLelang: (state, action) => {     
      pickDataArr.forEach((pck) => {
        Cookies.remove(`auction/${pck}`)
        state[pck] = initialState[pck]
      })
    },
    setAuctionError: (state, action) => {
      state.errors = action.payload
    }
  },
  extraReducers: (builder) => {
    const extraReducers = [antrianExtraReducer, timbanganExtraReducer, ikanExtraReducer, hargaExtraReducer]
    for (let i = 0; i < extraReducers.length; i++) {
      extraReducers[i](builder)      
    }
  }
})

export const { mulaiLelang, tutupLelang, mulaiSesi, tutupSesi, pilihTimbangan, pilihJenisIkan, setBobotIkan, setHargaAktual, setHargaSelisih, resetLelang, setAuctionError } = acutionSlice.actions
export default acutionSlice.reducer
