import React from 'react'
import Card from '../../../../components/Card/Card'
import Title from '../../../../components/Title/Title'
import { useMoneyDigits } from '../../../../hooks/useMoneyDigits'
import { CURRENCY } from '../../../../utils/currency'

const WinnerInfoCard = ({
  winnerName ,
  priceToPay
}) => {

  const moneyDigits = useMoneyDigits()

  return (
    <Card 
      className='p-5'
      contentClassName='flex flex-col divide-y'
    >
      <div className='flex items-center pb-4 space-x-2'>
        <Title
          containerClassName='text-center items-center w-full space-y-1'
          subtitle={'Pemenang Lelang'}
          subtitleClassName={'text-xs'}
          subtitlePosition={'top'}
          title={winnerName || '-'}
          titleWeight={'bold'}
        />
      </div>
      <div className='flex flex-col items-center w-full pt-4 text-center'>
        <Title
          containerClassName='text-center items-center w-full space-y-1'
          subtitle={`Nominal Pembayaran (${CURRENCY})`}
          subtitleClassName={'text-xs'}
          subtitlePosition={'top'}
          title={priceToPay ? ` ${moneyDigits(priceToPay)}` : '-'}
          titleWeight={'bold'}
        />
      </div>
    </Card>
  )
}

export default WinnerInfoCard