import React from 'react'
import './Title.css'

const Title = ({
  containerClassName = '',
  icon,
  title,
  titleClassName = '',
  titleWeight,
  subtitle,
  subtitleClassName = '',
  subtitlePosition,
}) => {
  
  const subtitlePositions = ['top', 'bottom']
  const titleWeights = ['medium', 'semibold', 'bold', 'extrabold', 'black']

  const CHECK_SUBTITLE_POSITION = subtitlePositions.find(x => x = subtitlePosition) ? subtitlePosition : subtitlePositions[0]
  const CHECK_TITLE_WEIGHT = titleWeights.find(x => x = titleWeight) ? titleWeight : titleWeights[0]

  return (
    <div className={`title__container ${containerClassName}`}>
      {CHECK_SUBTITLE_POSITION === 'top' ? (
        <div className={`subtitle ${subtitleClassName}`}>{subtitle}</div>
      ) : ''}
      <div className={`title title--${CHECK_TITLE_WEIGHT} ${titleClassName}`}>
        { icon ? ( icon ) : '' }
        <div className='title__text'>{title}</div>
      </div>
      {CHECK_SUBTITLE_POSITION === 'bottom' ? (
        <div className={`subtitle ${subtitleClassName}`}>{subtitle}</div>
      ) : ''}
    </div>
  )
}

export default Title