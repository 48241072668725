import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const convertBreadcrumb = string => {
  return string
    .replace(/_/g, ' ')
    .replace(/oe/g, 'ö')
    .replace(/ae/g, 'ä')
    .replace(/ue/g, 'ü')
    .replace(`%20`, ' ')
    .toUpperCase();
};


const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  const location = useLocation()

  useEffect(() => {
    const linkPath = location.pathname.split('/');
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return { breadcrumb: path, href: '/' + linkPath.slice(0, i + 1).join('/') };
      });

      setBreadcrumbs(pathArray);    
  }, [location.pathname]);

  if (!breadcrumbs) {
    return null;
  }

  return (
    <nav aria-label="breadcrumbs" className='fixed top-0 right-0 flex items-center h-16 px-5 left-16'>
      <ol className="flex items-center space-x-2 text-sm">
        {breadcrumbs.map((breadcrumb, breadcrumbIdx) => {
          return (
            <React.Fragment key={breadcrumb.href}>
              <li>
                {breadcrumbIdx !== breadcrumbs.length - 1 ? (
                  <Link to={breadcrumb.href} className='link link--to-secondary'>
                    {convertBreadcrumb(breadcrumb.breadcrumb)}
                  </Link>
                ) : (
                  convertBreadcrumb(breadcrumb.breadcrumb)
                )}
              </li>
              {breadcrumbs?.length > 1 && breadcrumbIdx !== breadcrumbs.length - 1 && (
                <span>&gt;</span>
              )}
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;