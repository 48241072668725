import './Notification.css';
import { IoIosClose } from "react-icons/io";
const Notification = ({
    notification,
    header,
    content,
    notifClose,
    notifColor
}) => {

    const colors = ['blue', 'orange', 'red']

    const CHECK_COLOR = colors.find(x => x === notifColor) ? notifColor : colors[0]

    return (
        <>
            {notification && (
                <div className={`notification notification--${CHECK_COLOR}`}>
                    <div className={'notification__header'}>
                        <p>{header}</p>
                        <IoIosClose className='notification__header__icon' onClick={notifClose} />
                    </div>
                    <div className={'notification__contents'}>
                        <p>{content}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Notification
