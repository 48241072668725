import axios from "axios"
import Cookies from "js-cookie"
import { useEffect } from "react"
import { useCallback } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTimer } from "react-timer-hook"
import { useGetDate } from "../../../hooks/useGetDate"
import { useMoneyDigits } from "../../../hooks/useMoneyDigits"
import { useSocket } from "../../../hooks/useSocket"
import useToggle from "../../../hooks/useToggle"
import { logout } from "../../../redux/slices/userSlice/userSlice"
import { API_BASE_URL } from "../../../utils/urls"
import { useSettingViewModel } from "../../SettingsScreen/useSettingsViewModel"
import { useFetchHargaPembukaan } from "../hooks/useFetchHargaPembukaan"
import { useFetchInfoJenisIkan } from "../hooks/useFetchInfoJenisIkan"
import { useFetchListAntrian } from "../hooks/useFetchListAntrian"
import { useFetchListJenisIkan } from "../hooks/useFetchListJenisIkan"

export const ViewModel = () => {  

  const [timer, setTimer] = useState(0)

  const time = new Date()  
  time.setSeconds(time.getSeconds() + timer)
  const expiryTimestamp = time
  const {
    seconds,
    pause,
    restart,
  } = useTimer({ expiryTimestamp });

  const restartTimer = useCallback( () => { 
    const time = new Date()  
    time.setSeconds(time.getSeconds() + timer)
    restart(time)
  }, [restart, timer])
  
  const initialState = {
    user: Cookies.get(`userInfo`) ? JSON.parse(Cookies.get(`userInfo`)) : null,
  }
  

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getDate = useGetDate()
  const { date, month, year } = getDate('') 

  const { socket, event } = useSocket()
  const [socketLogin, setSocketLogin] = useState(false)

  const [loading, setLoading] = useState(false)

  const [userInfo] = useState(initialState.user)
  const [token] = useState(userInfo?.token)
  const [tanggal] = useState(`${year}-${month}-${date}`)  
  const [errors, setErrors] = useState(null)

  const [antrian, setAntrian] = useState(null)
  const [antrianLoading, setAntrianLoading] = useState(true)
  const [antrianPopUp, setAntrianPopUp] = useState(null)

  const [lelang, setLelang] = useState(false)
  const [akhiriLelangAlert, toggleAkhiriLelangAlert] = useToggle(false)
  const [lelangNoSesiAlert, toggleLelangNoSesiAlert] = useToggle(false)
  const [lelangInvalidParamAlert, toggleLelangInvalidParamAlert] = useToggle(false)

  const [sesi, setSesi] = useState(false)
  const [akhiriSesiAlert, toggleAkhiriSesiAlert] = useToggle(false)
  const [sesiNoBidderAlert, toggleSesiNoBidderAlert] = useToggle(false)

  const [aktifNoAntrian, setAktifNoAntrian] = useState('')
  const [aktifKapalId, setAktifKapalId] = useState('')
  const [aktifNamaKapal, setAktifNamaKapal] = useState('')  
  const [aktifJenisIkan, setAktifJenisIkan] = useState('')
  const [aktifTotalTransaksi, setAktifTotalTransaksi] = useState('')
  const [aktifJumlahIkan, setAktifJumlahIkan] = useState('')
  const [aktifPenerimaan, setAktifPenerimaan] = useState('') 


  const [jenisIkan, setJenisIkan] = useState('')
  const [listJenisIkan, setListJenisIkan] = useState([])
  const [infoJenisIkan, setInfoJenisIkan] = useState(null)
  const [pembukaan, setPembukaan] = useState(null)

  const [beratIkan, setBeratIkan] = useState('')
  const [refreshBeratIkanAlert, toggleRefreshBeratIkanAlert] = useToggle(false)

  const [selisih, setSelisih] = useState(1000)
  const [aktual, setAktual] = useState('')

  const [pemenang, setPemenang] = useState(null)
  const [bidder, setBidder] = useState([{}, {}])
  const [peserta, setPeserta] = useState(null)

  const [ikanAntrian, setIkanAntrian] = useState('')
  const [listIkanAntrian, setListIkanAntrian] = useState({loading: false, error: '', data: []})


  const HARGA_TERENDAH = 500
  const DASH_SUM = event.dashboard.data?.summary

  const sesiArg = {
    "id_antrian_kapal": Number(aktifKapalId) || DASH_SUM?.id_antrian_kapal,
    "id_jenis_ikan": Number(ikanAntrian.id) || DASH_SUM?.id_jenis_ikan,
    "berat": Number(ikanAntrian.berat) || DASH_SUM?.berat,
    "harga_aktual": Number(aktual) || DASH_SUM?.harga_aktual,
    "range": Number(selisih) || DASH_SUM?.range,
    "key_timbangan": ikanAntrian?.timbangan || Cookies.get('keyTimbangan') || 'string'
  }

  const emitSesi = (status, callBack) => {
    const arg = {
      ...sesiArg,
      "status": status
    }    
    event.sesi.emit(arg)
  }
  

  // LELANG -------------------------------------------------------------------------------


  const akhiriLelang = (e, receipt) => {
    setLoading(true)
    emitSesi('finish')
    event.sesi.listen((data) => {
      toggleAkhiriLelangAlert(false)
      toggleLelangNoSesiAlert(false)
      setLoading(false)
      if(data.errors === 'Invalid Parameter') return toggleLelangInvalidParamAlert(true)
      if(data.status_code !== 200) return 
      Cookies.remove('keyTimbangan')
      if(receipt) {
        const sesiData = data.data
        const idNelayan = sesiData.id_nelayan
        const idTransaksi = sesiData.id_transaksi
        window.open(`/receipt/${idNelayan}/${idTransaksi}`, '_blank')
      }
    })    
  }  


  // ANTRIAN ------------------------------------------------------------------------------

  const fetchListAntrian = useFetchListAntrian(
    token,
    tanggal,
    setAntrian,
    setAntrianLoading,
  )

  useEffect(() => {    
    fetchListAntrian()
  // eslint-disable-next-line 
  }, [lelang])

  const mulaiLelang = (e, ship, idx) => {
    setLoading(true)
    event.antrian.emit(ship.ID)
    event.antrian.listen((data) => {     
      setLoading(false)
    })
  }
  // ANTRIAN AKTIF --------------------------------------------------------------------------------- 
 

  // JENIS IKAN -------------------------------------------------------------------------------n
  const fetchHargaPembukaan = useFetchHargaPembukaan()

  const jenisIkanOnSelect = (e, data, idx) => {
    setJenisIkan(data)
    fetchHargaPembukaan(
      token,
      data.id,
      userInfo.user.id_tpi,
      setPembukaan
    )
  }

  useFetchListJenisIkan(
    token,
    setListJenisIkan
  )  

  useFetchInfoJenisIkan(
    token,
    jenisIkan?.id,
    setInfoJenisIkan
  )


  // BERAT IKAN -------------------------------------------------------------------------------
  const beratIkanOnChange = (e) => {
    setBeratIkan(e.target.value)
  }

  const refreshBeratIkan = (e) => {
    ikanAntrianOnSelect(e, {id: ikanAntrian.id})
  }


  // HARGA -------------------------------------------------------------------------------
  const moneyDigits = useMoneyDigits()
  
  const selisihOnFocus = (e) => {
    e.target.select()
  } 

  const selisihOnChange = (e) => {
    setSelisih(e.target.value)
  }

  const aktualOnChange = (e) => {
    const value = e.target.value 
    setAktual(Math.floor(Number(value)))
  }

  const aktualOnFocus = (e) => {
    e.target.select()
    pause()
  }
  
  const aktualOnBlur = (e) => {  
    const value = e.target.value 
    setAktual(Math.floor(Number(value < 500 ? 500 : value)))
    if(!sesi) return
    event.harga.emit({
      "range": Number(value < 500 ? 500 : value),
      "status": "change"
    })
    event.harga.listen((data) => {
      if (data.status_code !== 200) return 
    })       
  }

  const incrementDecrementHandler = useCallback((action) => {    
    if(!socket || !sesi) return
    const numAktual = Number(aktual)
    const numSelisih = Number(selisih)
    const decrement = numAktual - numSelisih
    const increment = numAktual + numSelisih
    const actions = {
      increment: {
        value: increment,
        status: 'up'
      },
      decrement: {
        value:  decrement,
        status: 'down'
      },
    }
    if(action === 'decrement' && decrement < HARGA_TERENDAH) return
    const status = actions[action].status
    event.harga.emit({
        "range": numSelisih,
        "status": status
    })
    event.harga.listen((data) => {
      if (data.status_code !== 200) return 
      restartTimer()
    })        
  }, [aktual, event.harga, restartTimer, selisih, sesi, socket])

  useEffect(() => {
    if(!pembukaan) return
    setAktual(Math.floor(Number(pembukaan?.HargaPembukaan)))
    setSelisih(pembukaan?.RangeHarga)
  }, [pembukaan])

  // IKAN ANTRIAN LELANG ------------------------------------------------------------------------------- 
  const ikanAntrianOnSelect = async(e, data, idx) => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${API_BASE_URL}/dash/harga-pembukaan-ikan`,
        {
          "id_antrian_lelang": aktifKapalId,
          "id_jenis_ikan": data.id,
        },
        {
          headers: {
            Authorization: token,
          }
        }
      )
      const res = response.data.data
      setIkanAntrian({
        id: res.id_jenis_ikan ,
        nama: res.nama_ikan,
        berat: res.berat_total,
        timbangan: res.key_timbangan
      })
      Cookies.set('keyTimbangan', JSON.stringify(res.key_timbangan))
      setAktual(res.harga_pembukaan)
      setSelisih(res.range_harga)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if(error.message.includes('401')) return dispatch(logout())
    }
  }

  const closeAntrianPopUp = () => {
    setAntrianPopUp(null)
  }

  const fetchListIkanAntrianAktif = async() => {
    if(!aktifKapalId || aktifKapalId === 0) return
    setListIkanAntrian(current => ({...current, loading: true}))
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/dash/antrian-kapal/${aktifKapalId}/ikan`,
        {
          headers: {
            Authorization: token
          },
        }
      )
      if (!data) return
      let jenisIkanArr = []
      const dataJenisIkan = data.data
      for (let i = 0; i < dataJenisIkan.length; i++) {
        const dji = dataJenisIkan[i]
        jenisIkanArr.push({
          id: dji.id_jenis_ikan, 
          value: dji.nama_ikan, 
        })          
      }
      setListIkanAntrian(current => ({...current, loading: false, data: jenisIkanArr}))
    } catch (error) {
      setListIkanAntrian(current => ({...current, loading: false, error: error.response ? error.response.message : error.message}))
      if(error.message.includes('401')) return dispatch(logout())
    }
  }

  useEffect(() => {
    if(!socket) return
    event.antrian.listen((data) => {
      if(data.status_code === 400) {
        setAntrianPopUp(data)
        return
      } 
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  // SESI -------------------------------------------------------------------------------
 

  const valueToValidate = [
   
    {
      name: 'ikanAntrian',
      value: ikanAntrian,
      required: true,
      message: {
        required: 'harus memilih ikan'
      }
    },
    {
      name: 'selisih',
      value: selisih,
      required: true,
      message: {
        required: 'Selisih Harga tidak boleh kosong / 0'
      }
    },
    {
      name: 'aktual',
      value: aktual,
      required: true,
      message: {
        required: 'Harga Aktual tidak boleh kosong / 0'
      }
    },

  ]

  const validateValue = () => {
    let errObj = errors ? {...errors} : {}
    valueToValidate.forEach(val => {
      if(val.required && (!val.value || val.value === '' || val.value === 0 || val.value === '0' )) return errObj[val.name] = { type: 'required', message: val.message.required }
      delete errObj[val.name]
    })
    return Object.entries(errObj).length === 0 ? null : errObj
  }

  const mulaiSesi = () => {
    const valueValidation = validateValue()
    setErrors(valueValidation)
    if(valueValidation) return
    setLoading(true)
    emitSesi('start')
    event.sesi.listen((data) => {
      setLoading(false)
      if (data.status_code === 400) {
        setAntrianPopUp(data)
        return
      } 
    })
  }

  const akhiriSesi = () => {
    toggleAkhiriSesiAlert()
    setLoading(true)
    emitSesi('stop')
    event.sesi.listen((data) => {
      setLoading(false)
      if(data.message === "no bid participant.") return toggleSesiNoBidderAlert(true)
      if (data.status_code !== 200) return pause()
    })    
  } 
   
  // SOCKET ------------------------------------------------------------------------------- 
  useEffect(() => {
    if(!socket) return
    setTimeout(() => {
      event.login.emit(token)
    }, 2000)

    event.login.listen((data) => {
      if(data.status_code === 200) return setSocketLogin(true)
      setSocketLogin(false)
      dispatch(logout())
      navigate('/login') 
    })

    event.disconnect.listen((reason) => {
      setSocketLogin(false)
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
        setSocketLogin(true)
      }
    })    

    socket.on('bidder', (data) => { 
      if(!data.data) return
      const bidderList = data.data.peserta_bidder
      setBidder(bidderList)
    })

    event.broadcast.listen((data) => {
      setPeserta(data.peserta_lelang)
    })

  }, [socket]) //eslint-disable-line

  useEffect(() => {
    if(!socket|| !ikanAntrian) return
    if(sesi) socket.off('notif-timbangan')
    if(!sesi) socket.on("notif-timbangan", (data) => {
      if(data.id_jenis_ikan === ikanAntrian?.id && data.id_antrian_lelang === aktifKapalId) {
        toggleRefreshBeratIkanAlert(true)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ikanAntrian, socket, sesi, aktifKapalId])

  useEffect(() => {
    if(bidder?.length === 1) return pause()
    if(bidder?.length >= 2) return restartTimer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidder])  


  useEffect(() => {
    if(!socket) return
    event.dashboard.listen((data) => {      

      const dashSum = data?.summary
      const dashPem = data?.pemenang
      
      setPemenang(dashPem ? { nama: dashPem.nama, pembayaran: dashPem.pembayaran } : "")
      setLelang(dashSum?.sesi || false)
      setSesi(dashSum?.sesi === 2 ? true : false)
      setAktifKapalId(dashSum?.id_antrian_kapal || "")
      setAktifNoAntrian(dashSum?.no_antrian || "")
      setAktifNamaKapal(dashSum?.nama_kapal || "")
      setAktifTotalTransaksi(dashSum?.total_transaksi || "")
      setAktifPenerimaan(dashSum?.total_penerimaan || "")
      setAktifJenisIkan(dashSum?.jenis_ikan || "")
      setAktifJumlahIkan(dashSum?.jumlah_ikan || "")
      setBeratIkan(dashSum?.berat || "")
      setJenisIkan(dashSum?.id_jenis_ikan > 0 ? listJenisIkan.find(x => x.id === dashSum?.id_jenis_ikan) : '')
      const LIST_IKAN = dashSum?.sesi === 2 ? listJenisIkan : listIkanAntrian.data
      const findIkan = LIST_IKAN?.find(x => x.id === dashSum?.id_jenis_ikan)
      if(findIkan)  setIkanAntrian(current => ({
        ...current,
        id: dashSum?.id_jenis_ikan ,
        nama: findIkan.value,
        berat: dashSum?.berat,
      }))
      if(dashSum?.sesi === 2) {     
        if(dashSum?.harga_aktual !== aktual) setAktual(Math.floor(Number(dashSum?.harga_aktual)))
        if(dashSum?.range !== selisih) setSelisih(dashSum?.range)
        restartTimer()   
        if(bidder?.length === 1) pause()
        setAktual(dashSum?.harga_aktual)
        setSelisih(dashSum?.range)
      }      
      if(dashSum?.sesi !== 2) {
        pause()
        setAktual(0)
        setSelisih(0)
        setIkanAntrian('')
        setListIkanAntrian([])
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aktual, event.dashboard, listJenisIkan, selisih, socket])

  useEffect(() => {
    if(!aktifKapalId || !ikanAntrian) return
    ikanAntrianOnSelect(null, {id: ikanAntrian.id })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aktifKapalId])

  useEffect(() => {
    const onKeyPress = (e) => {
      if(e.key === 'ArrowUp') return incrementDecrementHandler('increment')
      if(e.key === 'ArrowDown') return incrementDecrementHandler('decrement')
    }
    window.addEventListener('keydown', onKeyPress)
    return () => {
      window.removeEventListener('keydown', onKeyPress)
    }
  }, [incrementDecrementHandler])

  useEffect(() => {
    if(!socket || seconds !== 0 || !sesi) return
    setTimeout(() => {
      incrementDecrementHandler('increment')
    }, 1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, seconds, listIkanAntrian, sesi])

  const sendAntrianKapal = useCallback(async(idNelayan) => {
    const config = { 
      "idNelayan": Number(idNelayan),
      "idTpi": Number(userInfo?.user?.id_tpi) ,
      "tangalAntrian": tanggal
    }
    try {
      await axios.post(
        `${API_BASE_URL}/dash/antrian-kapal`, 
        config,
        {
          headers: {
            Authorization: token
          }
        }
      )
    } catch (error) {
      console.error(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tanggal, token, userInfo?.user?.id_tpi])

  // BARCODE SCANNER HANDLER
  useEffect(() => {
    const addAndListenBarcodeScanner = (e) => {
      // add scan property to window if it does not exist
      if(!window.hasOwnProperty('scan')) {
        window.scan = []
      }
      
      // if key stroke appears after 10 ms, empty scan array
      if(window.scan.length > 0 && (e.timeStamp - window.scan.slice(-1)[0].timeStamp) > 10) {
        window.scan = []
      }
      
      // if key store is enter and scan array contains keystrokes
      // dispatch `scanComplete` with keystrokes in detail property
      // empty scan array after dispatching event
      if(e.key === "Enter" && window.scan.length > 0) {
        let scannedString = window.scan.reduce(function(scannedString, entry) {
          return scannedString + entry.key
        }, "")
        window.scan = []
        return document.dispatchEvent(new CustomEvent('scanComplete', {detail: scannedString}))
      }
      
      // do not listen to shift event, since key for next keystroke already contains a capital letter
      // or to be specific the letter that appears when that key is pressed with shift key
      if(e.key !== "Shift") {
        // push `key`, `timeStamp` and calculated `timeStampDiff` to scan array
        let data = JSON.parse(JSON.stringify(e, ['key', 'timeStamp']))
        data.timeStampDiff = window.scan.length > 0 ? data.timeStamp - window.scan.slice(-1)[0].timeStamp : 0;
        window.scan.push(data)
      }
    }
     
    const sendBarcodeToAntrianKapal = (e) => {
      sendAntrianKapal(e.detail)
    }

    document.addEventListener('keydown', addAndListenBarcodeScanner)
    document.addEventListener('scanComplete', sendBarcodeToAntrianKapal)

    return () => {
      document.removeEventListener('keydown', addAndListenBarcodeScanner)
      document.removeEventListener('scanComplete', sendBarcodeToAntrianKapal)
    }
  }, [sendAntrianKapal]) 


  const { globalSetting } = useSettingViewModel()

  useEffect(() => {
    globalSetting.handler.fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const globalSettingTimer = globalSetting.state.list.data?.Timer
    if(!globalSettingTimer || globalSettingTimer === timer) return
    setTimer(globalSettingTimer)
  }, [globalSetting.state.list.data, timer])

  useEffect(() => {
    if(akhiriSesiAlert) pause()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [akhiriSesiAlert])


  // DATA TO RETURN
  const returnObj = {
    state: {
      pemenang,
      bidder,
      peserta,
      socketLogin,
      userInfo,   
      loading,
      timer: seconds
    },
    bundle: {
      lelang: {
        status: lelang,
        alert: { 
          akhiri: {
            visible: akhiriLelangAlert,
            toggle: toggleAkhiriLelangAlert
          },
          noSesi: {
            visible: lelangNoSesiAlert,
            toggle: toggleLelangNoSesiAlert
          },
          invalidParam: {
            visible: lelangInvalidParamAlert,
            toggle: toggleLelangInvalidParamAlert
          },  
        },
        end: (e, receipt) => akhiriLelang(e, receipt)
      },
      sesi: {
        status: sesi,
        alert: {
          akhiri: {
            visible: akhiriSesiAlert,
            toggle: (bool) => {
              toggleAkhiriSesiAlert(bool)
              if(bool === false) restartTimer()
            },
          },
          noBidder: {
            visible: sesiNoBidderAlert,
            toggle: toggleSesiNoBidderAlert
          }
        },
        start: mulaiSesi,
        end: akhiriSesi,
      },
      antrian: {
        list: antrian,
        fetch: fetchListAntrian,
        loading: antrianLoading,
        mulaiLelang,
      },
      antrianAktif: {
        noAntrian: aktifNoAntrian,
        idKapal: aktifKapalId,
        namaKapal: aktifNamaKapal,
        jenisIkan: aktifJenisIkan,
        totalTransaksi: aktifTotalTransaksi,
        totalPenerimaan: aktifPenerimaan,
        jumlahIkan: aktifJumlahIkan,
        fetch: {
          list: {
            ikan: fetchListIkanAntrianAktif
          }
        }
      },
      ikan: {
        jenis: {
          value: jenisIkan?.value,
          list: listJenisIkan,
          info: infoJenisIkan,
          onSelect: jenisIkanOnSelect,
          disabled: !lelang || sesi,
          validation: errors?.jenisIkan?.message
        },
        berat: {
          value: beratIkan,
          onChange: beratIkanOnChange,
          disabled: !lelang || sesi,
          validation: errors?.beratIkan?.message,
          refresh: refreshBeratIkan,
          alert: {
            visible: refreshBeratIkanAlert,
            toggle: toggleRefreshBeratIkanAlert
          }
        },
      },      
      harga: {
        selisih: {
          value: selisih,
          onFocus: selisihOnFocus,
          onChange: selisihOnChange,
          validation: errors?.selisih?.message
        },
        aktual: {
          value: aktual,
          onChange: aktualOnChange,
          onFocus: aktualOnFocus,
          onBlur: aktualOnBlur,
          increment: () => incrementDecrementHandler('increment'),
          decrement: () => incrementDecrementHandler('decrement'),
          validation: errors?.aktual?.message
        },
        processor: moneyDigits
      },
      ikanAntrian: {
        value: ikanAntrian,
        list: listIkanAntrian,
        onSelect: ikanAntrianOnSelect,
        disabled: !lelang || sesi,
        alert: {
          error: {
            visible: antrianPopUp,
            close: closeAntrianPopUp
          }
        },
        validation: errors?.ikanAntrian?.message
      }
    }
  } 
  
  return returnObj
}