import React from 'react'
import { IoRefresh } from 'react-icons/io5'
import IconContainer from '../../../../components/IconContainer/IconContainer'
import ListCard from '../../../../components/ListCard/ListCard'

const QueueList = ({list, activeListItem, listItemOnClick, refreshOnClick}) => {
  return (
    <ListCard
      id={'queue-list'}
      header={
        <div className='flex items-center justify-between w-full'>
          <span>Antrian Kapal</span>
          <IconContainer component={'button'} onClick={() => refreshOnClick()}>
            <IoRefresh/>
          </IconContainer>
        </div> 
      }
      data={list}
      renderData={(sp, idx) => (
        <div className={styles.antrian.container}>
          <button key={idx} className={`${styles.antrian.default} ${activeListItem(sp) ? styles.antrian.active : ''}`} onClick={() => listItemOnClick(sp)}>
            <div className='text-xs'>Antrian {sp.NoAntrian < 10 ? `00${sp.NoAntrian}` : sp.NoAntrian < 100 ? `0${sp.NoAntrian}` : idx + 1}</div>
            <div className='font-semibold'>{sp.Nelayan?.NamaKapal}</div>
            <div>{sp.Nelayan?.NoKartuNelayan}</div>
          </button>   
        </div> 
      )} 
    />
  )
}

const styles = {
  antrian: {
    container: `border-b`,
    default: `flex flex-col col-span-2 space-y-1 p-5 text-sm  hover:bg-slate-50 w-full text-left`,
    active: `bg-etpi-orange-50 hover:bg-etpi-orange-50 border-r-8 border-etpi-orange`
  }
}

export default QueueList