import React from 'react'
import SmallBox from '../../../../components/Boxes/SmallBox/SmallBox'
import Card from '../../../../components/Card/Card'
import Title from '../../../../components/Title/Title'
import { useMoneyDigits } from '../../../../hooks/useMoneyDigits'
import { CURRENCY } from '../../../../utils/currency'

const ShipInfoCard = ({
  antrianAktif,
}) => {

  const moneyDigits = useMoneyDigits()

  return (
    <Card id="ship-info" contentClassName='p-5 grid grid-cols-3 gap-5' className='col-span-3'>
      <SmallBox className='flex items-center justify-center'>
        <Title
          containerClassName='flex flex-col items-center text-center'
          subtitle={'No. Antrian Aktif'}
          subtitleClassName={'text-sm'}
          title={antrianAktif.noAntrian ? antrianAktif.noAntrian < 10 ? `00${antrianAktif.noAntrian}` : antrianAktif.noAntrian < 100 ? `0${antrianAktif.noAntrian}` : antrianAktif.noAntrian : '-'}
          titleWeight={'bold'}
          titleClassName={'text-4xl'}
        />
      </SmallBox>
      <div className="grid grid-cols-2 col-span-2 gap-5">
        <Title
          subtitle={`Nama Kapal`}
          subtitleClassName={'text-xs'}
          title={antrianAktif?.namaKapal || '-'}
          titleWeight={'bold'}
        />
        <Title
          subtitle={`Jumlah Ikan Terjual`}
          subtitleClassName={'text-xs'}
          title={antrianAktif?.jumlahIkan || '-'}
          titleWeight={'bold'}
        />
        <Title
          subtitle={`Total Transaksi (${CURRENCY})`}
          subtitleClassName={'text-xs'}
          title={` ${antrianAktif?.totalTransaksi ? moneyDigits(antrianAktif?.totalTransaksi) : '-'}`}
          titleWeight={'bold'}
          titleClassName={'text-2xl'}
        />
        <Title
          subtitle={`Total Penerimaan (${CURRENCY})`}
          subtitleClassName={'text-xs'}
          title={`${antrianAktif?.totalPenerimaan ? moneyDigits(antrianAktif?.totalPenerimaan) : '-'}`}
          titleClassName={'text-green-600'}
          titleWeight={'bold'}
        />
      </div>
    </Card>
  )
}

export default ShipInfoCard