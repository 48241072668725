export const useFindExistData = () => {
  const findExistData = (dataList, dataToFind, query) => {
    let dataArray = [...dataList]
    const existData = dataArray.find(x => query ?  x[query] === dataToFind[query] : x === dataToFind) // Find if same commandcenter exist
  
    if (!existData) return([...dataArray, dataToFind]) // If not found, add data
  
    const existDataIdx = dataArray.indexOf(existData) // Get founded index from list
    dataArray.splice(existDataIdx, 1, dataToFind) // Delete founded data
  
    return(dataArray)
  } 
  return findExistData
}