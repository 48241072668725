import axios from "axios"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/slices/userSlice/userSlice"
import { API_BASE_URL } from "../../../utils/urls"

export const useFetchInfoJenisIkan = (token, id, setInfoJenisIkan) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const config = {
      url: `/jenis-ikan/${id}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
    }
    const fetchListJenisIkan = async() => {
      try {
        const { data } = await axios(config)
        if (!data) return       
        setInfoJenisIkan(data.data)
      } catch (error) {
        if(error.message.includes('401')) return dispatch(logout())
      }
    } 
    const fetchListJenisIkanHandler = () => {
      if(!id) return
      fetchListJenisIkan()
    }
    fetchListJenisIkanHandler()
  
  }, [dispatch, id, setInfoJenisIkan, token])
}