import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { API_BASE_URL } from "../../../../utils/urls"

export const fetchListTimbangan = createAsyncThunk(
  'auction/fetchListTimbangan',
  async (_, thunkAPI) => {    
    const state = thunkAPI.getState()
    const userInfo = state.user.userLogin.userInfo
    const token = userInfo.token 
    const config = {
      url: '/dash/timbangan',
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
    }
    const response = await axios(config)
    if (response) return response.data
  }
)

export const timbanganExtraReducer = (builder) => {
  builder.addCase(fetchListTimbangan.pending, (state, action) => {
    state.timbangan.list.loading = true
  })
  builder.addCase(fetchListTimbangan.fulfilled, (state, action) => {
    state.timbangan.list.loading = false 
    const timbangan = action.payload.data.timbangan
    let timbanganArr = []
    for (let i = 0; i < timbangan.length; i++) {
      timbanganArr.push({id: timbangan[i].id, value: timbangan[i].no})
    }
    state.timbangan.list.data = timbanganArr
  })
  builder.addCase(fetchListTimbangan.rejected, (state, action) => {
    state.timbangan.list.loading = false 
    state.timbangan.list.error = action.error.message
  })
}