import React from 'react'
import Card from '../../../../components/Card/Card'
import Select from '../../../../components/Select/Select'
import TextField from '../../../../components/TextField/TextField'
import UnderlinedTextField from '../../../../components/UnderlinedTextField/UnderlinedTextField'
import { CURRENCY } from '../../../../utils/currency'
import Title from '../../../../components/Title/Title'
import { MdGavel } from 'react-icons/md'
import { IoArrowDown, IoArrowUp, IoPlayCircle, IoStopCircle } from 'react-icons/io5'
import Button from '../../../../components/Button/Button'
import Chip from '../../../../components/Chip/Chip'

const AuctionCard = ({
  sesi,
  lelang,
  timbangan,
  ikan,
  harga,
  timer,
  ikanAntrian,
  antrianAktif
}) => {

  const handleKeypress = (e) => {
    const characterCode = e.key
    if (characterCode === 'Backspace') return

    const characterNumber = Number(characterCode)
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return
      } else if (characterNumber === 0) {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }
  }

  const CHECK_SESSION_TITLE = sesi.status ? 'Dimulai' : 'Belum Dimulai'
  const CHECK_SESSION_TITLE_CLASS_NAME = sesi.status ? styles.title.started : ""

  const SUMMARY_PRICE = (harga?.aktual?.value && harga?.aktual?.value !== '-') && ikanAntrian ? harga.processor(Math.floor(Number(harga.aktual.value) * Number(ikanAntrian.value.berat))) : ''

  const auctionInfos = [
    {
      subtitle: 'Sesi:',
      title: CHECK_SESSION_TITLE,
      titleClassName: `${styles.title.default} ${CHECK_SESSION_TITLE_CLASS_NAME}`
    },          
    { 
      subtitle: `Harga Naik/Turun (${CURRENCY}):`,
      title: (
        <UnderlinedTextField 
          className='text-xl text-right'
          value={harga?.selisih?.value || '-'} 
          valueProcessor={harga?.processor}
          onChange={harga?.selisih?.onChange} 
          onFocus={harga?.selisih?.onFocus} 
          onBlur={harga?.selisih?.onBlur}
          type={'number'}
          textPosition={'right'}
          validation={harga?.selisih?.validation}
          autoComplete={'off'}
          min={0}
          onKeyDown={handleKeypress}
        />
      ),
    },      
  ]

  const CHECK_STATUS_FOR_ACTION = sesi.status ? (
    {
      actionButtons: [
        {
          icon: <MdGavel/>,
          title: `Akhiri Lelang`,
          color: 'red',
          disabled: true,
          variant: 'outline',
        },
        {
          icon: <IoStopCircle/>,
          title: `Stop Sesi`,
          color: 'red',
          disabled: !lelang,
          onClick: sesi.alert.akhiri.toggle
        },
      ]
    }
  ) : (
    {
      actionButtons: [
        {
          icon: <MdGavel/>,
          title: `Akhiri Lelang`,
          color: 'red',
          disabled: !lelang.status,
          variant: 'outline',
          onClick: () => {
            lelang.alert.akhiri.toggle(true)
          }
        }, 
        {
          icon: <IoPlayCircle />,
          title: `Mulai Sesi`,
          color: 'blue',
          disabled: !lelang.status,
          onClick: sesi.start
        },
      ]
    }
  )

  return (
    <Card 
      id={'main-auction'}
      className='h-full'
      contentClassName='p-5 flex flex-col space-y-5 h-full'
    >
      <div className="grid grid-cols-2 gap-5">
        <Select
          label={'Jenis Ikan'}
          name='fishType'
          value={ikanAntrian?.value.nama || 'Pilih Jenis Ikan'}
          dropdownData={ikanAntrian?.list?.data}
          optionOnSelect={ikanAntrian?.onSelect}
          validation={ikanAntrian?.validation}
          disabled={ikanAntrian?.disabled}
          onDropdownShow={antrianAktif.fetch.list.ikan}
          loading={ikanAntrian?.list?.loading}
        />
        <TextField
          label={'Bobot Ikan'}
          name={'fishWeight'}
          value={ikanAntrian?.value?.berat || '-'}
          onChange={ikanAntrian?.onChange}
          onBlur={ikanAntrian?.onBlur}
          adornment={'kg'}
          adornmentPosition={'right'}
          style={{textAlign: 'right'}}
          disabled={true}
          autoComplete={'off'}
          min={0}
          className={'space-x-1'}
        />
      </div>  
      <Card
        className='relative flex-1 border'
        contentClassName='divide-y h-full flex flex-col'
      >
        <div className="grid grid-cols-2 divide-x">
          {auctionInfos.map((info, idx) => (
            <Title
              key={idx}
              containerClassName='grid grid-cols-2 items-center text-sm text-left justify-between p-5'
              subtitle={info.subtitle}
              subtitleClassName={''}
              title={info.title}
              titleWeight={'bold'}
              titleClassName={`text-right text-xl ${info.titleClassName || ''}`}
            />
          ))}     
        </div>
        <div className="flex flex-col flex-1 w-full p-5 space-y-5">
          <div className="flex flex-col items-center justify-center flex-1 w-full space-y-5">
            <Title
              containerClassName='flex flex-col items-center text-center space-y-1 w-full'
              subtitle={'Harga Aktual (Rp) / Kg'}
              subtitleClassName={'font-semibold text-xl  '}
            /> 
            <UnderlinedTextField 
              className='w-full font-bold text-center text-9xl'
              value={harga?.aktual?.value || '-'} 
              valueProcessor={harga.processor}
              onChange={harga?.aktual?.onChange} 
              onFocus={harga?.aktual?.onFocus}
              onBlur={harga?.aktual?.onBlur}
              type={'number'}
              textPosition={'center'}
              validation={harga?.aktual?.validation}
              min={500}
              onKeyDown={handleKeypress}
            />
            <Title
              containerClassName='flex flex-col items-center text-center'
              subtitle={`Total Harga (Rp) / ${harga.processor(ikanAntrian.value.berat)|| 0}Kg`}
              subtitleClassName={'text-base'}
              title={SUMMARY_PRICE}
              titleWeight={'bold'}
              titleClassName={'text-4xl'}
            />
          </div>
          <div className='grid w-full grid-cols-2 gap-2'>                  
            <Chip variant={'outline'} disabled={!sesi.status || harga?.aktual?.value - harga?.selisih?.value < 500 } onClick={harga.aktual.decrement}>
              <IoArrowDown className='text-xl'/>
            </Chip>
            <Chip variant={'outline'} disabled={!sesi.status} onClick={harga.aktual.increment}>
              <IoArrowUp className='text-xl'/>
            </Chip>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 p-5">
          {CHECK_STATUS_FOR_ACTION.actionButtons.map((btn, btnIdx) => (
            <Button key={btnIdx} color={btn.color} size={'lg'} variant={btn.variant}  onClick={btn.onClick} disabled={btn.disabled}>
              <div className='text-xl'>{btn.icon}</div>
              <div>{btn.title}</div>
            </Button>        
          ))}
        </div>
      </Card>
    </Card>
  )
}

const styles = {
  title: {
    default: '',
    started: 'text-green-600'
  }
}

export default AuctionCard