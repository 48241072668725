import axios from 'axios'
import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import TextField from '../../components/TextField/TextField'
import { API_BASE_URL } from '../../utils/urls'

export default function OpenAPIScreen() {

  const [addFishValue, setAddFishValue] = useState({id_jenis_ikan: 1, id_antrian_lelang: 1, berat: 1})

  const addFishWeight = async() => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/openapi/timbangan`,
        {
          "command": "insert",
          "ikan": {
            "berat": 1,
            "id_antrian_lelang": Number(addFishValue.id_antrian_lelang),
            "id_antrian_timbangan": 1,
            "id_jenis_ikan": Number(addFishValue.id_jenis_ikan)
          },
          "keranjang": [
            {
              "berat": 1,
              "id_antrian_timbangan": 1,
              "id_keranjang": 1,
              "qty": 1
            }
          ]
        },
        {
          headers: {
            Authorization: `aa2b613bdc5659c1fa02a523fa1130f2`
          }
        }
      )
      console.log(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const inputOnChange = (e) => {
    setAddFishValue(current => ({...current, [e.target.name]: e.target.value}))
  }

  return (
    <div className='grid w-full h-screen grid-cols-12 gap-5 p-5 bg-slate-200 place-content-start'>
      <Card className='col-span-4' contentClassName='grid grid-cols-3 gap-5 p-5'>
        <div className='font-bold col-span-full'>Tambah Jenis Ikan</div>
        <TextField name={`id_antrian_lelang`} label={'ID Antrian Lelang'} value={addFishValue.id_antrian_lelang || ''} type={'number'} onChange={inputOnChange}/>
        <TextField name={`id_jenis_ikan`} label={'ID Jenis Ikan'} value={addFishValue.id_jenis_ikan || ''} type={'number'} onChange={inputOnChange}/>
        <TextField name={`berat`} label={'Berat Ikan'} value={addFishValue.berat || ''} type={'number'} onChange={inputOnChange}/>
        <Button className='col-span-3' onClick={addFishWeight}>Tambah Bobot Ikan</Button>
      </Card>
    </div>
  )
}
