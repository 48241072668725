import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import jsCookie from 'js-cookie'
import { API_BASE_URL, LOGIN_PATH } from '../../../utils/urls' 

export const login = createAsyncThunk(
  'user/login',
  async (payload, thunkAPI) => {
    
    const config = {
      url: LOGIN_PATH,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json"
      },
      data: {
        "userid": payload.userid,
        "password": payload.password
      }
    }
    const response = await axios(config)
    if (response) return response.data
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userLogin: {
      userInfo: jsCookie.get('userInfo')
      ? JSON.parse(jsCookie.get('userInfo')) 
      : null
    },
  },
  reducers: {
    logout: (state, action) => {
      state.userLogin.userInfo = null
      jsCookie.remove('userInfo')
    },
    loginRole: (state, action) => {
      state.userLogin.userInfo.user.user_roles = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.userLogin = { loading: true }
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.userLogin = { loading: false, userInfo: action.payload.data }
      jsCookie.set('userInfo', JSON.stringify(action.payload.data))
    })
    builder.addCase(login.rejected, (state, action) => {
      state.userLogin = { loading: false, error: action.error?.message === "Request failed with status code 500" ? 'User ID / Password salah' : action.error.message}
    })
  }
})

export const { logout, loginRole } = userSlice.actions
export default userSlice.reducer