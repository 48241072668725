import React from 'react'
import { useRef } from 'react'
import Pagination from '../Pagination/Pagination'

const Table = ({
  pagination,
  page,
  pageTotal,
  maxDataPreview,
  data,
  pickData,
  actions,
  containerClassName = '',
  paginationOnClick,
  checkbox,
  checkAll,
  setCheck,
  handleCheckbox,
  className = ''
}) => {

  const tbody = useRef(null)

  const checkedTableBodyHandler = (data, idx) => {
    handleCheckbox(data,idx);
  }

  return (
    <div className={`w-full ${containerClassName}`}>
      {data && data.length > 0 ? (
        <table className={`w-full overflow-hidden rounded-t-xl ${className}`}>
          <thead className='text-white bg-etpi-blue'>
            <tr className=''>
              {checkbox && (
                <th className='px-5 py-3'>
                  <input type={'checkbox'} checked={checkAll} onChange={setCheck}/>
                </th>
              )}
              <th className='px-5 py-3'>
                No.
              </th>
              {pickData && pickData.map((pckDt, pckDtIdx) => (
                <th key={pckDtIdx} className='px-5 py-3'>{pckDt.title}</th>
              ))}
              {actions && (
                <th className='px-5 py-3'>Aksi</th>
              )}
            </tr>
          </thead>
          <tbody ref={tbody} className='text-center border-b divide-y min-h-[24rem]'>
            {data && data.map((tr, trIdx) => (
              <tr key={trIdx} className='text-center'>
                {checkbox && (
                  <td className='px-5 py-3'>
                  <input type={'checkbox'} checked={tr.isSelected} onChange={() => checkedTableBodyHandler(tr, trIdx)}/>
                  </td>
                )}
                <td className='px-5 py-3'>
                  {pagination ? (maxDataPreview * (page - 1) + trIdx + 1) : trIdx + 1 }
                </td>
                {pickData && pickData.map((pckData, pckDtIdx) => (
                  <td key={pckDtIdx} className={`px-5 py-3 ${pckData.className || ''}`}>{pckData.processor ? pckData.processor(typeof pckData.selector === 'string' ? tr[pckData.selector] : pckData.selector(tr)) : typeof pckData.selector === 'string' ? tr[pckData.selector] : pckData.selector(tr)}</td>
                ))}
                {actions && (
                  <td className='flex justify-center px-5 py-3 space-x-2'>
                    {actions(tr, trIdx)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="w-full overflow-hidden rounded-t-xl">
          <thead className='text-white bg-etpi-blue'>
            <tr className=''>
              <th className='px-5 py-3'>
                No.
              </th>
              {pickData && pickData.map((pckDt, pckDtIdx) => (
                <th key={pckDtIdx} className={`px-5 py-3 ${pckDt.className || ''}`}>{pckDt.title}</th>
              ))}
              {actions && (
                <th className='px-5 py-3'>Aksi</th>
              )}
            </tr>
          </thead>
          <tbody className='relative text-center border-b divide-y h-96'>
            <tr className='absolute inset-0 flex flex-col items-center justify-center '>
              <td>Tidak Ada Data</td>
            </tr>
          </tbody>
        </table>
      )}
      {pagination ? (
        <Pagination className='justify-end w-full mt-5' page={page} pageTotal={pageTotal} paginationOnClick={paginationOnClick}/>
      ) : ''}
    </div>
  )
}

export default Table
