import React from 'react'
import './Button.css'

const Button = ({
  className = '',
  children,
  color,
  size,
  variant,
  onClick,
  disabled,
  loading,
  title, 
}) => {

  const buttonVariants = ['default', 'outline', 'pill', 'round', 'round-outline'];
  const buttonSizes = ['xs', 'sm', 'md', 'lg', 'xl'];
  const buttonColors = ['blue', 'orange', 'red', 'gray'];

  const CHECK_BUTTON_SIZE = buttonSizes.find(x => x === size) ? size : 'md'
  const CHECK_BUTTON_VARIANT = buttonVariants.find(x => x === variant) ? variant : buttonVariants[0];
  const CHECK_BUTTON_COLOR = buttonColors.find(x => x === color) ? color : buttonColors[0];

  const disabledClassNames = `btn btn--${CHECK_BUTTON_SIZE} btn--disabled ${className}`
  const defaultClassNames  = `btn btn--${CHECK_BUTTON_SIZE} btn--${CHECK_BUTTON_COLOR} btn--${CHECK_BUTTON_VARIANT} ${className}`

  return (
    disabled ? (
      <div className={disabledClassNames} title={title}>{children}</div>      
    ) :  (
      <button className={defaultClassNames} onClick={onClick} title={title}> {children} </button>
    )
  )
};

export default Button
