import { useAddZero } from "./useAddZero"

export const useGetDate = () => {

  const addZero = useAddZero()

  const getDate = (_date, separator = '-') => {
    const dateObject = _date ? new Date(_date) : new Date()
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1
    const date = dateObject.getDate()
    const day = dateObject.getDay()
    const separated = { 
      year, 
      month: addZero.front(month, 1), 
      date: addZero.front(date, 1), 
      day,  
    }
    const combined = `${separated.year}${separator}${separated.month}${separator}${separated.date}`
    return {
      ...separated,
      combined
    }
  }
  return getDate
}