import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import { MdGavel } from 'react-icons/md'
import { IoClose, IoRefresh } from 'react-icons/io5'
import Alert from '../../components/Alerts/Alert/Alert'
import Button from '../../components/Button/Button'
import IconContainer from '../../components/IconContainer/IconContainer'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import AuctionCard from './components/AuctionCard/AuctionCard'
import BidderListCard from './components/BidderListCard/BidderListCard'
import ParticipantListCard from './components/ParticipantListCard/ParticipantListCard'
import PlaceDateCard from './components/PlaceDateCard/PlaceDateCard'
import ShipInfoCard from './components/ShipInfoCard/ShipInfoCard'
import ShipListCard from './components/ShipListCard/ShipListCard'
import WinnerInfoCard from './components/WinnerInfoCard/WinnerInfoCard'
import { ViewModel } from './ViewModel/ViewModel'
import Card from '../../components/Card/Card'
import SmallBox from '../../components/Boxes/SmallBox/SmallBox'
import Title from '../../components/Title/Title'

const MainScreen = () => {

  const { state, bundle,  } = ViewModel()

  const { 
    loading,
    bidder,
    pemenang,
    peserta,
    socketLogin,
    userInfo, 
    timer
  } = state

  const {
    antrian,
    antrianAktif,
    timbangan,
    ikan,
    ikanAntrian,
    harga,
    lelang,
    sesi,
  } = bundle
  
  const SECTION_COMMON_CLASS_NAME = `flex flex-col h-full space-y-5`   

  return (
    <>
      <GlobalLayout contentClassName='grid grid-cols-9 gap-5' loading={loading}>
        <section id='left-section' className={`col-span-2 ${SECTION_COMMON_CLASS_NAME}`}>
          <PlaceDateCard placeName={userInfo?.user?.nama_tpi}/>
          <ShipListCard 
            antrian={antrian}
            lelang= {lelang}
          />
        </section>
        <section id="middle-section" className={`col-span-5 ${SECTION_COMMON_CLASS_NAME}`}>
          <div className="grid w-full grid-cols-4 gap-5">
            <ShipInfoCard 
              antrianAktif={antrianAktif}
            />
            <Card contentClassName='p-5 flex flex-col h-full' className=''>
              <SmallBox className='flex items-center justify-center w-full h-full' color={'red'}>
                <Title
                  containerClassName='flex flex-col items-center text-center '
                  subtitle={'Waktu Penawaran (detik)'}
                  subtitleClassName={'text-sm'}
                  title={timer}
                  titleWeight={'bold'}
                  titleClassName={'text-5xl'}
                />
              </SmallBox>
            </Card>
          </div>
          <AuctionCard
            sesi={sesi}
            lelang={lelang}
            timbangan={timbangan}
            ikan={ikan}
            harga={harga}
            ikanAntrian={ikanAntrian}
            antrianAktif={antrianAktif}
          />
        </section>
        <section className={`col-span-2 ${SECTION_COMMON_CLASS_NAME}`}>
          <WinnerInfoCard winnerName={pemenang?.nama} priceToPay={pemenang?.pembayaran} /> 
          <BidderListCard bidder={bidder}/>
          <ParticipantListCard peserta={peserta}/>
        </section>  
      </GlobalLayout>
      {!socketLogin && (
        <div className='fixed inset-0 z-50 grid text-white bg-etpi-blue place-content-center'>
          <div className="flex flex-col items-center space-y-2 text-center">
            <FaSpinner className='text-4xl animate-spin'/>
            <div className='mb-1 text-2xl font-bold '>Menghubungkan Socket</div>
            <div>Mohon tunggu sebentar</div>
          </div>
        </div>
      )}
      {lelang.alert.noSesi.visible && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <MdGavel/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Akhiri Lelang
            </div>
            <p>Akhiri lelang tanpa penjualan?</p>
            <div className='flex w-full mt-6 space-x-2'>
              <Button className='w-full' color={'red'} variant={'outline'}  onClick={lelang.end}>
                Ya
              </Button>
              <Button className='w-full' color={'red'} onClick={lelang.alert.noSesi.toggle}>
                Tidak
              </Button>             
            </div>
          </div>
        </Alert>
      )}
      {lelang.alert.invalidParam.visible && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <IoClose/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Gagal Mengakhiri Lelang
            </div>
            <p>Invalid Parameter</p>
            <div className='w-full mt-6 space-y-2'>
              <Button className='w-full' color={'red'} onClick={() => lelang.alert.invalidParam.toggle(false)}>
                OK
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {lelang.alert.akhiri.visible && (
        <Alert closeFunction={() => lelang.alert.akhiri.toggle(false)}>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <MdGavel/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Akhiri Lelang?
            </div>
            <p>Anda akan mengakhiri lelang untuk antrian ini</p>
            <div className='w-full mt-6 space-y-2'>
              <Button className='w-full' color={'red'} variant={'outline'}  onClick={lelang.end}>
                Akhiri Lelang
              </Button>
              <Button className='w-full' color={'red'} onClick={e => lelang.end(e, true)}>
                Akhiri Lelang dan Cetak Receipt
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {sesi.alert.noBidder.visible && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <IoClose/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Belum Ada Pemenang
            </div>
            <p>Sesi tidak bisa diselesaikan tanpa pemenang</p>
            <div className='w-full mt-6 space-y-2'>
              <Button className='w-full' color={'red'} onClick={() => sesi.alert.noBidder.toggle(false)}>
                OK
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {sesi.alert.akhiri.visible && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <IoClose/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Stop Sesi
            </div>
            <p>Apakah Anda ingin mengakhiri sesi ini?</p>
            <div className='grid w-full grid-cols-2 gap-2 mt-6'>
              <Button className='w-full' color={'red'} variant={'outline'} onClick={() => sesi.end()}>
                Ya
              </Button>
              <Button className='w-full' color={'red'} onClick={() => sesi.alert.akhiri.toggle(false)}>
                Tidak
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {ikanAntrian.alert.error.visible && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white bg-red-600 rounded-full'>
              <IoClose/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Gagal
            </div>
            <p>{ikanAntrian.alert.error.visible.message}</p>
            <div className='grid w-full gap-2 mt-6'>
              <Button className='w-full' color={'red'} onClick={() => ikanAntrian.alert.error.close()}>
                OK
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {ikan.berat.alert.visible && (
        <Alert>
          <div className="flex flex-col items-center p-5 text-center">
            <IconContainer className='w-16 h-16 mb-5 text-4xl text-white rounded-full bg-etpi-blue'>
              <IoRefresh/>
            </IconContainer>
            <div className="mb-1 text-2xl font-bold">
              Tambahan Bobot Ikan
            </div>
            <p>Terdapat penambahan bobot pada Jenis Ikan pilihan</p>
            <div className='grid w-full gap-2 mt-6'>
              <Button 
                className='w-full' 
                onClick={() => {
                  ikan.berat.refresh()
                  ikan.berat.alert.toggle()
                }
              }>
                Refresh Bobot Ikan
              </Button>
            </div>
          </div>
        </Alert>
      )}
      
    </>
  )
}

export default MainScreen