import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import routes from './utils/routes';
import { useSelector } from 'react-redux'
import LoginScreen from './screens/LoginScreen/LoginScreen';
import NotFoundScreen from './screens/NotFoundScreen/NotFoundScreen';
import OpenAPIScreen from './screens/OpenAPIScreen/OpenAPIScreen';

function App() {

  const state = useSelector(state => state)
  const userInfo = state?.user?.userLogin?.userInfo
  const user_roles = userInfo?.user?.user_roles

  const currentRoutes = routes[user_roles]
  
  return (
    <Router>
      <Routes>
        <Route path={`/login`} element={<LoginScreen/>}/>
        {currentRoutes && currentRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
        <Route path='/open-api' element={<OpenAPIScreen/>}/>
        <Route path='*' element={<NotFoundScreen/>}/>
      </Routes>
    </Router>
  );
}

export default App;
