/* eslint-disable */
export const EMAIL_VALIDATION = {
  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  message: {
    required: 'Email harus diisi',
    invalid: 'Silahkan masukkan email yang benar',
    pattern: 'Silahkan masukkan format email yang benar'
  }
}

export const USER_ID_VALIDATION = {
  pattern: /[^0-9]/g,
  message: {
    required: 'User ID harus diisi',
    invalid: 'Silahkan masukkan User ID yang benar',
    type: 'Tipe User ID harus angka',
    pattern: 'Hanya boleh angka 0 - 9'
  }
}

export const PASSWORD_VALIDATION = {
  message: {
    required: 'Password harus diisi'
  }
}