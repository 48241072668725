import React from 'react'
import ListCard from '../../../../components/ListCard/ListCard'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect } from 'react'
import { useState } from 'react'

const BidderListCard = ({ bidder }) => {

  const [list, setList] = useState([])

  useEffect(() => {
    setList(bidder)
  }, [bidder])
  
  return (
    <ListCard 
      id={'bidder-list'}
      header='Penawar Lelang'
      headerColor={'green'}
      data={list || []}
      renderData={(bid, idx) => (
        <div key={idx} className={'flex w-full p-4 items-start text-sm space-x-2 border-b'}>
          <div className='w-6 font-semibold'>{idx+1 < 10 ? `0${idx+1}` : idx+1 }.</div>
          <div  className={`flex flex-col space-y-1`}>
            <div className='font-semibold'>{bid.nama}</div>
            <div className='text-xs'>{bid.phone}</div>
          </div>
        </div>            
      )}
    />
  )
}

export default BidderListCard