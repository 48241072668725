import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAddZero } from "../../hooks/useAddZero";
import { useGetDate } from "../../hooks/useGetDate";
import { useMoneyDigits } from "../../hooks/useMoneyDigits";
import { API_BASE_URL } from "../../utils/urls";
import { useSelector } from "react-redux";

const fetchReceipt = async (idNelayan, idTransaksi, token, callback) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/dash/receipt`, { id_nelayan: idNelayan, id_transaksi: idTransaksi }, { headers: { Authorization: token } });
    callback && callback({ data, error: "" });
  } catch (error) {
    callback && callback({ data: null, error: error.message });
  }
};

const ReceiptScreen = () => {
  const userInfo = useSelector((state) => state.user.userLogin?.userInfo);
  const token = userInfo.token;
  const user = userInfo.user;

  const [receipt, setReceipt] = useState(null);

  const params = useParams();
  const getDate = useGetDate();
  const addZero = useAddZero();
  const moneyDigits = useMoneyDigits();

  const newDate = new Date(Date.now());
  const currentTime = `${addZero.front(newDate.getHours())}:${addZero.front(newDate.getMinutes())}`;
  const currentDate = `${addZero.front(getDate().date)}-${addZero.front(getDate().month)}-${getDate().year}`;

  useEffect(() => {
    fetchReceipt(Number(params.idNelayan), Number(params.idTransaksi), token, ({ data, error }) => {
      if (!data) return;
      setReceipt(data?.data);
    });
  }, [params.idNelayan, params.idTransaksi, token]);

  const receiptOnLoad = () => {
    window.print();
  };

  return (
    <>
      <div className="pb-5 text-white">...</div>
      {receipt && (
        <div onLoad={receiptOnLoad} className={`${styles.common} w-[80mm] p-4 space-y-3 text-sm`}>
          <div className={`${styles.common} justify-center`}>
            <div className="flex flex-col items-center">
              <img src={`../../assets/img/logo.png`} alt={"logo"} className={"h-20"} />
            </div>
          </div>
          <table className="w-full" style={{ marginTop: 30 }}>
            <tbody>
              <tr>
                <td></td>
                <td className="text-right" style={{ fontSize: 40, textAlign: "center" }}>
                  {addZero.front(receipt.lelang.id_antrian_lelang, 2)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="font-semibold" style={{ marginTop: 20 }}>
            {user?.nama_tpi}
          </div>
          <table className="w-full ">
            <tbody>
              <tr>
                <td>{currentDate}</td>
                <td className="text-right">{currentTime} </td>
              </tr>
              <tr>
                <td>Kapal</td>
                <td className="text-right">{receipt.lelang.nama_kapal}</td>
              </tr>
              <tr>
                <td>Pemilik</td>
                <td className="text-right">{receipt.lelang.nama_pemilik_kapal}</td>
              </tr>
            </tbody>
          </table>
          <table className="flex flex-col w-full py-2 space-y-2 able-auto border-y">
            {receipt.transaksi?.map((rcp, idx) => (
              <tbody className="grid " key={idx}>
                <tr>
                  <td className="font-medium text-left">{rcp.nama_ikan}</td>
                </tr>
                <tr className="flex justify-between w-full">
                  <td className="grid grid-cols-5 text-left">
                    <div>{rcp.berat}kg</div>
                    <div>x</div>
                    <div>@{moneyDigits(rcp.harga_aktual)}</div>
                  </td>
                  <td className="text-right ">{moneyDigits(rcp.total_transaksi)}</td>
                </tr>
              </tbody>
            ))}
          </table>
          <table className="flex flex-col w-full space-y-2 table-auto">
            <tbody>
              <tr className="grid grid-cols-2">
                <td>Subtotal</td>
                <td className="text-right">{moneyDigits(receipt.lelang.total_transaksi)}</td>
              </tr>
              <tr className="grid grid-cols-2">
                <td>Fee Nelayan ({receipt.lelang.fee_nelayan}%)</td>
                <td className="text-right">-{moneyDigits(Math.ceil(receipt.lelang.fee_nelayan_rp))}</td>
              </tr>
              {receipt.retribusi &&
                receipt.retribusi.map((rtb, rtbIdx) => (
                  <tr key={rtbIdx} className="grid grid-cols-2">
                    <td>
                      {rtb.nama_fee} ({rtb.nominal_fee}%)
                    </td>
                    <td className="text-right">-{moneyDigits(Math.ceil(rtb.nominal_fee_rp))}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <table className="flex flex-col w-full py-2 space-y-2 font-semibold table-auto border-y">
            <tbody>
              <tr className="grid grid-cols-2">
                <td>Total</td>
                <td className="text-right">{moneyDigits(receipt.lelang.total_penerimaan)}</td>
              </tr>
            </tbody>
          </table>
          <div className="pt-16 text-white">Terimakasih</div>
        </div>
      )}
    </>
  );
};

const styles = {
  common: `flex flex-col items-center`,
};

export default ReceiptScreen;
