import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import IconContainer from '../../components/IconContainer/IconContainer'
import { usePickData } from '../../hooks/usePickData'
import routes from '../../utils/routes'
import './GlobalLayout.css'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/slices/userSlice/userSlice'
import useToggle from '../../hooks/useToggle'
import ActionAlert from '../../components/Alerts/ActionAlerts/ActionAlert'
import { useCallback } from 'react'
import Alert from '../../components/Alerts/Alert/Alert'
import Button from '../../components/Button/Button'
import { SiDevdotto } from 'react-icons/si'
import { IoClose } from 'react-icons/io5'
import { resetLelang } from '../../redux/slices/auctionSlice/auctionSlice'
import { FaSpinner } from 'react-icons/fa'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'

const GlobalLayout = ({
  contentClassName = '',
  breadcrumbs,
  loading,
  children
}) => {

  const [logoutAlert, toggleLogoutAlert] = useToggle(false)
  const [sessionEndAlert, toggleSessionEndAlert] = useToggle(false)
  const [devTools, toggleDevTools] = useToggle(false)
  
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const userLogin = state.user.userLogin
  const { userInfo } = userLogin
  const user_roles = userInfo?.user?.user_roles


  const navigate = useNavigate()
  const pickData = usePickData()

  const userRoles = [
    {
      name: 'JuruLelang',
      props: [
        routes.JuruLelang, 
        'name', 
        [
          'main-dashboard',
          'fisher-transactions'
        ], 
        'array'
      ]
    },
    {
      name: 'Finance',
      props: [
        routes.Finance, 
        'name', 
        [
          'main-dashboard', 
          'tpi-transaction',
          'transactions'
        ], 
        'array'
      ]
    },
    {
      name: 'Supervisor',
      props: [
        routes.Supervisor, 
        'name', 
        [
          'settings', 
          'transactions'
        ], 
        'array'
      ]
    },
    {
      name: 'Stakeholder',
      props: [
        routes.Stakeholder, 
        'name', 
        [
          'main-dashboard', 
        ], 
        'array'
      ]
    },
  ]

  const CHECK_USER_ROLES = userRoles.find(x => x.name === user_roles)?.props || null

  const sidebarMenuList = pickData(...CHECK_USER_ROLES)

  const logoutHandler = useCallback(() => {
    dispatch(logout())
    navigate('/login') 
    if(!state.auction.lelang.status) dispatch(resetLelang())
  }, [dispatch, navigate, state.auction.lelang.status])

  const devToolsActions = []

  useEffect(() => {
    if (!userInfo) return navigate('/login')
    const expireDate = new Date(userInfo.expire)
    const checkForExpire = () => {
      const currentDate = new Date(Date.now())  
      if(expireDate.getTime() > currentDate.getTime()) return
      toggleSessionEndAlert(true)
    }
    const timedCheckForExpired = () => {
      setInterval(() => {
        checkForExpire()
      }, 1000 * 60)
    }
    checkForExpire()
    timedCheckForExpired()
    return () => {
      checkForExpire()
      timedCheckForExpired()
    }
  }, [navigate, toggleSessionEndAlert, userInfo])

  return (
    <>
      {loading && (
        <div className='fixed inset-0 z-[39] flex flex-col items-center justify-center bg-white bg-opacity-50'>
          <div className='flex flex-col items-center'>
            <FaSpinner className='text-4xl animate-spin text-etpi-blue'/>
            <div>Loading</div>
          </div>
        </div>
      )}
      {devTools && (
        <div className="fixed inset-0 z-50 grid bg-black bg-opacity-50 place-content-center">
          <div className="flex flex-col w-full max-w-lg p-5 bg-white rounded-md">
            <div className="flex items-center justify-between w-full mb-4">
              <div className='w-full font-bold'>DEV TOOLS</div>
              <button onClick={toggleDevTools}>
                <IconContainer className='text-2xl'><IoClose /></IconContainer>
              </button>
            </div>
            <div className="space-y-2">
              {devToolsActions.map((act, actIdx) => (
                <Button key={actIdx}  className={'w-full'} onClick={act.onClick}> 
                  {act.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      )}
      {userInfo ? (
        <div className="global-layout" style={{backgroundImage: `url(../assets/img/background.png)`}}>
          <div className="sidebar">
            <div className="sidebar__icon">
              <img src={`../assets/img/logo_no_text.png`} alt={'logo'} className={'px-2'}/>
            </div>
            <div className="sidebar__menus">
              {sidebarMenuList.map((route, idx) => (
                <NavLink to={route.path} key={idx} className={`sidebar__menu`}>
                  <IconContainer className='sidebar__menu__icon'>
                    {route.icon}
                  </IconContainer>
                </NavLink>
              ))}
              {devToolsActions.length > 0 && (
                <button className='sidebar__menu' onClick={toggleDevTools}>
                  <IconContainer className='sidebar__menu__icon' >
                    <SiDevdotto/>
                  </IconContainer>
                </button>
              )}
              <div className={`sidebar__menu mt-auto`} onClick={toggleLogoutAlert}>
                <IconContainer className='sidebar__menu__icon'>
                  <RiLogoutBoxLine />
                </IconContainer>
              </div>
            </div>
          </div>  
          <main className={`global-layout__content ${breadcrumbs ? 'global-layout__content--breadcrumbs' : ''} ${contentClassName}`}>
            {breadcrumbs && <Breadcrumbs/>}
            {children}
          </main>
        </div>
      ) : (
        ''
      )}
      {logoutAlert ? (
        <ActionAlert
          icon={
            <IconContainer className='w-20 h-20 text-white rounded-full bg-etpi-orange'>
              <RiLogoutBoxLine className='text-3xl'/>
            </IconContainer>
          }
          title={'Apakah Anda yakin untuk keluar?'}
          subtitle={'Anda harus login kembali apabila sudah keluar'}
          action={{
            yes: logoutHandler,
            no: toggleLogoutAlert
          }}
        />
      ) : sessionEndAlert ? (
        <Alert size={`lg`}>
          <div className={`flex flex-col items-center text-center space-y-6 p-5`}>
            <IconContainer className='w-20 h-20 text-white rounded-full bg-etpi-orange'>
              <RiLogoutBoxLine className='text-3xl'/>
            </IconContainer>
            <div className="flex flex-col space-y-1">
              <div className='text-2xl font-bold'>
                Sesi Berakhir
              </div>
              <div className=''>
                Silahkan Anda login kembali untuk melanjutkan aktivitas Anda
              </div>
            </div>
            <div className='flex items-center w-full space-x-3 font-bold'>
              <Button className='w-full' onClick={() => logoutHandler()}>Login Kembali</Button>
            </div>
          </div>
        </Alert>
      ) : ''}
    </>
  )
}

export default GlobalLayout
