import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import MessageBox from '../../components/Boxes/MessageBox/MessageBox'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import IconContainer from '../../components/IconContainer/IconContainer'
import Table from '../../components/Table/Table'
import TextField from '../../components/TextField/TextField'
import { useGetDate } from '../../hooks/useGetDate'
import { useUrlParams } from '../../hooks/useUrlParams'
import GlobalLayout from '../../layouts/GlobalLayout/GlobalLayout'
import { API_BASE_URL } from '../../utils/urls'

export default function FisherTransactions() {

  const initialState = {
    search: {keyword: '', startDate: new Date(Date.now()), endDate: new Date(Date.now())}
  }

  const state = useSelector(state => state)
  const userInfo = state.user.userLogin.userInfo
  const token = userInfo.token 

  const getDate = useGetDate()
  const navigate = useNavigate()
  const urlParams = useUrlParams()
  const location = useLocation()

  const [search, setSearch] = useState(initialState.search)
  const [transaction, setTransaction] = useState({loading: true, error:'', data: null})
  const { loading, error, data } = transaction

  const fetchListTransaksiNelayan = async() => {
    setTransaction(current => ({...current, loading: true}))
    const page = urlParams.get('page')
    try {
      const response = await axios.get(
        `${API_BASE_URL}/list-transaction/nelayan`,
        {
          headers: {
            Authorization: token,
          },
          params: {
            page: page || 1,
            nama_kapal: search.keyword,
            id_tpi: userInfo?.user?.id_tpi,
            start_date: getDate(search.startDate).combined,
            end_date: getDate(search.endDate).combined
          }
        }
      )
      setTransaction(current => ({...current, loading: false, data: response.data.data}))
    } catch (error) {
      console.log(error)
      setTransaction(current => ({...current, loading: false, error: error.response ? error.response.data.errors : error.message}))
    }
  }

  const searchOnClickHandler = () => {
    fetchListTransaksiNelayan()
  }

  const resetSearchOnClickHandler = () => {
    setSearch(initialState.search)
    paginationOnClickHandler(1)
  }

  const searchOnChangeHandler = (e) => {
    setSearch(current => ({...current, [e.target.name]: e.target.value}))
  }

  const paginationOnClickHandler = (pageVal) => {
    navigate({pathname:'/transactions', search:`?page=${pageVal}`})
  }

  useEffect(() => {
    fetchListTransaksiNelayan()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  
  const pickData = [
    {
      title: 'Nama Kapal',
      selector: (tr) => tr.nama_kapal
    },
    {
      title: 'ID Antrian',
      selector: (tr) => tr.id_transaksi
    },  
  ]

  return (
    <GlobalLayout loading={loading}>
      {error ? (
        <MessageBox color={'red'}>Tidak ada data transaksi</MessageBox>
      ) : (
        <>
          <Card contentClassName='p-5  space-y-5'>
            <div className='flex items-center justify-between w-full mb-5'>
              <div className='text-xl font-bold'>Transaksi Kapal</div>
            </div>
            <div className="grid items-end grid-cols-12 gap-3 col-span-full">
              <TextField 
                name={'keyword'}
                wrapperClassName='col-span-6' 
                label={'Nama Kapal'} 
                value={search.keyword} 
                onChange={searchOnChangeHandler}
              />
              <TextField 
                name={'startDate'}
                wrapperClassName='col-span-2' 
                label={'Tanggal Mulai'} 
                value={getDate(search.startDate).combined} 
                onChange={searchOnChangeHandler}
                type={'date'}
                max={getDate(search.startDate).combined}
              />
              <TextField 
                name={'endDate'}
                wrapperClassName='col-span-2' 
                label={'Tanggal Berakhir'} 
                value={getDate(search.endDate).combined} 
                onChange={searchOnChangeHandler}
                type={'date'}
                max={getDate(search.endDate).combined}
              />
              
              <div className={`grid grid-cols-2 col-span-2 gap-3`}>
                <Button size={'lg'} color={'red'} onClick={searchOnClickHandler}>Cari</Button>
                <Button size={'lg'} color={'red'} variant={'outline'} onClick={resetSearchOnClickHandler}>Reset</Button>
              </div>
            </div>
            <Table
              containerClassName='col-span-full w-full'
              pagination={true}
              page={data?.Page}
              pageTotal={data?.TotalPage}
              maxDataPreview={data?.Limit}
              paginationOnClick={paginationOnClickHandler}
              pickData={pickData}
              data={data?.Records || []}
              actions={(rowData, idx) => (
                <Link to={`/receipt/${rowData.id_nelayan}/${rowData.id_transaksi}`} target={'_blank'}>
                  <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md cursor-pointer hover:text-etpi-blue hover:border-etpi-blue' >
                    <IoEyeOutline />
                  </IconContainer>
                </Link>
              )}
            />
          </Card> 
        </>
      )}
    </GlobalLayout>
  )
}
