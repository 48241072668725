import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Button from '../../components/Button/Button'


const NotFoundScreen = () => {

  const state = useSelector(state => state)
  const userInfo = state?.user?.userLogin?.userInfo
  const navigate = useNavigate()

  useEffect(() => {
    if(!userInfo) return navigate('/login')
  }, [navigate, userInfo])

  return (
    <div className='grid w-full h-screen text-white place-content-center bg-etpi-blue'>
      <div className='flex flex-col items-center text-center'>
        <div className="font-bold text-8xl">
          404
        </div>
        <div className='text-2xl font-bold'>HALAMAN TIDAK DITEMUKAN</div>
        <Button color={'orange'}  className={'font-semibold mt-5'} size={'lg'} onClick={() => navigate('/')}>
          Kembali ke Halaman Utama
        </Button>
      </div>
    </div>
  )
}

export default NotFoundScreen