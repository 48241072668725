export const usePickData = () => {
  const pickData = (data, dataSelector = '', dataToPick = []) => {
    let pickedData = Object.prototype.toString.call(data) === '[object Array]' ? arrayHandler(data, dataSelector, dataToPick) : objectHandler(data, dataToPick)
    return pickedData
  }
  return pickData
}

const arrayHandler = (data, dataSelector, dataToPick) => {
  let dataArray = []
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < dataToPick.length; j++) {
      if (data[i][dataSelector] === dataToPick[j] || data[i] === dataToPick[j]) dataArray.push(data[i])       
    }      
  }
  return(dataArray)
}

const objectHandler = (data, dataToPick) => {
  let dataObject = {}
  for (let i = 0; i < dataToPick.length; i++) {
    if (data[dataToPick[i]]) dataObject[dataToPick[i]] = data[dataToPick[i]]
  }
  return dataObject
}