import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { API_BASE_URL } from "../../../../utils/urls"



export const fetchListIkan = createAsyncThunk(
  'auction/fetchListIkan',
  async (_, thunkAPI) => {    
    const state = thunkAPI.getState()
    const userInfo = state.user.userLogin.userInfo
    const token = userInfo.token 
    const config = {
      url: '/jenis-ikan',
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
    }
    const response = await axios(config)
    if (response) return response.data
  }
)

export const fetchInfoIkan = createAsyncThunk(
  'auction/fetchInfoIkan',
  async (_, thunkAPI) => {  
    const state = thunkAPI.getState()
    const userInfo = state.user.userLogin.userInfo
    const token = userInfo.token 
    const idJenisIkan = Number(state.auction.ikan.pilihan.id)
    const config = {
      url: `/jenis-ikan/${idJenisIkan}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : token
      },
    }
    const response = await axios(config)
    if (response) return response.data
  }
)



export const ikanExtraReducer = (builder) => {
  builder.addCase(fetchListIkan.pending, (state, action) => {
    state.ikan.list.loading = true
  })
  builder.addCase(fetchListIkan.fulfilled, (state, action) => {
    state.ikan.list.loading = false 
    const records = action.payload.data.Records
    let recordsArr = []
    for (let i = 0; i < records.length; i++) {
      recordsArr.push({id: records[i].IdJenisIkan, value: records[i].NamaIkan})
    }
    state.ikan.list.data = recordsArr
  })
  builder.addCase(fetchListIkan.rejected, (state, action) => {
    state.ikan.list.loading = false 
    state.ikan.list.error = action.error.message
  })
  builder.addCase(fetchInfoIkan.pending, (state, action) => {
    state.ikan.info.loading = true
  })
  builder.addCase(fetchInfoIkan.fulfilled, (state, action) => {
    state.ikan.list.loading = false 
    state.ikan.info.data = action.payload.data
  })
  builder.addCase(fetchInfoIkan.rejected, (state, action) => {
    state.ikan.info.loading = false 
    state.ikan.info.error = action.error.message
  })
} 