import React from 'react'
import './Alert.css'
import { IoClose } from 'react-icons/io5'

const Alert = ({
  className = '',
  header,
  headerClose,
  headerColor,
  closeOutside = false,
  closeFunction,
  size,
  children
}) => {

  const alertSizes = ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', 'full']
  const alertHeaderColors = ['blue']

  const CHECK_ALERT_SIZE = alertSizes.find(x => x === size) ? size : alertSizes[0]
  const CHECK_ALERT_HEADER_COLORSS = alertHeaderColors.find(x => x === headerColor) ? headerColor : alertHeaderColors[0]

  const closeHandler = (e) => {
    closeFunction && closeFunction(e)
  }

  return (
    <div className='alert__container'>
      <div className='fixed inset-0 z-40 bg-black opacity-50' onClick={(e) => closeOutside ? closeHandler(e) : ''}></div>
      <div className={`alert alert--${CHECK_ALERT_SIZE} ${className}`}>
        {header ? (
          <div className={`alert__header alert__header--${CHECK_ALERT_HEADER_COLORSS}`}>
            <div className='alert__header__title'>{header}</div>
            {headerClose ? (
              <button className='alert__header__close' onClick={closeHandler}>
                <IoClose />
              </button>
            ) : ''}
          </div>
        ) : ''}
        {closeFunction && !headerClose && (
          <button className='alert__close' onClick={closeHandler}>
            <IoClose />
          </button>
        )}
        {children}
      </div>
    </div>
  )
}

export default Alert
